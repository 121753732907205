import { useSetBulkInventoryItemStatus } from '../../../api/inventory/set-inventory-item-status';
import { Status } from '../../../api/status';
import Dropdown from '../../common/dropdown/dropdown';
import DropdownItem from '../../common/dropdown/dropdown-item';

export type InventoryBulkChangeStatusDropdownProps = {
  statuses: Status[];
  inventoryIds: number[];
  clearBulkSelect: () => void;
  className?: string;
};

export const InventoryBulkChangeStatusDropdown = ({
  statuses,
  className,
  inventoryIds,
  clearBulkSelect,
}: InventoryBulkChangeStatusDropdownProps) => {
  const setBulkStatus = useSetBulkInventoryItemStatus(clearBulkSelect);

  const onChangeStatus = async (ids: number[], statusId: number) => {
    await setBulkStatus.mutate({ ids, statusId });
  };

  return (
    <div className={`${className}`}>
      {statuses.length ? (
        <Dropdown title="Change Status" disabled={inventoryIds.length === 0}>
          {statuses.map((s, k) => (
            <DropdownItem
              title={`${s.title}`}
              onClick={async () => await onChangeStatus(inventoryIds, s.id)}
              key={k}
            ></DropdownItem>
          ))}
        </Dropdown>
      ) : null}
    </div>
  );
};

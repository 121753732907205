import { Location } from '../../../api/location';
import { User } from '../../../api/user';
import { usaStates } from '../../../lib/states';
import { Input } from '../../form/input';
import { Label } from '../../form/label';
import { LabelInput } from '../../form/labelInput';
import { LabelSelect } from '../../form/labelSelect';
import { LabelTextArea } from '../../form/labelTextArea';
import { Line } from '../../form/line';
import { Radio } from '../../form/radio';
import { Select } from '../../form/select';
import { SubmitButton } from '../../form/submit-button';

export type CustomerFormProps = {
  onSubmit: () => void;
  register: any;
  users: User[];
  locations: Location[];
  isSubmitting: boolean;
};

const paymentTerms = [
  { id: 'DUE_ON_RECEIPT', title: 'Due on Receipt' },
  { id: 'NET15', title: 'Net 15' },
  { id: 'NET30', title: 'Net 30' },
  { id: 'NET45', title: 'Net 45' },
  { id: 'NET60', title: 'Net 60' },
  { id: 'NET90', title: 'Net 90' },
  { id: 'CONSIGNMENT', title: 'Consignment' },
  { id: '1%_10_NET30', title: '1% 10 Net 30' },
  { id: '2%_10_NET30', title: '2% 10 Net 30' },
];

const taxExempt = [
  { id: 'YES', title: 'Yes' },
  { id: 'NO', title: 'No' },
];

const deliveryMethod = ['Shipment', 'Local'];

export const CustomerForm = ({ onSubmit, users, locations, register, isSubmitting }: CustomerFormProps) => {
  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="flex flex-col space-y-8 w-full">
        <div className="w-full sm:w-[50%]">
          <LabelInput
            title={'Customer Name'}
            attributes={{ ...register('title', { required: true }) }}
            placeholder={'ABC Hospital'}
          />
        </div>

        <Line />

        <LabelTextArea title="Notes" attributes={{ ...register('note') }} />

        <Line />

        <div className="flex space-y-4 flex-col sm:flex-row sm:space-x-2 sm:space-y-0">
          {/** Billing address **/}
          <div className="flex flex-col w-full space-y-2">
            <LabelInput
              title={'Billing Address'}
              attributes={{ ...register('address.billing.street1', { required: true }) }}
              placeholder={'Street, Address 1'}
            />

            <Input attributes={{ ...register('address.billing.street2') }} placeholder={'Street, Address 2'} />

            <Input attributes={{ ...register('address.billing.city', { required: true }) }} placeholder={'City'} />

            <div className="flex w-full space-x-2">
              <Select
                attributes={{ ...register('address.billing.state', { required: 'Select one option' }) }}
                data={usaStates}
                option="State"
                className="border border-gray-300 rounded-md p-2 text-sm w-full"
              />
              <Input
                attributes={{ ...register('address.billing.zip', { required: true }) }}
                placeholder={'Zip'}
                className="border border-gray-300 rounded-md p-2 max-w-[450px] w-full"
              />
            </div>
          </div>

          {/** Shipping address **/}
          <div className="flex flex-col w-full space-y-2">
            <LabelInput
              title={'Default Shipping Address'}
              attributes={{ ...register('address.shipping.street1', { required: true }) }}
              placeholder={'Street, Address 1'}
            />

            <Input attributes={{ ...register('address.shipping.street2') }} placeholder={'Street, Address 2'} />

            <Input attributes={{ ...register('address.shipping.city', { required: true }) }} placeholder={'City'} />

            <div className="flex w-full space-x-2">
              <Select
                attributes={{ ...register('address.shipping.state', { required: 'Select one option' }) }}
                data={usaStates}
                option="State"
                className="border border-gray-300 rounded-md p-2 text-sm w-full"
              />
              <Input
                attributes={{ ...register('address.shipping.zip', { required: true }) }}
                placeholder={'Zip'}
                className="border border-gray-300 rounded-md p-2 max-w-[450px] w-full"
              />
            </div>
          </div>
        </div>

        <Line />

        <div className="flex space-y-4 flex-col sm:flex-row sm:space-x-2 sm:space-y-0">
          <div className="flex flex-col w-full space-y-2">
            <LabelInput
              title={'Accounts Payable E-mail'}
              attributes={{ ...register('email') }}
              placeholder={'ABC Hospital'}
            />
          </div>
          <div className="flex flex-col w-full space-y-2">
            <LabelInput
              title={'AP Address'}
              attributes={{ ...register('address.ap.street1') }}
              placeholder={'Street, Address 1'}
            />

            <Input attributes={{ ...register('address.ap.street2') }} placeholder={'Street, Address 2'} />

            <Input attributes={{ ...register('address.ap.city') }} placeholder={'City'} />

            <div className="flex w-full space-x-2">
              <Select
                attributes={{ ...register('address.ap.state') }}
                data={usaStates}
                option="State"
                className="border border-gray-300 rounded-md p-2 text-sm w-full"
              />
              <Input
                attributes={{ ...register('address.ap.zip') }}
                placeholder={'Zip'}
                className="border border-gray-300 rounded-md p-2 max-w-[450px] w-full"
              />
            </div>
          </div>
        </div>

        <Line />

        <div className="flex space-y-4 flex-col sm:flex-row sm:space-x-2 sm:space-y-0">
          <div className="flex flex-col w-full space-y-2">
            <LabelInput
              title={'Caller Name'}
              attributes={{ ...register('contactPerson', { required: true }) }}
              placeholder={'John Doe'}
            />
          </div>
          <div className="flex flex-col w-full space-y-2">
            <LabelInput
              title={'Phone Number'}
              placeholder="###-###-####"
              attributes={{ ...register('callerPhoneNumber', { required: true }) }}
            />
            <LabelInput title={'Email'} attributes={{ ...register('callerEmail', { required: true }) }} />
          </div>
        </div>

        <Line />

        <Label title="Payment Terms" />

        <div className="grid grid-row-4 xl:flex xl:gap-20 xl:justify-start">
          {paymentTerms.map(({ id, title }: { id: string; title: string }) => (
            <Radio
              attributes={{ ...register('paymentTerm', { required: true }) }}
              title={title}
              key={id}
              name={'paymentTerm'}
              value={id}
            />
          ))}
        </div>

        <Line />

        <div className="w-full sm:w-[50%]">
          <LabelInput title="PO Number" attributes={{ ...register('poNumber') }} />
        </div>

        <Line />

        <Label title="Tax Exempt" />

        <div className="flex gap-20">
          {taxExempt.map(({ id, title }: { id: string; title: string }) => (
            <Radio
              attributes={{
                ...register('taxExempt', {
                  required: true,
                }),
              }}
              title={title}
              key={title}
              name={'taxExempt'}
              value={id}
            />
          ))}
        </div>

        <div className="flex flex-col">
          <Label title={'Sales Tax Percentage'} />
          <div className="flex w-full sm:w-[10%]">
            <Input
              type="number"
              attributes={{ ...register('taxPercentage', { valueAsNumber: true }) }}
              placeholder={'-'}
              className="border border-gray-300 rounded-none rounded-l-lg p-1 w-full"
            />
            <span className="inline-flex items-center h-[34px] px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
              %
            </span>
          </div>
        </div>

        <Line />

        <div className="flex flex-col w-full sm:w-[50%] space-y-2">
          <LabelInput
            title={'Delivery Location'}
            attributes={{ ...register('address.delivery.street1') }}
            placeholder={'Street, Address 1'}
          />

          <Input attributes={{ ...register('address.delivery.street2') }} placeholder={'Street, Address 2'} />

          <Input attributes={{ ...register('address.delivery.city') }} placeholder={'City'} />

          <div className="flex w-full space-x-2">
            <Select
              attributes={{ ...register('address.delivery.state') }}
              data={usaStates}
              option="State"
              className="border border-gray-300 rounded-md p-2 text-sm w-full"
            />
            <Input
              attributes={{ ...register('address.delivery.zip') }}
              placeholder={'Zip'}
              className="border border-gray-300 rounded-md p-2 max-w-[450px] w-full"
            />
          </div>
        </div>

        <Line />

        <Label title="Delivery Method" />
        <div className="flex gap-20">
          {deliveryMethod.map((p) => (
            <Radio attributes={{ ...register('deliveryMethod') }} title={p} key={p} name={'deliveryMethod'} value={p} />
          ))}
        </div>

        <Line />

        <div className="w-full sm:w-[50%]">
          <LabelSelect
            title="Sales Rep"
            data={users.map((u) => ({ id: u.id, title: u.name }))}
            option="Select"
            attributes={{ ...register('userId', { valueAsNumber: true }) }}
          />
        </div>
        <Line />

        <div className="w-full sm:w-[50%]">
          <LabelSelect
            title="Market"
            data={locations.map((l) => ({ id: l.id, title: l.title }))}
            option="Select"
            attributes={{ ...register('marketId', { required: 'Select one option', valueAsNumber: true }) }}
          />
        </div>

        <Line />

        <div className="flex justify-center">
          <SubmitButton isSubmitting={isSubmitting} submitText="Save Customer" />
        </div>
      </div>
    </form>
  );
};

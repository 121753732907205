import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { EQUIPMENT_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { Equipment } from './index';

export const getEquipment = (id: string | unknown): Promise<Equipment> => {
  return axios.get(`/product/${id}`);
};

export const useEquipment = (id: string | unknown) => {
  return useQuery<Equipment, AxiosError<AxiosErrorResponse>>({
    queryKey: [EQUIPMENT_KEY, id],
    queryFn: () => getEquipment(id),
    keepPreviousData: true,
  });
};

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams } from 'react-router-dom';

import { InventoryJoinedHistory } from '../../api/inventory';
import { useInventoryHistory } from '../../api/inventory/get-inventory-history';
import { useMasterInventoryItem } from '../../api/inventory/get-inventory-item';
import { useInventoryStatus } from '../../api/status/get-inventory-status';
import { TableContainer } from '../../components/common/table/table-container';
import { TableDataCell } from '../../components/common/table/table-data-cell';
import { TableDataRow } from '../../components/common/table/table-data-row';
import { TableDataValue } from '../../components/common/table/table-data-value';
import { TableTitle } from '../../components/common/table/table-title';
import { ActionButton } from '../../components/common/title-action/action-button';
import { LinkButton } from '../../components/common/title-action/link-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { InventoryActionDropdown } from '../../components/inventory/dropdown/inventory-action-dropdown';
import { InventoryInfo } from '../../components/inventory/info/inventory-info';
import { InventoryViewTabs } from '../../components/inventory/tabs/inventory-view-tabs';
import { NavigationButton } from '../../components/navigation/navigation-button';
import { Spinner } from '../../components/spinner';
import { formatDate } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';

const productInfoCols = [
  'Status',
  'Type',
  'Owner',
  'PM Type',
  'Last PM',
  'Due Date',
  'Location',
  'Manufacturer',
  'Software Version',
  'Purchase Date',
  'Initial Cost',
  'Warranty',
  'PO Number',
  'Vendor purchased from',
];

const productHistoryCols = [
  'Description',
  'Status',
  'Location',
  'Order Number',
  'Customer Name',
  'Delivery/Pickup Date',
  'Delivery User',
  'Updated By',
  'Date',
];

export const InventoryViewPage = () => {
  const { roles } = useRoles();
  const isUserOrAdmin =
    roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, isSuccess, data, isRefetching } = useMasterInventoryItem({ id });
  const {
    isLoading: historyLoading,
    data: historyData,
    isRefetching: historyRefetching,
  } = useInventoryHistory({ id: (id as string) || '' });

  const statuses = useInventoryStatus({ inventoryId: Number(id) });

  if (isLoading || isRefetching) return <Spinner />;

  return isSuccess ? (
    <div className="flex flex-col justify-start items-start w-full h-full p-4 box-border overflow-x-auto mb-[80px] md:mb-0">
      {isUserOrAdmin ? (
        <>
          {/**  NAVIGATION BACK * */}
          <button onClick={() => navigate(-1)}>
            <NavigationButton title="Inventory" url="#">
              <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
            </NavigationButton>
          </button>

          {/**  PRODUCT INFO VIEW * */}
          <InventoryInfo inventory={data} />

          {/**  PRODUCT TITLE ACTIONS * */}
          <TitleActions title={data.product.title}>
            {statuses.isSuccess ? (
              <InventoryActionDropdown statuses={statuses.data} inventoryId={data.id} className="z-10" />
            ) : null}
            <LinkButton title="Edit" link={`/inventory/edit/${id}`} />
            <ActionButton title="Export Table" />
          </TitleActions>

          {/**  PRODUCT VIEW TABS/ROUTES * */}
          <InventoryViewTabs />

          {/**  PRODUCT INFO TABLE * */}
          <TableContainer className="mb-8 h-full">
            <TableDataRow className={'h-14'}>
              {productInfoCols.map((col: string, key: number) => (
                <TableDataCell className="px-[10px] border-r border-1" key={key}>
                  <TableDataValue text={col} />
                </TableDataCell>
              ))}
            </TableDataRow>
            <TableDataRow className={'h-14'}>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.status.title} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue
                  text={data.product.type?.title ?? 'No Type'}
                  outlineStyle={'border-2 border py-1 px-2 rounded-full'}
                />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.owner.title} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.product.pm_type} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.lastPm} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.dueDate} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.location.title} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.product.manufacturer} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.softwareVersion} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={formatDate(data.purchaseDate)} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data.cost} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data?.warranty || 'Warranty'} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data?.poNumber || 'PO Number'} />
              </TableDataCell>
              <TableDataCell className="px-[10px] border-r border-1">
                <TableDataValue text={data?.vendorPurchased || 'Vendor purchased from'} />
              </TableDataCell>
            </TableDataRow>
          </TableContainer>

          {/**  PRODUCT HISTORY TABLE * */}
          <TableTitle title={'History'} />
          <TableContainer className=" h-full">
            <TableDataRow className={'h-14'}>
              {productHistoryCols.map((col: string, key: number) => (
                <TableDataCell className="px-[10px]" key={key}>
                  <TableDataValue text={col} />
                </TableDataCell>
              ))}
            </TableDataRow>

            {historyLoading || historyRefetching ? (
              <Spinner />
            ) : (
              historyData.map((history: InventoryJoinedHistory, key: number) => (
                <TableDataRow className={'h-14'} key={key}>
                  <>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={history.description} />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue
                        text={history.statusTitle}
                        outlineStyle={'border-2 border py-1 px-2 rounded-full'}
                      />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={history.locationTitle} />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={history.order?.id} />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={history.order?.customer?.title} />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue
                        text={history.deliveryPickupDate !== null ? formatDate(history.deliveryPickupDate as Date) : ''}
                      />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={history.order?.user?.name} />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={history.userTitle} />
                    </TableDataCell>
                    <TableDataCell className="px-[10px]">
                      <TableDataValue text={formatDate(history.createdAt)} />
                    </TableDataCell>
                  </>
                </TableDataRow>
              ))
            )}
          </TableContainer>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </div>
  ) : null;
};

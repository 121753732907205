import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { DELIVERY_MODE_KEY, ORDER_STATUS_KEY, ORDER_TYPE_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps } from '../types';

export const getOrderStatuses = (params: GetItemProps) => {
  return axios.get(`/order/statuses`, { params: { ...params, perPage: '10' } });
};

export const useOrderStatuses = (params: GetItemProps) => {
  return useQuery<any, AxiosError<AxiosErrorResponse>>({
    queryKey: [ORDER_STATUS_KEY, params.page || 1],
    queryFn: () => getOrderStatuses(params),
  });
};

export const getOrderTypes = (params: GetItemProps) => {
  return axios.get(`/order/types`, { params: { ...params, perPage: '10' } });
};

export const useOrderTypes = (params: GetItemProps) => {
  return useQuery<any, AxiosError<AxiosErrorResponse>>({
    queryKey: [ORDER_TYPE_KEY, params.page || 1],
    queryFn: () => getOrderTypes(params),
  });
};

export const getDeliveryModes = (params: GetItemProps) => {
  return axios.get(`/order/delivery-modes`, { params: { ...params, perPage: '10' } });
};

export const useDeliveryModes = (params: GetItemProps) => {
  return useQuery<any, AxiosError<AxiosErrorResponse>>({
    queryKey: [DELIVERY_MODE_KEY, params.page || 1],
    queryFn: () => getDeliveryModes(params),
  });
};

import { ActionButton } from '../common/title-action/action-button';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export const ShowInvoiceGroupModalButton = ({ onClick, disabled }: Props) => {
  return (
    <ActionButton
      icon="/media/credit-card-icon.svg"
      title="Assign to Invoice Group"
      className={'sm:flex'}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

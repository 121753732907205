import { useQuery } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';

export const INVENTORY_COUNT_KEY = 'INVENTORY_COUNT_KEY';

export const getInventoryCount = (productId: number | undefined): Promise<number> => {
  if (productId === undefined) return Promise.resolve(0);
  return axios.get(`/inventory/count/${productId}`);
};

type QueryFnType = typeof getInventoryCount;

type UseInventoryCountOptions = {
  productId: number | undefined;
  config?: QueryConfig<QueryFnType>;
};

export const useInventoryCount = ({ productId, config }: UseInventoryCountOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [INVENTORY_COUNT_KEY, productId],
    queryFn: () => getInventoryCount(productId),
  });
};

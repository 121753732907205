import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { CustomerPricing } from '../../api/customer';
import { useCustomerPricingCreate } from '../../api/customer/create-customer-pricing';
import { useCustomer } from '../../api/customer/get-customer';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { CustomerPricingForm } from '../../components/customer/form/customer-pricing-form';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

export const CustomerPricingCreatePage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const { customerId } = useParams();

  const { isSuccess, data: customer } = useCustomer(customerId);
  const createCustomerPricing = useCustomerPricingCreate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<CustomerPricing>();

  const onSubmit = async (values: CustomerPricing) => {
    if (!customerId) return;
    await createCustomerPricing.mutateAsync({ customerId, data: { ...values, customerId: parseInt(customerId) } });
  };

  if (!isSuccess) return <Spinner />;

  return (
    <div className="flex flex-col justify-start items-start w-[100%] h-screen p-4 box-border overflow-x-auto pb-[80px] md:pb-0">
      {isAdmin ? (
        <>
          <NavigationBackButton url={-1} />

          <TitleActions title={customer.title} />

          <CustomerPricingForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            setValue={setValue}
            isSubmitting={isSubmitting}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </div>
  );
};

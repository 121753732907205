import { Dialog } from '@headlessui/react';
import { useState } from 'react';

import { InvoiceGroup } from '../../../api/invoicing';
import { useInvoiceGroupCreate } from '../../../api/invoicing/create-invoice-group';
import { useInvoiceGroups } from '../../../api/invoicing/get-customer-invoice-groups';
import { ButtonCancel } from '../../common/button/button-cancel';
import { ButtonFilled } from '../../common/button/button-filled';
import Dropdown from '../../common/dropdown/dropdown';
import DropdownItem from '../../common/dropdown/dropdown-item';
import { LabelInput } from '../../form/labelInput';

type InvoiceGroupDropdownProps = {
  customerId: number;
  onSelect: (invoiceGroupId: number) => void;
};

export const InvoiceGroupDropdown = ({ customerId, onSelect }: InvoiceGroupDropdownProps) => {
  const [selectedText, setSelectedText] = useState('');
  const [isAddNewGroupModalOpen, setIsAddNewGroupModalOpen] = useState(false);
  const [newInvoiceGroupName, setNewInvoiceGroupName] = useState('');
  const { data, isSuccess } = useInvoiceGroups({
    args: {
      customerId,
      page: 1,
      perPage: 1000,
    },
  });
  const createInvoiceGroup = useInvoiceGroupCreate();

  const onAddNewGroupOptionSelected = () => {
    setIsAddNewGroupModalOpen(true);
  };

  const onCreateGroup = async () => {
    try {
      const newGroup = await createInvoiceGroup.mutateAsync({
        customerId,
        group: {
          name: newInvoiceGroupName,
        } as InvoiceGroup,
      });
      onSelect(newGroup.id);
      setSelectedText(newGroup.name);
      setIsAddNewGroupModalOpen(false);
    } catch {
      // Notification handled by axios interceptor
      return;
    }
  };

  return (
    <>
      <Dropdown disabled={!isSuccess} title={selectedText || 'Select an Invoice Group'} justifyStart={true}>
        {(data as any)?.items
          .map((invoiceGroup: InvoiceGroup) => (
            <DropdownItem
              title={invoiceGroup.name}
              onClick={() => {
                onSelect(invoiceGroup.id);
                setSelectedText(invoiceGroup.name);
              }}
              key={invoiceGroup.id}
            ></DropdownItem>
          ))
          .concat([
            <DropdownItem onClick={onAddNewGroupOptionSelected} key={'Add new group'}>
              <p className="text-blue-500 font-bold">Add New Group</p>
            </DropdownItem>,
          ])}
      </Dropdown>
      <Dialog
        open={isAddNewGroupModalOpen}
        onClose={() => setIsAddNewGroupModalOpen(false)}
        className="relative z-[51]"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="mx-auto rounded bg-white w-full md:w-[400px] p-4">
            <Dialog.Title className="text-2xl text-center font-bold">Create a new Invoice Group</Dialog.Title>
            <div className="flex justify-center my-4">
              <LabelInput
                title="Name"
                type="text"
                attributes={{
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewInvoiceGroupName(e.currentTarget.value);
                  },
                }}
              />
            </div>
            <div className="flex flex-col justify-center px-8">
              <ButtonFilled text="Create" onClick={onCreateGroup} />
              <ButtonCancel text="Cancel" onClick={() => setIsAddNewGroupModalOpen(false)} />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Equipment, TableEquipment } from '../../api/equipment';
import { useEquipmentTable } from '../../api/equipment/get-equipment-table';
import { PageContainer } from '../../components/common/container/page.container';
import { Searchbar } from '../../components/common/search/searchbar';
import { LinkButton } from '../../components/common/title-action/link-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { ImportEquipmentButton } from '../../components/equipment/button/import-equipment-button';
import { ImportSubProductButton } from '../../components/equipment/button/import-sub-product-button';
import DeleteEquipmentModal from '../../components/equipment/modal/delete-equipment-modal';
import { productTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';
import { TablePagination } from '../../components/table/table-pagination';
import { useRoles } from '../../provider/roles-provider';

export const EquipmentPage = () => {
  const [searchParams] = useSearchParams();
  const [equipmentToDelete, setEquipmentToDelete] = useState<undefined | TableEquipment>(undefined);

  const products = useEquipmentTable(searchParams);

  const mapItems = (items: Equipment[]): TableEquipment[] => {
    return items.map(
      (i): TableEquipment => ({
        id: i.id,
        manufacturer: i.manufacturer,
        title: i.title,
        pm_type: i.pm_type,
        type: i.type?.title || 'No type',
        category: i.category?.title || 'No category',
      })
    );
  };

  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  return (
    <PageContainer>
      <DeleteEquipmentModal equipment={equipmentToDelete} onDelete={() => products.refetch()} />
      {isAdmin ? (
        <>
          <Searchbar />

          <TitleActions title="Equipment Type">
            <LinkButton
              icon="/media/plus-icon.svg"
              link={'/equipment/create'}
              title="Add equipment"
              className={'sm:flex'}
            />
            {process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED === 'true' ? (
              <>
                <ImportEquipmentButton title="Import Equipment CSV" className={'sm:flex'} />
                <ImportSubProductButton title="Import Accessories and Components CSV" className={'sm:flex'} />
              </>
            ) : null}

          </TitleActions>

          {products.isFetched && products.data ? (
            <>
              <Table
                data={{
                  ...products.data,
                  items: mapItems(products.data.items),
                }}
                columns={productTableColumns}
                onDelete={(equipment) => setEquipmentToDelete(equipment)}
                canUserEdit={isAdmin}
              />
              <TablePagination
                count={Number(products.data.count)}
                page={Number(products.data.page)}
                perPage={Number(products.data.perPage)}
              />
            </>
          ) : null}
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { Suspense } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useOrder } from '../../api/order/get-order';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import OrderHistory from '../../components/order/order-history';
import OrderPickDetails from '../../components/order/order-pickup-details';
import { ORDER_VIEW_KEY, OrderViewTabs } from '../../components/order/tabs/order-view-tabs';
import { Spinner } from '../../components/spinner';

export const PickupOrderViewPage = () => {
    const [searchParams] = useSearchParams({ [ORDER_VIEW_KEY]: 'details' });
    const orderView = searchParams.get(ORDER_VIEW_KEY);
    const { id } = useParams();
    const { isSuccess, data: order } = useOrder(id);

    if (!isSuccess) return <Spinner />;
    return (
        <PageContainer>
            <NavigationBackButton url="/order" />

            <TitleActions title={order.customer.title} />

            <p className="text-lg font-medium md:text-left text-center w-full">PM-{order.id}</p>

            <OrderViewTabs />

            {orderView === 'details' && (
                <OrderPickDetails order={order} />
            )}

            {orderView === 'history' && (
                <Suspense fallback={<Spinner />}>
                    <OrderHistory orderId={order.id} />
                </Suspense>
            )}
        </PageContainer>
    );
};

export const QUERY_PAGE = 'page';
export const QUERY_SEARCH = 'search';
export const QUERY_PER_PAGE = 'perPage';

export const QUERY_PRODUCT_IDS = 'productIds';
export const QUERY_STATUS_IDS = 'statusIds';
export const QUERY_TYPE_IDS = 'typeIds';
export const QUERY_OWNER_IDS = 'ownerIds';
export const QUERY_LOCATION_IDS = 'locIds';
export const QUERY_AT_IDS = 'atIds';
export const QUERY_CUSTOMER_IDS = 'customerIds';
export const QUERY_USER_IDS = 'userIds';
export const QUERY_ORDER_TYPE = 'orderType';
export const QUERY_ORDER_STATUS = 'orderStatuses';
export const QUERY_DELIVERY_MODE = 'deliveryModes';
export const QUERY_ORDER_DATE = 'orderDate';
export const QUERY_ORDER_NEEDED_DATE = 'orderNeededDate';

export const CHECKBOX_KEY = 'i';
export const CHECKBOX_ALL_KEY = 'a';

/** STATE MANAGER KEYS **/
export const MASTER_INVENTORY_ITEM_STATUS_CHANGE_KEY = 'MASTER_INVENTORY_ITEM_STATUS_CHANGE_KEY';
export const MASTER_INVENTORY_BULK_ITEM_STATUS_CHANGE_KEY = 'MASTER_INVENTORY_BULK_ITEM_STATUS_CHANGE_KEY';
export const MASTER_INVENTORY_KEY = 'MASTER_INVENTORY_KEY';
export const INVENTORY_STATUS_KEY = 'INVENTORY_STATUS_KEY';

export const USER_ROLES_KEY = 'USER_ROLES_KEY';

export const USERS_KEY = 'USERS_KEY';
export const USER_LIST_KEY = 'USER_LIST_KEY';

export const AUTH_ME_KEY = 'AUTH_ME_KEY';

export const CUSTOMER_PRICING_KEY = 'CUSTOMER_PRICING_KEY';
export const CUSTOMER_PRICING_LIST_KEY = 'CUSTOMER_PRICING_LIST_KEY';

export const CUSTOMER_KEY = 'CUSTOMER_KEY';
export const CUSTOMERS_KEY = 'CUSTOMERS_KEY';
export const CUSTOMER_LIST_KEY = 'CUSTOMER_LIST_KEY';

export const EQUIPMENT_KEY = 'EQUIPMENT_KEY';
export const EQUIPMENT_LIST_KEY = 'EQUIPMENT_LIST_KEY';
export const EQUIPMENT_READY_LIST_KEY = 'EQUIPMENT_READY_LIST_KEY';
export const EQUIPMENT_TABLE_KEY = 'EQUIPMENT_TABLE_KEY';

export const STATUS_KEY = 'STATUS_KEY';
export const ORDER_STATUS_KEY = 'ORDER_STATUS_KEY';
export const ORDER_TYPE_KEY = 'ORDER_TYPE_KEY';
export const DELIVERY_MODE_KEY = 'DELIVERY_MODE_KEY';

export const TYPE_KEY = 'TYPE_KEY';

export const OWNER_KEY = 'OWNER_KEY';

export const LOCATION_KEY = 'LOCATION_KEY';

export const ORDERS_KEY = 'ORDERS_KEY';

export const CATEGORY_KEY = 'CATEGORY_KEY';

export const INVOICE_PREVIEW_KEY = 'INVOICE_PREVIEW_KEY';
export const CUSTOMER_INVOICE_PREVIEW_KEY = 'CUSTOMER_INVOICE_PREVIEW_KEY';
export const INVOICE_ZIP_KEY = 'INVOICE_ZIP_KEY';
export const INVOICE_GROUP_KEY = 'INVOICE_GROUP_KEY';
export const PAGINATED_INVOICE_GROUP_KEY = 'PAGINATED_INVOICE_GROUP_KEY';
export const INVOICE_GROUP_ITEMS_KEY = 'INVOICE_GROUP_ITEMS_KEY';
export const MASTER_INVENTORY_BULK_UPDATE_INVOICE_GROUP_KEY = 'MASTER_INVENTORY_BULK_UPDATE_INVOICE_GROUP_KEY';
import { Popover } from '@headlessui/react';
import React, { useEffect } from 'react';

import { Label } from '../../form/label';
import TablePopoverPanelFormInput from '../../table/popover/table-popover-panel-form-input';
import TablePopoverTransition from '../../table/popover/table-popover-transition';

export default function PopoverSelect({
  title,
  setedValue,
  parentValue,
  parentValueSetter,
  itemKey,
  setValue,
  registerName,
  register,
  isRequired,
}: any) {
  const { ref } = register(registerName, {
    required: isRequired ? 'This is required.' : false,
    min: isRequired ? 1 : 0,
  });

  useEffect(() => {
    setValue(registerName, parentValue ? Number(parentValue) : parentValue);
  }, [parentValue]);

  return (
    <div className="flex flex-col space-y-1">
      <Label title={title} />
      <Popover className="relative w-full">
        <Popover.Button className="w-full">
          <div
            className={
              'box-border border cursor-pointer bg-white border-gray-300 rounded-md p-2 text-md min-w-[100px] w-full text-left focus-within:border-2 focus-within:border-blue-800'
            }
          >
            {setedValue || 'Select one option'}
            <input type="text" ref={ref} className="absolute h-1 w-1 z-[-1]" />
          </div>
        </Popover.Button>
        <TablePopoverTransition>
          <Popover.Panel className="absolute z-10 mt-2">
            <TablePopoverPanelFormInput parentValue={parentValue} setParentValue={parentValueSetter} itemKey={itemKey} />
          </Popover.Panel>
        </TablePopoverTransition>
      </Popover>
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef, useState } from 'react';

import { TableEquipment } from '../../../api/equipment';
import { useDeleteEquipment } from '../../../api/equipment/delete-equipment';
import { useInventoryCount } from '../../../api/inventory/get-inventory-count';
import { ReactComponent as Spinner } from '../../spinner/loading-spinner.svg';
import { EquipmentTypesDropdown } from '../dropdown/equipment-types-dropdown';

interface Props {
  equipment?: TableEquipment;
  onDelete: () => void;
}

const DeleteEquipmentModal = ({ equipment, onDelete }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [equipmentId, setEquipmentId] = useState<any>(null);
  const cancelButtonRef = useRef(null);
  const { isLoading: countLoading, data: count, refetch } = useInventoryCount({ productId: equipment?.id });
  const { mutate, isLoading: deleteLoading } = useDeleteEquipment();

  const onDestructiveClick = () => {
    if (equipment && equipmentId) {
      mutate({ 
        equipmentIdForDelete: equipment.id, 
        equipmentIdForReassign: equipmentId 
      }, {
        onSuccess: () => {
          setMessage('Deleted!');
          onDelete();
          setShowModal(false);
        },
      });
    }
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const isLoading = countLoading || deleteLoading;

  useEffect(() => {
    if (equipment !== undefined) {
      refetch();
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [equipment]);

  useEffect(() => {
    if (count === 0) {
      setMessage(`Are you sure you want to delete ${equipment?.title}? This action cannot be undone.`);
    } else {
      const plural = !(count === 1);
      setMessage(
        `There ${plural ? 'are' : 'is'} ${count} inventory ${
          plural ? 'items' : 'item'
        } assigned to this equipment type. Please reassign ${plural ? 'them' : 'it'} to a new equipment type and update the associated pricing.`
      );
    }
  }, [count]);

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Delete Equipment?
                      </Dialog.Title>
                      <div className="mt-2">
                        {isLoading ? (
                          <div role="status">
                            <Spinner />
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <>
                            <p className="text-sm text-gray-500">{message}</p>
                            {count ? (
                              <EquipmentTypesDropdown
                                className="mt-3"
                                onEquipmentSelect={(id) => setEquipmentId(id)}
                              />
                            ) : null
                            }
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={isLoading}
                    className={
                      'inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto' +
                      (isLoading || count !== 0 && !equipmentId ? ' cursor-not-allowed' : '')
                    }
                    onClick={() => onDestructiveClick()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => onCancel()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteEquipmentModal;

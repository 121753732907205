import { Popover } from '@headlessui/react';
import { ChevronDownIcon, CalendarDaysIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { convertLocalToUTCDate } from '../../../utils/date';
import { DateRangePicker } from '../../common/date-range-picker/date-range-picker';

import TablePopoverPanelFilter from './table-popover-panel-filter';
import TablePopoverTransition from './table-popover-transition';

type TablePopoverProps = {
  title: string;
  sortable?: boolean;
  itemKey?: string;
  isDateFilter?: boolean;
  startDateKey?: string;
  endDateKey?: string;
};

export default function TablePopover({
  title,
  sortable,
  itemKey,
  isDateFilter,
  startDateKey,
  endDateKey,
}: TablePopoverProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (itemKey) {
      const filterValues = searchParams.getAll(itemKey);
      setIsFilterActive(filterValues.length > 0);
    } else if (isDateFilter) {
      const startDateValue = searchParams.get(startDateKey || '');
      const endDateValue = searchParams.get(endDateKey || '');
      setIsFilterActive(!!startDateValue || !!endDateValue);
    }
  }, [searchParams, itemKey, isDateFilter, startDateKey, endDateKey]);

  if (isDateFilter) {
    return (
      <div className="w-full">
        <Popover className="relative">
          {() => (
            <>
              <Popover.Button
                className={`inline-flex items-center rounded-md p-1 text-sm font-medium text-black focus:outline-none bg-[#f5f4f4]
                  ${isFilterActive ? 'border border-blue-500' : ''}`}
              >
                <span>{title}</span>
                <CalendarDaysIcon
                  className="ml-3 h-4 w-4 text-black transition duration-150 ease-in-out"
                  aria-hidden="true"
                />
              </Popover.Button>
              <Popover.Panel className="absolute mt-2 z-40 p-4 bg-white border rounded shadow-lg">
                <DateRangePicker
                  startDateKey={startDateKey ? startDateKey : ''}
                  endDateKey={endDateKey ? endDateKey : ''}
                  onChange={(start, end) => {
                    if (startDateKey) {
                      const utcStart = convertLocalToUTCDate(start);
                      searchParams.set(startDateKey, utcStart.toISOString().split('T')[0]);
                    }
                    if (endDateKey) {
                      const utcEnd = convertLocalToUTCDate(end);
                      searchParams.set(endDateKey, utcEnd.toISOString().split('T')[0]);
                    }
                    setSearchParams(searchParams);
                  }}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>
      </div>
    );
  }

  return (
    <div className="w-full">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button
              className={`inline-flex items-center rounded-md p-1 text-sm font-medium text-black ${
                sortable && isFilterActive ? 'bg-[#f5f4f4] border border-blue-500' : sortable ? 'bg-[#f5f4f4]' : ''
              } focus:outline-none`}
            >
              <span>{title}</span>
              {sortable ? (
                <ChevronDownIcon
                  className="ml-3 h-4 w-4 text-black transition duration-150 ease-in-out"
                  aria-hidden="true"
                />
              ) : null}
            </Popover.Button>
            {sortable ? (
              <TablePopoverTransition>
                <Popover.Panel className="absolute mt-2 z-40">
                  <TablePopoverPanelFilter itemKey={itemKey} />
                </Popover.Panel>
              </TablePopoverTransition>
            ) : null}
          </>
        )}
      </Popover>
    </div>
  );
}

import { Spinner } from '../../spinner';

type GenerateInvoicePreviewButtonProps = {
  icon?: string;
  title: string;
  isGenerating: boolean;
  generatingText?: string;
  className?: string;
  onClick?: () => void;
};

export const GenerateInvoicePreviewButton = ({
  icon,
  title,
  isGenerating,
  generatingText = 'Generating...',
  className = '',
  onClick,
}: GenerateInvoicePreviewButtonProps) => {
  return (
    <button
      type="button"
      className={`flex justify-start items-center h-full px-2 sm:px-3 py-1 border border-[#dde2e4ff] border-solid rounded-lg box-border bg-[rgba(255,255,255,1)] ${className}`}
      disabled={isGenerating}
      onClick={onClick}
    >
      {isGenerating ? (
        <>
          {icon && <Spinner />}
          <span className="border-[#252c32ff] text-xs sm:text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px] ml-2">
            {generatingText}
          </span>
        </>
      ) : (
        <>
          {icon && <img src={icon} alt="Not Found" className="w-3 h-3 sm:w-4 sm:h-4" />}
          <p className="border-[#252c32ff] text-xs sm:text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px] ml-2">
            {title}
          </p>
        </>
      )}
    </button>
  );
};

import { Menu } from '@headlessui/react';

export default function DropdownItem({ title, children, onClick, buttonType }: any) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type={buttonType}
          onClick={onClick}
          className={`${
            active ? 'bg-[#D7EDFF] text-[#0E73F6]' : 'text-gray-900'
          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
        >
          {children}
          {title}
        </button>
      )}
    </Menu.Item>
  );
}

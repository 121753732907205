import React from 'react';
import { Navigate } from 'react-router-dom';

import { ActiveRentalPage } from '../pages/active-rental/active-rental.page';
import { CustomerCreatePage } from '../pages/customer/customer-create.page';
import { CustomerEditPage } from '../pages/customer/customer-edit.page';
import { CustomerPricingCreatePage } from '../pages/customer/customer-pricing-create.page';
import { CustomerPricingEditPage } from '../pages/customer/customer-pricing-edit.page';
import { CustomerPricingPage } from '../pages/customer/customer-pricing.page';
import { CustomerPage } from '../pages/customer/customer.page';
import { CustomerDetailsPage } from '../pages/customer/details/customer-details.page';
import { CustomerInvoiceGroups } from '../pages/customer/invoicing/customer-invoice-groups';
import { InvoiceGroupCreate } from '../pages/customer/invoicing/invoice-group-create.page';
import { InvoiceGroupEdit } from '../pages/customer/invoicing/invoice-group-edit.page';
import { CustomerInvoiceGroupView } from '../pages/customer/invoicing/invoice-group-view.page';
import { EquipmentCreatePage } from '../pages/equipment/equipment-create.page';
import { EquipmentEditPage } from '../pages/equipment/equipment-edit.page';
import { EquipmentViewPage } from '../pages/equipment/equipment-view.page';
import { EquipmentPage } from '../pages/equipment/equipment.page';
import { ImportHistoryPage } from '../pages/import-history/import-history.page';
import { InventoryCreatePage } from '../pages/inventory/inventory-create.page';
import { InventoryEditPage } from '../pages/inventory/inventory-edit.page';
import { InventoryViewPage } from '../pages/inventory/Inventory-view.page';
import { InventoryPage } from '../pages/inventory/Inventory.page';
import InvoicingPage from '../pages/invoicing/invoicing-page';
import { ChooseOrderPage } from '../pages/order/choose-order.page';
import { CollectOrderSignature } from '../pages/order/collect-order-signature';
import { CreateOrderPage } from '../pages/order/create-order.page';
import { OrderEditPage } from '../pages/order/order-edit.page';
import { OrderViewPage } from '../pages/order/order-view.page';
import { OrderPage } from '../pages/order/order.page';
import { PickupOrderViewPage } from '../pages/order/pickup-order-view.page';
import { ScannerPage } from '../pages/scanner/scanner.page';
import { UsersPage } from '../pages/users/users.page';

export const routes = [
  /** LANDING PAGE **/
  {
    path: '/',
    element: <Navigate to={'/order'} />,
  },
  /** INVENTORY ROUTES **/
  {
    path: '/inventory',
    element: <InventoryPage />,
  },
  {
    path: '/inventory/create',
    element: <InventoryCreatePage />,
  },
  {
    path: '/inventory/:id',
    element: <InventoryViewPage />,
  },
  {
    path: '/inventory/edit/:id',
    element: <InventoryEditPage />,
  },
  /** SCANNER **/
  {
    path: '/scanner',
    element: <ScannerPage />,
  },
  /** EQUIPMENT ROUTES **/
  {
    path: '/equipment/create',
    element: <EquipmentCreatePage />,
  },
  {
    path: '/equipment/edit/:id',
    element: <EquipmentEditPage />,
  },
  {
    path: '/equipment',
    element: <EquipmentPage />,
  },
  {
    path: '/equipment/:id',
    element: <EquipmentViewPage />,
  },
  /** CUSTOMER ROUTES **/
  {
    path: '/customer',
    element: <CustomerPage />,
  },
  {
    path: '/customer/:customerId',
    element: <CustomerDetailsPage />,
  },
  {
    path: '/customer/create',
    element: <CustomerCreatePage />,
  },
  {
    path: '/customer/edit/:id',
    element: <CustomerEditPage />,
  },
  /** CUSTOMER PRICING ROUTES **/
  {
    path: '/customer/:customerId/pricing',
    element: <CustomerPricingPage />,
  },
  {
    path: '/customer/:customerId/pricing/create',
    element: <CustomerPricingCreatePage />,
  },
  {
    path: '/customer/:customerId/pricing/edit/:pricingId',
    element: <CustomerPricingEditPage />,
  },
  {
    path: '/active-rental',
    element: <ActiveRentalPage />,
  },
  /** INVOICING ROUTES */
  {
    path: '/invoicing',
    element: <InvoicingPage />,
  },
  /** IMPORT HISTORY */
  {
    path: '/import-history',
    element: <ImportHistoryPage />,
  },
  {
    path: '/order',
    element: <OrderPage />,
  },
  {
    path: '/order/choose',
    element: <ChooseOrderPage />,
  },
  {
    path: '/order/create',
    element: <CreateOrderPage />,
  },
  {
    path: '/order/edit/:id',
    element: <OrderEditPage />,
  },
  {
    path: '/order/:id',
    element: <OrderViewPage />,
  },
  {
    path: '/pickup-order/:id',
    element: <PickupOrderViewPage />,
  },
  {
    path: '/order/:orderId/signature',
    element: <CollectOrderSignature />,
  },
  /** USERS */
  {
    path: '/users',
    element: <UsersPage />,
  },
  /** INVOICING */
  {
    path: '/customer/:customerId/groups',
    element: <CustomerInvoiceGroups />,
  },
  {
    path: '/customer/:customerId/group/:groupId',
    element: <CustomerInvoiceGroupView />,
  },
  {
    path: '/customer/:customerId/group/create',
    element: <InvoiceGroupCreate />,
  },
  {
    path: '/customer/:customerId/group/edit/:groupId',
    element: <InvoiceGroupEdit />,
  },
];

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCustomersList } from '../../../api/customer/get-customers-list';
import { useEquipments } from '../../../api/equipment/get-equipments';
import { useLocation } from '../../../api/location/get-location';
import { useOrderStatuses, useDeliveryModes, useOrderTypes } from '../../../api/order/get-order-constants';
import { useOwner } from '../../../api/owner/get-owner';
import { useStatus } from '../../../api/status/get-status';
import { useType } from '../../../api/type/get-type';
import { useUserList } from '../../../api/user/get-user-list';

import TablePopoverList from './table-popover-list';
import TablePopoverPagination from './table-popover-pagination';
import TablePopoverSearch from './table-popover-search';

const hooks: { [key: string]: any } = {
  productIds: useEquipments,
  statusIds: useStatus,
  ownerIds: useOwner,
  typeIds: useType,
  locIds: useLocation,
  atIds: useCustomersList,
  customerIds: useCustomersList,
  userIds: useUserList,
  orderStatuses: useOrderStatuses,
  orderType: useOrderTypes,
  deliveryModes: useDeliveryModes,
};

// TODO: Consider consolidating this with TablePopoverPanelFormInput for shared use
export default function TablePopoverPanelFilter({ itemKey, parentValue }: any) {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [isChecked, setIsChecked] = useState<(number | string)[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const hook = hooks[itemKey];

  // TODO: Consolidate use of URLSearchParams vs GetItemProps
  const response = hook({
    search,
    page,
  });

  const convertIdsToAppropriateType = (id: string | number) => {
    if (id === 'UNASSIGNED') return id;
    return isNaN(Number(id)) ? id : Number(id);
  };

  const convertAllIdsToAppropriateType = (ids: (string | number)[]) => {
    return ids.map(convertIdsToAppropriateType);
  };  

  useEffect(() => {
    const params = searchParams.getAll(itemKey);
    setIsChecked(convertAllIdsToAppropriateType(params));
  }, [searchParams, itemKey]);


  const unassigned = { id: 'UNASSIGNED', name: 'Unassigned' };
  const modifiedItems = (response.data && itemKey === 'userIds')
    ? [...response.data.items, unassigned]
    : response.data?.items || [];

  const handleSelect = (e: any) => {
    const { id, checked } = e.target;
    const idValue = convertIdsToAppropriateType(id);
  
    let newCheckedItems;
    if (checked) {
      newCheckedItems = [...isChecked, idValue];
    } else {
      newCheckedItems = isChecked.filter((i) => i !== idValue);
    }

    setIsChecked(newCheckedItems);

    searchParams.delete(itemKey);
    newCheckedItems.forEach((c) => searchParams.append(itemKey, String(c)));
    setSearchParams(searchParams);
  };

  const onSearch = async (search: string) => {
    await setSearch(search);
    await response.refetch();
  };

  const onPageChange = async (page: number) => {
    await setPage(page);
    await response.refetch();
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-5 bg-white text-sm">
      <TablePopoverSearch value={search} onSearch={onSearch} />
      {response.isFetched ? (
        <>
          <TablePopoverList
            items={modifiedItems}
            isChecked={isChecked.length ? isChecked : [Number(parentValue)]}
            onSelect={handleSelect}
          />
          <TablePopoverPagination
            onChange={onPageChange}
            page={Number(response.data.page)}
            perPage={Number(response.data.perPage)}
            count={Number(response.data.count)}
          />
        </>
      ) : null}
    </div>
  );
}

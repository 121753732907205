import React from 'react';

import { useOrderHistory } from '../../api/order/get-order-history';
import { formatDate } from '../../lib/format-date';
import { camelCaseToString } from '../../lib/format-string';
import { TableContainer } from '../common/table/table-container';
import { TableDataCell } from '../common/table/table-data-cell';
import { TableDataRow } from '../common/table/table-data-row';
import { TableDataValue } from '../common/table/table-data-value';
import { TableTitle } from '../common/table/table-title';

type Props = {
  orderId: number;
}

const productHistory = ['Team member', 'Description', 'Date'];

const OrderHistory: React.FC<Props> = ({ orderId }) => {
  const { data } = useOrderHistory(orderId, {
    suspense: true,
    select(data) {
      return data?.map((history) => {
        const newValue = history.newValue ? history.newValue : 'N/A';
        const oldValue = history.oldValue ? history.oldValue : 'N/A';
        const field = camelCaseToString(history.field).replace('Id', '');

        return {
          ...history,
          description: `Changed "${field}" from "${oldValue}" to "${newValue}"`,
          createdAt: formatDate(history.createdAt),
        }
      });
    }
  });


  return (
    <>
      <TableTitle title={'History'} />
      <TableContainer className=" h-full">
        <TableDataRow className={'h-14 bg-gray-50'}>
          {productHistory.map((col: string, key: number) => (
            <TableDataCell className="px-[10px] font-bold" key={key}>
              <TableDataValue text={col} />
            </TableDataCell>
          ))}
        </TableDataRow>

        {data?.length ? data?.map((history) => (
          <TableDataRow className={'h-14'} key={history.id}>
            <>
              <TableDataCell className="px-[10px]">
                <TableDataValue
                  text={history?.user?.name}
                  outlineStyle={'border-2 border py-1 px-2 rounded-full'} />
              </TableDataCell>
              <TableDataCell className="px-[10px]">
                <TableDataValue text={history.description} />
              </TableDataCell>
              <TableDataCell className="px-[10px]">
                <TableDataValue text={history.createdAt} />
              </TableDataCell>
            </>
          </TableDataRow>
        )) : (
          <TableDataRow className={'h-14'}>
            <TableDataCell colSpan={3} className="px-[10px] text-center">
              <TableDataValue text={'No history found'} />
            </TableDataCell>
          </TableDataRow>
        )}
      </TableContainer>
    </>
  )
}

export default OrderHistory;

import { Label } from './label';
import { TextArea } from './textArea';

export type LabelTextAreaProps = {
  title: string;
  className?: string;
  placeholder?: string;
  attributes: any;
};

export const LabelTextArea = ({ title, attributes, className, placeholder }: LabelTextAreaProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <Label title={title} />
      <TextArea attributes={attributes} className={className} placeholder={placeholder} />
    </div>
  );
};

import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { Order } from '../../api/order';
import { useOrderCreate } from '../../api/order/create-order';
import { useOrderPickupCreate } from '../../api/order/create-pickup-order';
import { useUsers } from '../../api/user/get-users';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { OrderForm } from '../../components/order/form/order-form';
import { OrderFormTabs } from '../../components/order/tabs/order-form-tabs';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

const DELIVERY = 'DELIVERY';
const PICKUP = 'PICKUP';

export const CreateOrderPage = () => {
  const { roles } = useRoles();
  const isUserOrAdmin =
    roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  const methods = useForm<Order>({
    defaultValues: {
      isReceiverName: true,
    },
  });

  const { setValue, handleSubmit } = methods;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const type = searchParams.get('type');

  const createOrder = useOrderCreate();
  const createPickupOrder = useOrderPickupCreate();

  const { isSuccess: isSuccessUsers, data: users } = useUsers();

  const onSubmit = async (values: any) => {
    try {
      if (type === DELIVERY) await createOrder.mutateAsync(values);
      if (type === PICKUP) await createPickupOrder.mutateAsync(values);
    } catch {
      // Errors caught and toasted by axios interceptor
      return;
    }

    navigate('/order');
  };

  useEffect(() => {
    type ? setValue('orderType', type) : null;
  }, []);

  if (!type) return <Navigate to={'/order/choose'} />;

  if (!isSuccessUsers) return <Spinner />;

  return (
    <PageContainer className="overflow-x-visible">
      {isUserOrAdmin ? (
        <>
          <NavigationBackButton url={'/order/choose'} />
          <TitleActions title={type === DELIVERY ? ' Create Delivery Order' : 'Create Pickup Order'} />
          <OrderFormTabs />

          <FormProvider {...methods}>
            <OrderForm
              onSubmit={(fn: any) => handleSubmit((values) => fn(values, onSubmit))}
              users={users.items}
              equipments={[]}
              isForEdit={false}
            />
          </FormProvider>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

type TableActionButtonProps = {
  icon?: string;
  className?: string;
  onClick?: () => void;
  title: string;
  disabled?: boolean;
};

export const ActionButton = ({ icon, title, onClick, className, disabled }: TableActionButtonProps) => {
  return (
    <div
      className={`flex justify-start items-center h-full px-2 sm:px-3 py-1 border border-[#dde2e4ff] border-solid rounded-lg box-border  bg-[rgba(255,255,255,1)] ${className}`}
    >
      <button
        onClick={onClick}
        className="flex items-center h-full w-full box-border gap-2 disabled:opacity-50"
        disabled={disabled === undefined ? undefined : disabled}
      >
        {icon && <img src={icon} alt="Not Found" className="w-3 h-3 sm:w-4 sm:h-4" />}
        <p className="border-[#252c32ff] text-xs sm:text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px]">
          {title}
        </p>
      </button>
    </div>
  );
};

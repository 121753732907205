import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMER_LIST_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps, ResourceResponse } from '../types';

import { Customer } from './index';

export const getCustomersList = (params: GetItemProps): Promise<ResourceResponse<Customer>> => {
  return axios.get('/customer', { params: { perPage: '10', ...params } });
};

export const useCustomersList = (args: GetItemProps) => {
  return useQuery<ResourceResponse<Customer>, AxiosError<AxiosErrorResponse>>({
    queryKey: [CUSTOMER_LIST_KEY, args.toString()],
    queryFn: () => getCustomersList(args),
    keepPreviousData: true,
  });
};

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { ORDERS_KEY } from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { AxiosErrorResponse } from '../../lib/types';

type AssignProps = {
  orderInventoryIds: number[];
  invoiceGroupId: number;
};

export const assignOrderInventoriesToInvoiceGroup = ({
  orderInventoryIds,
  invoiceGroupId,
}: AssignProps): Promise<void> => {
  return axios.post(`order/inventories/invoice-group/${invoiceGroupId}`, {
    inventories: orderInventoryIds,
  });
};

export const useAssignOrderInventoriesToInvoiceGroup = () => {
  return useMutation<void, AxiosError<AxiosErrorResponse>, any, any>({
    mutationFn: assignOrderInventoriesToInvoiceGroup,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });
    },
  });
};

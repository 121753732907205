import { Order } from '../../../api/order';
import { useOrderUpdate } from '../../../api/order/update-order';
import { User } from '../../../api/user';
import Dropdown from '../../common/dropdown/dropdown';
import DropdownItem from '../../common/dropdown/dropdown-item';

export type PersonAssignedDropdownProps = {
  users: User[];
  order: Order;
  className?: string;
};

export const PersonAssignedDropdown = ({ users, className, order }: PersonAssignedDropdownProps) => {

  const updateOrderMutation = useOrderUpdate();

  const assignUser = (userId: number) => {
    if (order) {
      const updatedOrder = {
        ...order,
        userId: userId,
      };
      updateOrderMutation.mutate(updatedOrder);
    }
  };

  const defaultValue = order?.userId ? users.find(user => user.id === order.userId)?.name : 'Assign User';

  return (
    <div className={`${className}`}>
      {users.length ? (
        <Dropdown title="Assign User" defaultValue={defaultValue}>
          {users.map((user) => (
            <DropdownItem
              title={user.name}
              onClick={() => assignUser(user.id)}
              key={user.id}
            />
          ))}
        </Dropdown>
      ) : null}
    </div>
  );
};

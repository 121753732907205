import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ORDERS_KEY } from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

type MakeOrderActiveRentalParams = {
  id: string | number;
  billingStartDate: Date;
};

export const makeOrderActiveRental = ({ id, billingStartDate }: MakeOrderActiveRentalParams): Promise<void> => {
  return axios.post(`/order/activeRental/${id}`, { billingStartDate });
};

export const useOrderActiveRental = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Order is in Active Rental status!',
      });
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });
    },
    mutationFn: makeOrderActiveRental,
  });
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { INVOICE_GROUP_ITEMS_KEY } from '../../lib/constants';
import { QueryConfig } from '../../lib/react-query';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { InvoiceGroupItems } from './index';

export type GetInvoiceGroupItemsProps = {
  customerId: string;
  groupId: string;
  page: number;
  perPage: number;
};

export const getInvoiceGroupItems = ({
  customerId,
  groupId,
  page = 1,
  perPage = 10,
}: GetInvoiceGroupItemsProps): Promise<ResourceResponse<InvoiceGroupItems>> => {
  const params = { page, perPage};

  return axios.get(`/invoicing/${customerId}/group/${groupId}`, { params });
};

type QueryFnType = typeof getInvoiceGroupItems;

type UseInvoiceGroupItems<TData> = {
  args: any
  config?: QueryConfig<QueryFnType, TData>;
};

export const useInvoiceGroupItems = <TData>({ args, config }: UseInvoiceGroupItems<TData>) => {
  return useQuery<ResourceResponse<InvoiceGroupItems>, AxiosError<AxiosErrorResponse>, TData>({
    ...config,
    queryKey: [INVOICE_GROUP_ITEMS_KEY, args.customerId, args.groupId, args.page, args.perPage],
    queryFn: () => getInvoiceGroupItems(args),
    keepPreviousData: true,
  });
};

export type RadioProps = {
  attributes: any;
  title: string;
  name: string;
  value: string | number | boolean;
};

export const Radio = ({ attributes, title, name, value }: RadioProps) => {
  return (
    <label htmlFor={title} className="text-sm">
      <input type="radio" {...attributes} className="mr-2" name={name} value={value} />
      {title}
    </label>
  );
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { INVOICE_GROUP_KEY } from '../../lib/constants';
import { QueryConfig } from '../../lib/react-query';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { InvoiceGroup } from './index';

export type GetInvoiceGroupsProps = {
  customerId: string;
  page: number;
  perPage: number;
};

export const getInvoiceGroups = ({
  customerId,
  page = 1,
  perPage = 10,
}: GetInvoiceGroupsProps): Promise<ResourceResponse<InvoiceGroup>> => {
  const params = { page, perPage };

  return axios.get(`/invoicing/groups/${customerId}`, { params });
};

type QueryFnType = typeof getInvoiceGroups;

type UseInvoiceGroups<TData> = {
  args: any;
  config?: QueryConfig<QueryFnType, TData>;
};

export const useInvoiceGroups = <TData>({ args, config }: UseInvoiceGroups<TData>) => {
  return useQuery<ResourceResponse<InvoiceGroup>, AxiosError<AxiosErrorResponse>, TData>({
    ...config,
    queryKey: [INVOICE_GROUP_KEY, args.customerId, args.page, args.perPage],
    queryFn: () => getInvoiceGroups(args),
    keepPreviousData: true,
  });
};

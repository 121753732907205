import { TablePopoverCheckbox } from './table-popover-checkbox';

const formatText = (text?: string): string => {
  if (!text) return '';
  return text
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export default function TablePopoverList({ items, isChecked, onSelect }: any) {

  const isItemChecked = (keyOrId: string | number) => {
    if (typeof keyOrId === 'string') {
      return isChecked.includes(keyOrId);
    } else {
      return isChecked.includes(Number(keyOrId));
    }
  };

  return (
    <>
      {items?.map((i: any, key: number) => (
        <div className="flex justify-start items-center w-[100%] box-border gap-3" key={key}>
          <TablePopoverCheckbox
            id={i.key || i.id}
            onChange={onSelect}
            checked={isItemChecked(i.key || i.id)} 
          />
          <span>{i.title || i.name || formatText(i.status) || formatText(i.type) || formatText(i.deliveryMode)}</span>
        </div>
      ))}
    </>
  );
}

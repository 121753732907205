import { Input } from './input';
import { Label } from './label';

export type LabelInputProps = {
  title: string;
  className?: string;
  placeholder?: string;
  type?: string;
  attributes?: any;
  isTextarea?: boolean;
};

export const LabelInput = ({ title, attributes, className, placeholder, type = 'text', isTextarea = false }: LabelInputProps & { isTextarea?: boolean }) => {
  return (
    <div className="flex flex-col space-y-1">
      <Label title={title} />
      <Input attributes={attributes} className={`${className} disabled:opacity-40`} type={type} placeholder={placeholder} isTextarea={isTextarea} />
    </div>
  );
};

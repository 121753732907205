import { TableDataCell } from '../common/table/table-data-cell';

import { TableItem } from './table-item';

export const TableRows = ({ item }: any) => {
  const keys = Object.keys(item).filter((k) => k !== 'id' && k !== 'links' && k !== 'titleLink' && k !== 'data-object');
  return (
    <>
      {keys.map((key: string) => (
        <TableDataCell className="pl-[22px]" key={key}>
          <TableItem text={item[key]} outlineKey={key} />
        </TableDataCell>
      ))}
    </>
  );
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { useNotificationStore } from '../../stores/notifications';

import { Order } from './index';

export const createOrder = (data: Order): Promise<Order> => {
  return axios.post('/order', { ...data });
};

export const useOrderCreate = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Order created successfully',
      });
    },
    mutationFn: createOrder,
  });
};

import { InvoiceGroup } from '../../../api/invoicing';
import { useBulkUpdateInventoryInvoiceGroup } from '../../../api/invoicing/set-inventory-invoice-group';
import Dropdown from '../../common/dropdown/dropdown';
import DropdownItem from '../../common/dropdown/dropdown-item';

export type InventoryBulkUpdateInvoiceGroupDropdownProps = {
  groups: InvoiceGroup[];
  inventories: number[];
  customerId: number;
  clearBulkSelect: () => void;
  className?: string;
};

export const InventoryBulkUpdateInvoiceGroupDropdown = ({
  groups,
  customerId,
  className,
  inventories,
  clearBulkSelect,
}: InventoryBulkUpdateInvoiceGroupDropdownProps) => {
  const setInventoryUpdateInvoiceGroup = useBulkUpdateInventoryInvoiceGroup(clearBulkSelect);

  const onChangeInvoiceGroup = async (inventories: number[], customerId: number, groupId: number) => {
    await setInventoryUpdateInvoiceGroup.mutate({ inventories, customerId, groupId });
  };

  return (
    <div className={`${className}`}>
      {groups.length ? (
        <Dropdown title="Update Invoice Group Items" disabled={inventories.length === 0}>
          {groups.map((g, k) => (
            <DropdownItem
              title={`${g.name}`}
              onClick={async () => await onChangeInvoiceGroup(inventories, customerId, g.id)}
              key={k}
            ></DropdownItem>
          ))}
        </Dropdown>
      ) : null}
    </div>
  );
};

import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export type NavigationButtonProps = {
  title: string;
  url: string;
  children: ReactNode;
};

export const NavigationButton = ({ title, url, children }: NavigationButtonProps) => {
  return (
    <NavLink to={url} className="outline outline-1 p-1 rounded-md inline-flex items-center outline-[#DDE2E4]">
      {children}
      <p className="text-sm ml-2 mr-3 text-gray">{title}</p>
    </NavLink>
  );
};

import { useRoles } from '../../../provider/roles-provider';

import { MenuList } from './menu-list';

const navigation = [
  {
    name: 'Orders',
    href: '/order',
  },
  {
    name: 'All Inventory',
    children: [
      { name: 'Inventory', href: '/inventory' },
      { name: 'Equipment Type', href: '/equipment', disabled: true },
    ],
  },
  {
    name: 'Active Rentals',
    href: '/active-rental',
  },
  {
    name: 'Invoicing',
    href: '/invoicing',
  },
  {
    name: 'Customers',
    href: '/customer',
  },
  {
    name: 'Alerts',
    messages: 24,
  },
  {
    name: 'Import History',
    href: '/import-history',
    active: false,
  },
  {
    name: 'Users',
    href: '/users',
  },
];

const userPagesExceptions = ['Invoicing', 'Customers', 'Equipment Type'];

type DashboardSidebarProps = {
  signOut: any; // function
};

export const DashboardSidebar = ({ signOut }: DashboardSidebarProps) => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const rolesBasedNavigation = navigation.map((item) => {
    if (userPagesExceptions.includes(item.name)) {
      return { ...item, disabled: !isAdmin };
    }

    if (item.name === 'All Inventory') {
      return {
        ...item,
        children: item.children?.map((child) => {
          if (userPagesExceptions.includes(child.name)) {
            return { ...child, disabled: !isAdmin };
          }
          return child;
        }),
      };
    }
    return item;
  });

  const featureFlagBasedNavigation = rolesBasedNavigation.reduce((acc, curr) => {
    if (curr.name === 'Import History' && process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED !== 'true') {
      return acc;
    } else {
      acc.push(curr);
      return acc;
    }
  }, [] as any[]);

  return (
    <div className="hidden flex-col w-[240px] min-w-[240px] bg-[#F6F8F9] min-h-screen p-4 md:flex">
      <div className="flex items-center mb-7">
        <img className="h-8 w-auto" src="/media/logo.svg" alt="PMR" />
      </div>
      <nav className="flex-1">
        <MenuList navigation={featureFlagBasedNavigation} />
        <button className="flex mr-auto items-center text-gray-600 text-sm gap-1 px-2 pt-6" onClick={signOut}>
          Logout
        </button>
      </nav>
    </div>
  );
};

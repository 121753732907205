import { useState } from 'react';

import { Category } from '../../../api/category';
import { useOneType } from '../../../api/type/get-one-type';
import PopoverSelect from '../../common/popover-select/popover-select';
import { Checkbox } from '../../form/checkbox';
import { LabelInput } from '../../form/labelInput';
import { LabelSelect } from '../../form/labelSelect';
import { SubmitButton } from '../../form/submit-button';

const pmTypes = [
  { id: 'Annual', title: 'Annual' },
  { id: 'Other', title: 'Other' },
];

export type EquipmentFormProps = {
  setValue: any;
  onSubmit: () => void;
  register: any;
  isSubmitting: boolean;
  initialType?: any;
  categories: Category[];
};

export const EquipmentForm = ({
  setValue,
  onSubmit,
  register,
  isSubmitting,
  initialType,
  categories,
}: EquipmentFormProps) => {
  const [typeId, setTypeId] = useState<any>(initialType || null);

  const { data } = useOneType(typeId);

  return (
    <form onSubmit={onSubmit} className="w-[100%] sm:w-[50%]">
      <div className="flex flex-col space-y-4 w-full">
        <LabelInput title={'Product Name'} attributes={{ ...register('title', { required: true }) }} />

        <PopoverSelect
          title="Type"
          setedValue={data?.title}
          parentValue={typeId}
          parentValueSetter={setTypeId}
          itemKey="typeIds"
          setValue={setValue}
          registerName="typeId"
          register={register}
          isRequired
        />

        <LabelSelect
          title="Category"
          data={categories.map((c) => ({ id: c.id, title: c.title }))}
          option="Select"
          attributes={{ ...register('categoryId', { required: 'Select one option', valueAsNumber: true }) }}
        />

        <Checkbox attributes={{ ...register('isDisposable') }} title={'Is Disposable'} />

        <Checkbox attributes={{ ...register('isPart') }} title={'Is Part'} />

        <LabelSelect
          title="PM Type"
          data={pmTypes.map((item) => ({ id: item.id, title: item.title }))}
          option="Select"
          attributes={{ ...register('pm_type') }}
          defaultValue="Annual"
        />

        <LabelInput title={'Manufacturer'} attributes={{ ...register('manufacturer', { required: true }) }} />

        <SubmitButton isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

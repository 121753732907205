import { Spinner } from '../spinner';

export type SubmitButtonProps = {
  isSubmitting: boolean;
  submitText?: string;
  submittingText?: string;
  submittingSpinner?: boolean;
};

export const SubmitButton = ({
  isSubmitting,
  submitText = 'Save',
  submittingText = 'Saving...',
  submittingSpinner = false,
}: SubmitButtonProps) => {
  return (
    <button
      type="submit"
      className="bg-[#030299] text-white rounded-md px-4 py-2 my-4 min-w-[250px] max-w-[450px]"
      disabled={isSubmitting}
    >
      {isSubmitting ? submittingSpinner ? <Spinner /> : submittingText : submitText}
    </button>
  );
};

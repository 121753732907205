type TableCheckboxProps = {
  id?: number;
  setChecked: (e: any) => void;
  checked: boolean;
};

export const TableCheckbox = ({ id, setChecked, checked }: TableCheckboxProps) => {
  return (
    <input
      type="checkbox"
      id={`${id}`}
      className="cursor-pointer w-[18px] h-[18px] border-2 border-[#666666] rounded-sm focus:outline-none"
      onChange={setChecked}
      checked={checked}
    />
  );
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { EQUIPMENT_LIST_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps, ResourceResponse } from '../types';

import { Equipment } from './index';

export const getEquipments = (params: GetItemProps): Promise<ResourceResponse<Equipment>> => {
  return axios.get('/product', { params: { ...params, perPage: '10' } });
};

export const useEquipments = (params: GetItemProps) => {
  return useQuery<ResourceResponse<Equipment>, AxiosError<AxiosErrorResponse>>({
    queryKey: [EQUIPMENT_LIST_KEY, params.page, params.search || 1],
    queryFn: () => getEquipments(params),
    keepPreviousData: true,
  });
};

import { useQuery } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { EQUIPMENT_KEY } from '../../lib/constants';
import { ExtractFnReturnType } from '../../lib/react-query';

import { Equipment } from './index';

export const getOneEquipment = (id: number): Promise<Equipment> | null => {
  if (id === null || isNaN(id)) return null;
  return axios.get(`/product/${id}`);
};

type QueryFnType = typeof getOneEquipment;

export const useOneEquipment = (id: number, enabled = true) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: [EQUIPMENT_KEY, id],
    queryFn: () => getOneEquipment(id),
    keepPreviousData: true,
    enabled
  });
};

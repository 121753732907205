export type ButtonCancelProps = {
  text: string;
  onClick?: () => void;
};

export const ButtonCancel = ({ text, onClick }: ButtonCancelProps) => {
  return (
    <button
      className={`my-4 min-w-[250px] max-w-[450px] font-bold bg-red-600 text-white py-1 px-2 rounded-full`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
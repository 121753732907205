import { ChangeEventHandler } from 'react';

import { Label } from './label';
import { Select } from './select';

export type LabelSelectProps = {
  title: string;
  option?: string;
  skipOption?: boolean;
  data: any[];
  attributes: any;
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

export const LabelSelect = ({ title, data, attributes, option, skipOption, defaultValue, onChange }: LabelSelectProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <Label title={title} />
      <Select onChange={onChange} attributes={attributes} data={data} option={option} skipOption={skipOption} defaultValue={defaultValue} />
    </div>
  );
};

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export default function TablePopoverPagination({ onChange, page, perPage, count }: any) {
  const from = page === 1 ? 1 : page > 1 ? (page - 1) * perPage : 0;

  const to = (page === 1 ? 0 : from) + perPage;

  const next = () => {
    if (to >= count) return;
    onChange(page + 1);
  };

  const previous = () => {
    if (page > 1) {
      onChange(page - 1);
    }
  };

  return (
    <div className="flex justify-center items-center w-[100%] box-border gap-3 text-[12px]">
      <span className="flex">
        {from + (page === 1 ? 0 : 1)} - {to >= count ? count : to} of {count}
      </span>
      <ChevronLeftIcon className={`h-5 ${page === 1 ? 'text-gray-300' : ''}`} onClick={previous} />
      <ChevronRightIcon className={`h-5 ${to >= count ? 'text-gray-300' : ''}`} onClick={next} />
    </div>
  );
}

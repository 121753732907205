import { NavLink } from 'react-router-dom';

type TableItemProps = {
  linkString?: string;
  productId?: string;
  text: string | number | boolean;
  outlineKey?: string;
};

const fieldsToOutline = ['type', 'view', 'edit'];

export const TableItem = ({ text, outlineKey, productId, linkString }: TableItemProps) => {
  if (typeof text === 'boolean') {
    text = text ? 'Yes' : 'No';
  }

  const hasKey = outlineKey ? fieldsToOutline.includes(outlineKey) : false;

  const outlineStyle = hasKey ? 'border-2 border py-1 px-2 rounded-full' : '';

  if (linkString) {
    return (
      <div className="text-[14px]">
        <NavLink to={linkString ?? '#'}>{text}</NavLink>
      </div>
    );
  }

  if (outlineKey === 'view') {
    return (
      <div className="text-[14px]">
        <NavLink to={productId ? `${productId}` : '#'} className={outlineStyle}>
          {text}
        </NavLink>
      </div>
    );
  }
  if (outlineKey === 'edit') {
    return (
      <div className="text-[14px]">
        <NavLink to={productId ? `edit/${productId}` : '#'} className={outlineStyle}>
          {text}
        </NavLink>
      </div>
    );
  } else {
    return (
      <div className="text-[14px]">
        <span className={outlineStyle}>{text}</span>
      </div>
    );
  }
};

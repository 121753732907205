import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useLocation as useRouterLocation } from 'react-router-dom';

import { useCustomersList } from '../../api/customer/get-customers-list';
import { useEquipments } from '../../api/equipment/get-equipments';
import { Inventory } from '../../api/inventory';
import { useMasterInventoryItem } from '../../api/inventory/get-inventory-item';
import { useInventoryEdit } from '../../api/inventory/update-inventory';
import { useLocation } from '../../api/location/get-location';
import { useOwner } from '../../api/owner/get-owner';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { InventoryForm } from '../../components/inventory/form/inventory-form';
import { NavigationButton } from '../../components/navigation/navigation-button';
import { Spinner } from '../../components/spinner';
import { formatDateForInput } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';

export const InventoryEditPage = () => {

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<Inventory>();

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useRouterLocation();

  const equipments = useEquipments({ page: '1', perPage: '100' });
  const owners = useOwner({ page: '1', perPage: '100' });
  const locations = useLocation({ page: '1', perPage: '100' });
  const customers = useCustomersList({ page: '1', perPage: '100' });

  const { data: inventory, isSuccess } = useMasterInventoryItem({ id });

  const inventoryEdit = useInventoryEdit();

  const onSubmit = async (values: Inventory) => {
    try {
      await inventoryEdit.mutateAsync({ inventory: values, id });
      await navigate(`/inventory/${id}`);
    } catch {
      // allow axios interceptor to handle notification of error
      return;
    }

  };

  useEffect(() => {
    reset({ ...inventory, purchaseDate: inventory ? formatDateForInput(inventory.purchaseDate) : '' });
  }, [inventory]);

  if (!isSuccess || !equipments.isSuccess || !owners.isSuccess || !locations.isSuccess || !customers.isSuccess) {
    return <Spinner />;
  }

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <button onClick={() => location.key === 'default' ? navigate('/inventory') : navigate(-1)}>
            <NavigationButton title="Inventory" url="#">
              <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
            </NavigationButton>
          </button>

          <TitleActions title="Edit inventory product" />

          <InventoryForm
            isCreateForm={false}
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            setValue={setValue}
            equipments={equipments}
            owners={owners}
            locations={locations}
            isSubmitting={isSubmitting}
            customers={customers}
            initialData={{
              equipmentId: inventory.productId,
              ownerId: inventory.ownerId,
              locationId: inventory.locationId,
              atId: inventory.atId || null,
            }}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

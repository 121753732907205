import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMER_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { Customer } from './index';

export const getCustomer = (id: string | unknown): Promise<Customer> => {
  return axios.get(`/customer/${id}`);
};

export const useCustomer = (id: string | unknown) => {
  return useQuery<Customer, AxiosError<AxiosErrorResponse>>({
    queryKey: [CUSTOMER_KEY, id],
    queryFn: () => getCustomer(id),
    keepPreviousData: true,
  });
};

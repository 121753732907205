import { XMarkIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

import { useNotificationStore } from "../../stores/notifications";
import { NavigationButton } from "../navigation/navigation-button";

import { BarcodeScanner } from "./barcode-scanner";

export const ScannerModal = ({ onResult, onClose }: any) => {
  const { addNotification } = useNotificationStore();
  const [codes, setCode] = useState<string[]>([]);

  const onScan = (res: any) => {
    const exist = codes.find((c) => c === res.text);

    if (exist) return;

    setCode([...codes, res.text]);
    onResult(res.text);

    /** show scanned code without duplicates **/
    addNotification({
      type: "info",
      title: "Scanned",
      message: `Code ${res.text}`
    });
  };

  return (
    <div className="fixed justify-start items-start top-0 left-0 w-screen h-screen p-4 bg-white z-10">
      <button onClick={onClose}>
        <NavigationButton title="Close" url="#">
          <XMarkIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
        </NavigationButton>
      </button>

      <BarcodeScanner onResult={onScan} />

      {/*TODO: Paul: Connect real scanner and switch between camera and scanner*/}
      {/*
        {hasScannerConnected ? <RealScanner /> : <BarcodeScanner onResult={onResult} onError={onError} />}
      */}
    </div>
  );
};

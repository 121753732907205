import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMER_INVOICE_PREVIEW_KEY, QUERY_PAGE, QUERY_PER_PAGE } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { InvoicePreview } from './index';

export type GetCustomerInvoicePreviewProps = {
  customerId: string;
  searchParams: URLSearchParams;
};

export const getCustomerInvoicePreview = ({ customerId, searchParams }: GetCustomerInvoicePreviewProps): Promise<ResourceResponse<InvoicePreview>> => {
  const params = {
    page: searchParams.get(QUERY_PAGE) || 1,
    perPage: searchParams.get(QUERY_PER_PAGE) || 100,
    month: searchParams.get('month') || null,
    year: searchParams.get('year') || null,
  };

  return axios.get(`/invoicing/preview/${customerId}`, { params });
};

export const useCustomerInvoicePreview = (
  customerId: string,
  args: URLSearchParams,
) => {
  args.sort();
  return useQuery<ResourceResponse<InvoicePreview>, AxiosError<AxiosErrorResponse>>({
    queryKey: [CUSTOMER_INVOICE_PREVIEW_KEY, customerId, args.toString()],
    queryFn: () => getCustomerInvoicePreview({ customerId, searchParams: args }),
    keepPreviousData: false,
  });
};

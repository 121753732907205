import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

type DateRangePickerProps = {
  onChange: (startDate: Date, endDate: Date) => void;
  startDateKey: string,
  endDateKey: string,
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ onChange, startDateKey, endDateKey }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialStartDate = searchParams.get(startDateKey) ? new Date(searchParams.get(startDateKey) as string) : null;
  const initialEndDate = searchParams.get(endDateKey) ? new Date(searchParams.get(endDateKey) as string) : null;

  const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      onChange(start, end);
    }
  };

  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    searchParams.delete(startDateKey);
    searchParams.delete(endDateKey);
    setSearchParams(searchParams);
  }

  return (
    <div className='flex flex-col items-center'>
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        isClearable
      />
      <button className={`w-1/2 mt-2 font-bold bg-blue-800 text-white place-self-center rounded-full`} onClick={clearDates}>Clear</button>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PAGE, QUERY_SEARCH } from '../../../lib/constants';

export const Searchbar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      searchParams.delete(QUERY_PAGE);

      if (!search) {
        searchParams.delete(QUERY_SEARCH);

        return setSearchParams(searchParams);
      }

      searchParams.set(QUERY_SEARCH, search);

      setSearchParams(searchParams);
    }, 300);

    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    const param = searchParams.get(QUERY_SEARCH);
    if (param) setSearch(param);
  }, []);

  return (
    <div className="flex flex-row justify-start items-center w-[100%] border-b border-[#ebebebff] border-solid box-border bg-[rgba(255,255,255,1)]">
      <div className="flex flex-row justify-start items-start w-[100%] py-1 box-border">
        <img src="/media/search-icon.svg" alt="Not Found" className="w-6 " />
        <input
          className="border-[#84919aff] w-full text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px] h-6 focus:outline-none"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { INVENTORY_STATUS_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { InventoryStatusProps } from '../types';

import { Status } from './index';

export const getInventoryStatus = (params: InventoryStatusProps): Promise<Status[]> => {
  return axios.get(`/inventory/${params.inventoryId}/getStatuses`);
};

export const useInventoryStatus = (params: InventoryStatusProps) => {
  return useQuery<Status[], AxiosError<AxiosErrorResponse>>({
    queryKey: [INVENTORY_STATUS_KEY],
    queryFn: () => getInventoryStatus(params),
  });
};

import { NavLink } from 'react-router-dom';

const style = 'block px-10 py-1  text-sm font-medium rounded-md';

export const MenuNestedItem = ({ child }: any) => {
  const handleOnClick = (e: React.MouseEvent) => {
    if (child.disabled) {
      e.preventDefault();
    }
  };

  const handleOnHover = (e: React.MouseEvent) => {
    if (child.disabled) {
      (e.currentTarget as HTMLElement).title = "You must be an Admin to view this page";
    }
  };

  return (
    <NavLink
      onClick={handleOnClick}
      onMouseOver={handleOnHover}
      key={child.name}
      to={child.href}
      className={({ isActive }) => {
        const activeClasses = isActive ? 'bg-[#D7EDFF] text-[#0E73F6]' : '';
        const textColor = child.disabled ? 'text-gray-400' : 'text-gray-default';
        return `${activeClasses} ${textColor} ${style}`;
      }}
    >
      {child.name}
    </NavLink>
  );
};

import { CameraIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

import { Order } from '../../api/order';
import { useOrderActiveRental } from '../../api/order/active-rental-order';
import { useOrderApprove } from '../../api/order/approve-order';
import { useOrderCancel } from '../../api/order/cancel-order';
import { useOrderClose } from '../../api/order/close-order';
import { useOrderPending } from '../../api/order/pending-order';
import { useOrderPendingReview } from '../../api/order/pending-review-order';
import { useOrderUpdate } from '../../api/order/update-order';
import { formatDateForInput } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';
import { ButtonCancel } from '../common/button/button-cancel';
import { ButtonFilled } from '../common/button/button-filled';
import { Input } from '../form/input';
import { Label } from '../form/label';
import { LabelInput } from '../form/labelInput';
import { Line } from '../form/line';

import { AdminPickupOrderReviewForm } from './form/admin-pickup-order-review-form';
import OrderShippingDeliveryForm from './order-shipping-delivery-form';
import OrderShippingPickupForm from './order-shipping-pickup-form';

type Code = {
  id: string;
  text: string;
};

export const OrderStatusButtons = ({
  order,
  setCamera,
  assignInventoryItemToOrder,
}: {
  order: Order;
  setCamera: React.Dispatch<React.SetStateAction<boolean>>;
  assignInventoryItemToOrder: (code: string, customerId: number) => Promise<string | undefined>;
}) => {
  const [codes, setCode] = useState<Code[]>([]);
  const [driverNotes, setDriverNotes] = useState('');
  const [activeRentalDate, setActiveRentalDate] = useState(new Date());

  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const handleAddition = async (code: Code) => {
    if (await assignInventoryItemToOrder(code.id, order.customerId)) {
      const state = [...codes, code];
      setCode(state);
    }
  };

  const handleDelete = (i: number) => {
    const state = codes.filter((_code: Code, index: number) => index !== i);
    setCode(state);
  };

  const handleNotesChange = (e: any) => {
    setDriverNotes(e.target.value);
  };

  const pendingOrder = useOrderPending();

  const pendingReviewOrder = useOrderPendingReview();

  const cancelOrder = useOrderCancel();

  const approveOrder = useOrderApprove();

  const makeActiveRental = useOrderActiveRental();

  const closeOrder = useOrderClose();

  const updateOrder = useOrderUpdate();

  const activeRentalSubmit = async () => {
    try {
      await makeActiveRental.mutateAsync({ id: order.id, billingStartDate: activeRentalDate });
    } catch {
      // notification handled by axios interceptor
      return;
    }
  };

  const pendingSubmit = async () => {
    if (order.orderType === 'DELIVERY') await pendingOrder.mutateAsync(order.id);
    if (order.orderType === 'PICKUP') await pendingReviewOrder.mutateAsync(order.id);
    await updateOrder.mutateAsync({
      ...order,
      status: undefined,
      id: order.id,
      driverNotes,
      pickupDate: order.orderType === 'PICKUP' && !order.pickupDate ? new Date().toISOString() : order.pickupDate,
    });
  };

  const cancelSubmit = async () => {
    await cancelOrder.mutateAsync(order.id);
  };

  const approveSubmit = async () => {
    await approveOrder.mutateAsync(order.id);
  };

  const closeSubmit = async (values: any) => {
    await closeOrder.mutateAsync({ ...values, id: order.id });
  };

  if (order.status === 'PENDING' && !order.trackingNumber && order.deliveryMode === 'SHIPMENT_DELIVERY') {
    return <OrderShippingDeliveryForm orderId={order.id} />;
  }

  if (order.status === 'PENDING' && order.pickupMode === 'SHIPMENT_PICKUP') {
    return <OrderShippingPickupForm orderId={order.id} />;
  }

  switch (order.status) {
    case 'PENDING_APPROVAL':
      return (
        <>
          {isAdmin ? (
            <div className="mt-4 flex items-center justify-center gap-3 flex-wrap">
              <ButtonFilled text="Submit" onClick={approveSubmit} />
              <ButtonCancel text="Cancel" onClick={cancelSubmit} />
            </div>
          ) : (
            <>
              <span>Pending Review from Admin</span>
            </>
          )}
        </>
      );

    case 'PENDING_REVIEW':
      return (
        <>
          {isAdmin ? (
            <div className="mt-4 flex flex-col items-center justify-center gap-3 flex-wrap">
              <Line />
              <h2 className="text-lg">Admin Review</h2>
              <AdminPickupOrderReviewForm onSubmit={closeSubmit} onCancel={cancelSubmit} order={order} />
            </div>
          ) : (
            <>
              <span>Pending Review from Admin</span>
            </>
          )}
        </>
      );

    case 'CLOSED':
      return <span>Closed</span>;

    case 'READY_TO_SHIP':
      return (
        <>
          {isAdmin ? (
            <>
              <LabelInput
                title={'Billing Start Date'}
                type="date"
                attributes={{
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setActiveRentalDate(new Date(e.currentTarget.value));
                  },
                  value: formatDateForInput(activeRentalDate),
                }}
              />
              <ButtonFilled text="Make Active Rental" onClick={activeRentalSubmit} />
            </>
          ) : (
            <>
              <span>Pending Review from Admin</span>
            </>
          )}
        </>
      );

    case 'ACTIVE_RENTAL':
      return <span className="font-bold">In Active Rental Status</span>;

    default:
      return (
        <>
          <div className="flex flex-col">
            <Label className="text-left font-bold" title="Scan Items" />
            <Input attributes={{}} className="hidden" />
            <div className="flex">
              <ReactTags
                name="snArray"
                tags={codes}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                allowDragDrop={false}
                placeholder="Enter Serial Number or Asset ID"
                inputFieldPosition="top"
                autocomplete
                classNames={{
                  tags: 'w-1/4',
                  tag: 'flex p-1 border border-gray text-sm rounded-sm my-1 justify-between',
                  remove: 'pr-2 text-red-700',
                  tagInputField: 'rounded-none rounded-l-lg border border-gray-300 p-1 focus:ring-0 w-full',
                }}
              />
              <span className="inline-flex items-center h-[34px] px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
                <CameraIcon className="h-6 w-6" onClick={() => setCamera((val) => !val)} />
              </span>
            </div>
            {order.orderType === 'PICKUP' ? (
              <>
                <div className="flex flex-col justify-start w-full my-4">
                  <Label className="text-left" title="Driver Notes" />
                  <textarea
                    className="px-2 py-1 w-1/4 border border-gray-300 rounded-lg"
                    value={driverNotes}
                    placeholder="Missing items, etc."
                    onChange={handleNotesChange}
                  />
                </div>
              </>
            ) : null}
          </div>
          <ButtonFilled text="Submit to Management" onClick={pendingSubmit} />
        </>
      );
  }
};

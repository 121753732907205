import { Authenticator, Image, useTheme, View } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';

import awsConfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { Banner } from './components/banner/banner';
import { DashboardSidebar } from './components/sidebars/menu-sidebar/dashboard-sidebar';
import { MobileBar } from './components/sidebars/mobile-bar/mobile-bar';
import { AppProvider } from './provider/app';
import { AppRoutes } from './routes';

Amplify.configure(awsConfig);

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.xxxl}>
        <Image alt="Amplify logo" src="/media/logo.svg" height="80px" />
      </View>
    );
  },
};

function App() {
  return (
    <Authenticator components={components} hideSignUp={true}>
      {({ signOut }) => (
        <AppProvider>
          <div className="flex flex-col">
            <Banner />
            <div className='flex flex-row'>
              <DashboardSidebar signOut={signOut} />
              <AppRoutes />
            </div>
          </div>
          <MobileBar />
        </AppProvider>
      )}
    </Authenticator>
  );
}

export default App;

type TablePopoverSearchProps = {
  value: string | undefined;
  onSearch: (value: string) => void;
};

export default function TablePopoverSearch({ value, onSearch }: TablePopoverSearchProps) {
  return (
    <div className="flex justify-center items-center min-w-[150px] box-border">
      <img src="/media/search-icon.svg" alt="Not Found" className="h-4 mr-2" />
      <input
        className="border-[#84919aff] w-full text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px] h-6 focus:outline-none"
        placeholder="Search..."
        value={value}
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );
}

import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-duplicates
// eslint-disable-next-line import/no-duplicates
import SignaturePad from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';

import { useOrderActiveRental } from '../../api/order/active-rental-order';
import { useOrder } from '../../api/order/get-order';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { Label } from '../../components/form/label';
import { LabelInput } from '../../components/form/labelInput';
import { SubmitButton } from '../../components/form/submit-button';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

export const CollectOrderSignature = () => {
  const { roles } = useRoles();
  const isUserOrAdmin =
    roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  const { orderId } = useParams();
  const navigate = useNavigate();

  const { isSuccess, data: order } = useOrder(orderId);
  const makeActiveRental = useOrderActiveRental();

  type SignatureForm = {
    name: string;
    email: string;
    phone: string;
  };

  const padRef = React.useRef<SignatureCanvas>(null);

  const clear = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    padRef.current?.clear();
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignatureForm>();

  const submit = async (value: SignatureForm) => {
    const isEmptyCanvas = padRef.current?.isEmpty();

    if (isEmptyCanvas) return;

    const signature = padRef.current?.getTrimmedCanvas().toDataURL('image/png');

    // TODO: No logic how to save and where to display.
    console.log({ ...value, signature });

    if (!orderId) return;

    await makeActiveRental.mutateAsync({ id: orderId, billingStartDate: new Date() });

    navigate(-1);
  };

  if (!isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <NavigationBackButton />
          <TitleActions title={order.customer.title} />
          <p className="text-lg font-medium md:text-left text-center w-full">PM-{orderId}</p>

          <div className="mt-8 w-full flex justify-center">
            <form className="w-full max-w-[450px] flex flex-col gap-y-5" onSubmit={handleSubmit(submit)}>
              <LabelInput title={'Name'} attributes={{ ...register('name', { required: true }) }} />
              <LabelInput title={'Email'} attributes={{ ...register('email', { required: true }) }} />
              <LabelInput title={'Phone Number'} attributes={{ ...register('phone', { required: true }) }} />

              <div className="flex flex-col space-y-1 border-b border-gray-300 rounded-md">
                <div className="w-full flex items-center justify-between">
                  <Label title="Signature" />
                  <button className="border-none bg-transparent outline-none" onClick={clear}>
                    <img src="/media/close-icon.svg" alt="close" />
                  </button>
                </div>
                <SignaturePad ref={padRef} canvasProps={{ className: 'w-full h-[200px]' }} />
              </div>

              <SubmitButton isSubmitting={isSubmitting} submitText="Submit Order" />
            </form>
          </div>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import React from 'react';

import {
  QUERY_AT_IDS,
  QUERY_CUSTOMER_IDS,
  QUERY_DELIVERY_MODE,
  QUERY_ORDER_DATE,
  QUERY_ORDER_NEEDED_DATE,
  QUERY_ORDER_STATUS,
  QUERY_ORDER_TYPE,
  QUERY_OWNER_IDS,
  QUERY_PRODUCT_IDS,
  QUERY_STATUS_IDS,
  QUERY_TYPE_IDS,
  QUERY_USER_IDS,
} from '../../lib/constants';

export type TableColumnProp = {
  title: string;
  sortable?: boolean;
  key?: string;
  render?: (item: any) => React.ReactNode;
  isDateFilter?: boolean;
};

export const dashboardTableColumns: TableColumnProp[] = [
  {
    title: 'Equipment Type',
    sortable: true,
    key: QUERY_PRODUCT_IDS,
  },
  {
    title: 'Status',
    sortable: true,
    key: QUERY_STATUS_IDS,
  },
  {
    title: 'Type',
    sortable: true,
    key: QUERY_TYPE_IDS,
  },
  {
    title: 'Owner',
    sortable: true,
    key: QUERY_OWNER_IDS,
  },
  {
    title: 'Asset',
  },
  {
    title: 'SN',
  },
  {
    title: 'Location',
  },
  {
    title: 'At',
    sortable: true,
    key: QUERY_AT_IDS,
  },
  {
    title: 'Software Version',
  },
  {
    title: 'Equipment Overview',
  },
  {
    title: 'Actions',
  },
];

export const productTableColumns: TableColumnProp[] = [
  {
    title: 'Manufacturer',
  },
  {
    title: 'Title',
  },
  {
    title: 'PM Type',
  },
  {
    title: 'Type',
  },
  {
    title: 'Category',
  },
  {
    title: 'Equipment Overview',
  },
  {
    title: 'Actions',
  },
];

export const customerTableColumns: TableColumnProp[] = [
  {
    title: 'Name',
  },
  {
    title: 'Billing Address',
  },
  {
    title: 'Shipping Address',
  },
  {
    title: 'Active Rental Count',
  },
  {
    title: 'Market',
  },
  {
    title: 'Sales Rep',
  },
  {
    title: 'Products',
  },
  {
    title: 'Actions',
  },
];

export const customerViewTableColumns: TableColumnProp[] = [
  {
    title: 'Equipment',
    sortable: true,
    key: QUERY_PRODUCT_IDS,
  },
  {
    title: 'Pricing',
  },
  {
    title: 'Monthly Cap',
  },
  {
    title: 'Start Date',
  },
  {
    title: 'End Date',
  },
  {
    title: 'Notes',
  },
  {
    title: 'Actions',
  },
];

export const activeRentalViewTableColumns: TableColumnProp[] = [
  {
    title: 'Equipment',
    sortable: true,
    key: QUERY_PRODUCT_IDS,
  },
  {
    title: 'Status',
    sortable: true,
    key: QUERY_STATUS_IDS,
  },
  {
    title: 'Type',
    sortable: true,
    key: QUERY_TYPE_IDS,
  },
  // TODO: Add customer
  {
    title: 'Asset',
  },
  {
    title: 'SN',
  },
  {
    title: 'Software Version',
  },
  {
    title: 'View',
  },
  {
    title: 'Actions',
  },
];

export const importHistoryTableColumns: TableColumnProp[] = [
  {
    title: 'File Name',
  },
  {
    title: 'Type',
  },
  {
    title: 'Count',
  },
  {
    title: 'Actions',
  },
];

export const ordersTableColumns: TableColumnProp[] = [
  {
    title: 'Order Number',
  },
  {
    title: 'Customer Name',
    sortable: true,
    key: QUERY_CUSTOMER_IDS,
  },
  {
    title: 'Person Assigned',
    sortable: true,
    key: QUERY_USER_IDS,
  },
  {
    title: 'Status',
    sortable: true,
    key: QUERY_ORDER_STATUS,
  },
  {
    title: 'Order Type',
    sortable: true,
    key: QUERY_ORDER_TYPE,
  },
  {
    title: 'Shipping Mode',
    sortable: true,
    key: QUERY_DELIVERY_MODE,
  },
  {
    title: 'Date of Order',
    isDateFilter: true,
    key: QUERY_ORDER_DATE,
  },
  {
    title: 'Date Needed',
    isDateFilter: true,
    key: QUERY_ORDER_NEEDED_DATE,
  },
  {
    title: 'View',
  },
  {
    title: 'Edit',
  },
];

export const usersTableColumns: TableColumnProp[] = [
  {
    title: 'Name',
  },
  {
    title: 'Email',
  },
  {
    title: 'Roles',
  },
];

export const invoiceGroupsColumns: TableColumnProp[] = [
  {
    title: 'Group Name',
  },
  {
    title: 'Actions',
  },
];

export const invoiceGroupItemsColumns: TableColumnProp[] = [
  {
    title: 'Equipment Type',
  },
  {
    title: 'Asset',
  },
  {
    title: 'SN',
  },
  {
    title: 'Actions',
  },
];

export const invoicePreviewTableColumns: TableColumnProp[] = [
  {
    title: 'Inv Date',
  },
  {
    title: 'Inv Ref #',
  },
  {
    title: 'Customer',
  },
  {
    title: 'Bill To',
  },
  {
    title: 'Bill To 2',
  },
  {
    title: 'Bill To 3',
  },
  {
    title: 'Bill To 4',
  },
  {
    title: 'Bill To 5',
  },
  {
    title: 'Ship To',
  },
  {
    title: 'Ship To 2',
  },
  {
    title: 'Ship To 3',
  },
  {
    title: 'Ship To 4',
  },
  {
    title: 'Ship To 5',
  },
  {
    title: 'Import PO',
  },
  {
    title: 'Terms Ref',
  },
  {
    title: 'Due Date',
  },
  {
    title: 'Account #',
  },
  {
    title: 'Equipment',
  },
  {
    title: 'Description',
  },
  {
    title: 'Period',
  },
  {
    title: 'Qty',
  },
  {
    title: 'Amount',
  },
  {
    title: 'Tax Included',
  },
  {
    title: 'Email Address(es)',
  },
  { title: 'Total Amount' },
];

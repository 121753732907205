import { useForm } from "react-hook-form"

import { Order } from "../../../api/order"
import { formatDateForInput } from "../../../lib/format-date"
import { LabelInput } from "../../form/labelInput"
import { LabelTextArea } from "../../form/labelTextArea"

export type AdminPickupOrderReviewFormProps = {
    onSubmit: any
    onCancel: () => void
    order: Order
}


export const AdminPickupOrderReviewForm = ({ onSubmit, onCancel, order }: AdminPickupOrderReviewFormProps) => {
    const { handleSubmit, register } = useForm<Order>(
        {
            defaultValues: {
                description: order.description,
                orderDate: formatDateForInput(order.orderDate),
            },
        }
    );

    const sub = (values: any) => {
        onSubmit(values)
    }

    return (
        <>
            <form onSubmit={handleSubmit(sub)} className="w-[100%] lg:w-[50%] pb-20 space-y-4">
                <LabelTextArea title="Notes" attributes={{ ...register('description') }} placeholder="e.g. Mismatches or missing accessories" />

                <LabelInput
                    title={'Billing Stop Date'}
                    type="date"
                    attributes={{
                        ...register('orderDate', {
                            required: true,
                            // valueAsDate: true,
                            value: formatDateForInput(new Date()),
                            // pattern: '\\d{4}-\\d{2}-\\d{2}',
                        }),
                    }}
                />

                <div className="flex flex-row gap-4 justify-center items-center">
                    <button
                        type="submit"
                        className={`my-4 min-w-[250px] max-w-[450px] font-bold bg-blue-800 text-white py-1 px-2 rounded-full`}
                    >
                        {"Close Order"}
                    </button>
                    <button
                        type="button"
                        className={`my-4 min-w-[250px] max-w-[450px] font-bold bg-red-600 text-white py-1 px-2 rounded-full`}
                        onClick={onCancel}
                    >
                        Cancel
                    </button>

                </div>
            </form>
        </>
    )
}

export type InputProps = {
  attributes: any;
  placeholder?: string;
  type?: string;
  className?: string;
  step?: number;
};

export const Input = ({ attributes, className, placeholder, type = 'text', step = 0.01, isTextarea = false }: InputProps & { isTextarea?: boolean }) => {
  const defaultClasses = 'border border-gray-300 rounded-md p-2 text-md';
  const combinedClasses = `${defaultClasses} ${className || ''}`;

  if (isTextarea) {
    return (
      <textarea
        {...attributes}
        className={combinedClasses}
        placeholder={placeholder}
      />
    );
  }

  return (
    <input
      type={type}
      step={step}
      {...attributes}
      className={combinedClasses}
      placeholder={placeholder}
    />
  );
};

import { useNavigate } from 'react-router-dom';

import { ButtonOutline } from '../../components/common/button/button-outline';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { orderTypes } from '../../components/order/form/order-form';
import { useRoles } from '../../provider/roles-provider';

export const ChooseOrderPage = () => {
  const navigate = useNavigate();

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <NavigationBackButton url={'/order'} />

          <TitleActions title={'Create Order'} />

          <div className="flex flex-col space-y-6">
            <p>Is your order a delivery or a pickup?</p>
            <div className="flex space-x-4">
              {orderTypes.map((i, key) => (
                <ButtonOutline text={i.title} onClick={() => navigate(`/order/create?type=${i.id}`)} key={key} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { useMutation } from "@tanstack/react-query";

import { axios } from "../../lib/axios";
import { ORDERS_KEY } from "../../lib/constants";
import { queryClient } from "../../lib/react-query";
import { useNotificationStore } from "../../stores/notifications";

type DeleteOrderProps = {
  orderId: number,
  itemId: number
}

export const deleteOrderItem = ({ orderId, itemId }: DeleteOrderProps): Promise<any> => {
  return axios.delete(`/order/assign/${orderId}/${itemId}`);
};

export const useOrderItemDelete = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: "success",
        title: "Ok",
        message: "Order deleted successfully"
      });
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });

    },
    mutationFn: deleteOrderItem
  });
};
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import {
  MASTER_INVENTORY_KEY,
  QUERY_AT_IDS,
  QUERY_LOCATION_IDS,
  QUERY_OWNER_IDS,
  QUERY_PAGE,
  QUERY_PER_PAGE,
  QUERY_PRODUCT_IDS,
  QUERY_SEARCH,
  QUERY_STATUS_IDS,
  QUERY_TYPE_IDS,
} from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { Inventory } from './index';

export const getMasterInventories = (queryParams: URLSearchParams): Promise<ResourceResponse<Inventory>> => {
  const params = {
    page: queryParams.get(QUERY_PAGE) || 1,
    perPage: queryParams.get(QUERY_PER_PAGE) || 10,
    search: queryParams.get(QUERY_SEARCH) || null,
    productIds: queryParams.getAll(QUERY_PRODUCT_IDS) || null,
    statusIds: queryParams.getAll(QUERY_STATUS_IDS) || null,
    typeIds: queryParams.getAll(QUERY_TYPE_IDS) || null,
    ownerIds: queryParams.getAll(QUERY_OWNER_IDS) || null,
    locationIds: queryParams.getAll(QUERY_LOCATION_IDS) || null,
    atIds: queryParams.getAll(QUERY_AT_IDS) || null,
  };

  return axios.get('/inventory/master', { params });
};

export const useMasterInventories = (args: URLSearchParams, enabled = true) => {
  args.sort();
  return useQuery<ResourceResponse<Inventory>, AxiosError<AxiosErrorResponse>>({
    queryKey: [MASTER_INVENTORY_KEY, args.toString()],
    queryFn: () => getMasterInventories(args),
    keepPreviousData: true,
    enabled,
  });
};

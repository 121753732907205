import React from 'react';

import { orderType, type Order, deliveryMode } from '../../api/order';
import { useAssignInvToOrder } from '../../api/order/assign-inv-to-order';
import { formatDate } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';
import { useNotificationStore } from '../../stores/notifications';
import { ScannerModal } from '../barcode-scanner/scanner-modal';
import { Line } from '../form/line';

import { InvoiceGroupModal } from './invoice-group-modal';
import { OrderInventoriesCards } from './order-inventories-cards';
import { OrderInventoriesTable } from './order-inventories-table';
import { OrderStatusButtons } from './order-status-buttons';
import { ShowInvoiceGroupModalButton } from './show-invoice-group-modal-button';

type ScannedCountsType = {
  [key: number]: number;
};

type Props = {
  order: Order;
};

const OrderDetails: React.FC<Props> = ({ order }) => {
  const [scannedCounts, setScannedCounts] = React.useState<ScannedCountsType>({});
  const [cameraAsset, setCameraForAsset] = React.useState(false);
  const [isInvoiceGroupModalOpen, setIsInvoiceGroupModalOpen] = React.useState(false);
  const [selectedOrderInventories, setSelectedOrderInventories] = React.useState<number[]>([]);
  const assignInvToOrder = useAssignInvToOrder();
  const { addNotification } = useNotificationStore();
  const { roles } = useRoles();

  const isAdmin = !!(roles && Array.isArray(roles.roles) && roles.roles.includes('Admin'));

  const assignInventoryItemToOrder = async (code: string) => {
    if (!code) return;
    try {
      await assignInvToOrder.mutateAsync({ code, orderId: order.id, customerId: order.customerId });
      addNotification({
        type: 'success',
        title: `Success`,
        message: `Assigned to Order PM-${order.id}`,
      });
      return code;
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: `Unable to assign inventory item ${code} to Order PM-${order.id}.`,
      });
    }
  };

  const updateScannedCounts = () => {
    const newScannedCounts: ScannedCountsType = {};

    order?.productBookings.forEach((pb) => {
      const count = order.orderInventories.reduce((acc, oi) => {
        return oi.inventory.productId === pb.productId ? acc + 1 : acc;
      }, 0);
      newScannedCounts[pb.productId] = count;
    });

    setScannedCounts(newScannedCounts);
  };

  React.useEffect(() => {
    updateScannedCounts();
  }, [order?.orderInventories]);

  return (
    <>
      {cameraAsset && (
        <ScannerModal onResult={assignInventoryItemToOrder} onClose={() => setCameraForAsset(!cameraAsset)} />
      )}

      <div className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md space-y-2 border border-1 border-gray-[rgba(224,224,224,0.40)] mt-4 w-full">
        <div className="flex">
          <span className="font-bold">Order Number:</span>
          <span className="ml-2">PM-{order.id}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Type:</span>
          <span className="ml-2">
            {order.orderType === orderType.DELIVERY ? order.deliveryMode.replaceAll('_', ' ') : order.orderType}
          </span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Status:</span>
          <span className="ml-2">{order.status.replace('_', ' ')}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Date:</span>
          <span className="ml-2">{formatDate(order.orderDate)}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Date Needed:</span>
          <span className="ml-2">{formatDate(order.orderNeededDate)}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Customer:</span>
          <span className="ml-2">{order.customer.title}</span>
        </div>

        {order.orderType === orderType.DELIVERY ? (
          <div className="flex">
            <span className="font-bold">
              {order.deliveryMode === deliveryMode.LOCAL_DELIVERY ? 'Deliver To' : 'Ship To'}:
            </span>
            <span className="ml-2">{order.shipTo}</span>
          </div>
        ) : null}

        {order.shippingCompany && (
          <div className="flex">
            <span className="font-bold">Shipping Company:</span>
            <span className="ml-2 uppercase">{order.shippingCompany}</span>
          </div>
        )}

        {order.trackingNumber && (
          <div className="flex">
            <span className="font-bold">Tracking Number:</span>
            <span className="ml-2 uppercase">{order.trackingNumber}</span>
          </div>
        )}
        <Line />

        <div className="flex">
          <span className="font-bold">Caller Name:</span>
          <span className="ml-2">{order.callerName}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Caller Phone Number:</span>
          <span className="ml-2">{order.callerPhoneNumber}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Caller E-mail:</span>
          <span className="ml-2">{order.callerEmail}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Person Assigned:</span>
          <span className="ml-2">{order.user ? order.user.name : 'None'}</span>
        </div>

        <Line />

        {order.isReceiverName ? (
          <div className="flex">
            <span className="font-bold">Receiver:</span>
            <span className="ml-2">Same as caller</span>
          </div>
        ) : (
          <>
            <div className="flex">
              <span className="font-bold">Receiver Name:</span>
              <span className="ml-2">{order.receiverName}</span>
            </div>
            <div className="flex">
              <span className="font-bold">Receiver Phone Number:</span>
              <span className="ml-2">{order.receiverPhoneNumber}</span>
            </div>
            <div className="flex">
              <span className="font-bold">Receiver Email:</span>
              <span className="ml-2">{order.receiverEmail}</span>
            </div>
          </>
        )}

        <Line />

        <div className="flex">
          <span className="font-bold">Customer Notes:</span>
          <span className="ml-2">{order.customer.note ? order.customer.note : 'None'}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Notes:</span>
          <span className="ml-2">{order.description ? order.description : 'None'}</span>
        </div>
      </div>

      {order.productBookings.length > 0 ? (
        <div className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md space-y-2 border border-1 border-gray-[rgba(224,224,224,0.40)] mt-2 mb-2 w-full">
          <span className="font-bold">Booked Products</span>
          <div className="flex items-center space-x-2 mt-2">
            {order.productBookings.map((pb, key) => (
              <React.Fragment key={key}>
                <div
                  key={pb.productId}
                  className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md border border-1 border-gray-[rgba(224,224,224,0.40)] mb-2"
                >
                  <div>
                    <span className="font-bold">Product:</span>
                    <span className="ml-2">{pb.product?.title}</span>
                  </div>
                  <div>
                    <span className="font-bold">Total booked:</span>
                    <span className="ml-2">{pb.qty}</span>
                  </div>
                  <div>
                    <span className="font-bold">Total scanned:</span>
                    <span className="ml-2">{scannedCounts[pb.productId] || 0}</span>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex justify-between w-full my-2">
          <span className="font-bold">Items</span>
          {order.status === 'READY_TO_SHIP' ? (
            <ShowInvoiceGroupModalButton
              onClick={() => setIsInvoiceGroupModalOpen(true)}
              disabled={!isAdmin || selectedOrderInventories.length === 0}
            />
          ) : null}
        </div>
      )}

      {order.orderInventories ? (
        order.status === 'READY_TO_SHIP' ? (
          <OrderInventoriesTable
            order={order}
            checkedRowIds={selectedOrderInventories}
            setCheckedRowIds={setSelectedOrderInventories}
          />
        ) : (
          <OrderInventoriesCards order={order} />
        )
      ) : null}

      <div className="w-full text-center">
        <OrderStatusButtons
          order={order}
          setCamera={setCameraForAsset}
          assignInventoryItemToOrder={assignInventoryItemToOrder}
        />
      </div>

      <InvoiceGroupModal
        isOpen={isInvoiceGroupModalOpen}
        setIsOpen={setIsInvoiceGroupModalOpen}
        customerId={order.customer.id}
        inventoryIds={selectedOrderInventories}
      />
    </>
  );
};

export default OrderDetails;

import { ReactNode } from 'react';

export type TableDataRowProps = {
  children: ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<'tr'>;

export const TableDataRow = ({ children, className, ...props }: TableDataRowProps) => {
  return (
    <tr tabIndex={0} className={`focus:outline-none rounded border-b border-1 ${className ? className : ''}`} {...props}>
      {children}
    </tr>
  );
};

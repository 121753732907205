import { Auth } from 'aws-amplify';
import Axios, { InternalAxiosRequestConfig } from 'axios';

import { useNotificationStore } from '../stores/notifications';

import { USER_ROLES_KEY } from './constants';
import { queryClient } from './react-query';

async function authRequestInterceptor(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
  const jwt = await Auth.currentSession();
  const token = jwt.getAccessToken().getJwtToken();

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
    config.headers['X-User-Id'] = jwt.getIdToken().payload['sub'];

    queryClient.invalidateQueries({ queryKey: [USER_ROLES_KEY] });
  }

  config.headers.Accept = 'application/json';

  return config;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;

    if (Array.isArray(message)) {
      message.forEach((msg) => {
        useNotificationStore.getState().addNotification({
          type: 'error',
          title: 'Error',
          message: msg,
        });
      });
    } else {
      useNotificationStore.getState().addNotification({
        type: 'error',
        title: 'Error',
        message,
      });
    }

    return Promise.reject(error);
  }
);

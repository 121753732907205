import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { TYPE_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps, ResourceResponse } from '../types';

import { Type } from './index';

export const getType = (params: GetItemProps): Promise<ResourceResponse<Type>> => {
  return axios.get('/type', { params: { ...params, perPage: '10' } });
};

export const useType = (params: GetItemProps) => {
  return useQuery<ResourceResponse<Type>, AxiosError<AxiosErrorResponse>>({
    queryKey: [TYPE_KEY, params.page || 1],
    queryFn: () => getType(params),
    keepPreviousData: true,
  });
};

import { ChangeEventHandler} from 'react';

type OptionItem = { id: string; title: string };

export type SelectProps = {
  attributes: any;
  className?: string;
  option?: string;
  skipOption?: boolean;
  data: OptionItem[];
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
};

export const Select = ({ attributes, data, className, option, skipOption = false, defaultValue, onChange}: SelectProps) => {
  return (
    <select
      {...attributes}
      defaultValue={defaultValue}
      className={className ? className : 'border border-gray-300 rounded-md p-2 text-md min-w-[100px]'}
      onChange={onChange}
    >
      {!skipOption ? <option value="">{option ? option : 'Select option:'}</option> : null}
      {data && data.length
        ? data.map((i: OptionItem) => (
            <option value={i.id} key={i.title + i.id}>
              {i.title}
            </option>
          ))
        : null}
    </select>
  );
};

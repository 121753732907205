import { useMutation } from "@tanstack/react-query";

import { axios } from "../../lib/axios";
import { ORDERS_KEY } from "../../lib/constants";
import { queryClient } from "../../lib/react-query";
import { useNotificationStore } from "../../stores/notifications";

export const pendingOrder = (orderId: number): Promise<any> => {
  return axios.post(`/order/pending/${orderId}`);
};

export const useOrderPending = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    mutationFn: pendingOrder,
    onSuccess: async () => {
      addNotification({
        type: "success",
        title: "Ok",
        message: "Order pending successfully"
      });
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });
    }
  });
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import {
  CUSTOMER_INVOICE_PREVIEW_KEY,
  MASTER_INVENTORY_BULK_UPDATE_INVOICE_GROUP_KEY,
  MASTER_INVENTORY_KEY,
} from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { InventoryBilling } from './index';

export type BulkUpdateInventoryInvoiceGroupProps = {
  inventories: number[];
  customerId: number;
  groupId: number;
};

export const bulkUpdateInventoryInvoiceGroup = ({ inventories, customerId, groupId }: BulkUpdateInventoryInvoiceGroupProps): Promise<InventoryBilling[]> => {
  return axios.put(`/invoicing/${customerId}/group/${groupId}`, { inventories });
};

export const useBulkUpdateInventoryInvoiceGroup = (clearBulkSelect: () => void) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onError: () => {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to reassign inventories invoice group.',
      });
    },
    onSuccess: async (data: any) => {
      await Promise.all([queryClient.invalidateQueries([MASTER_INVENTORY_KEY]), queryClient.invalidateQueries([CUSTOMER_INVOICE_PREVIEW_KEY])]);
      clearBulkSelect();
      addNotification({
        type: 'success',
        title: 'Success',
        message: `Successfully bulk updated invoice group of ${data.length} inventory items.`,
      });
    },
    mutationFn: bulkUpdateInventoryInvoiceGroup,
    mutationKey: [MASTER_INVENTORY_BULK_UPDATE_INVOICE_GROUP_KEY],
  });
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { AUTH_ME_KEY, USERS_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { User } from './index';

export const getUsers = (): Promise<ResourceResponse<User>> => {
  return axios.get('/user', { params: { page: '1', perPage: '100' } });
};

export const useUsers = () => {
  return useQuery<ResourceResponse<User>, AxiosError<AxiosErrorResponse>>({
    queryKey: [USERS_KEY],
    queryFn: () => getUsers(),
    keepPreviousData: true,
  });
};

export const getCurrentUser = (): Promise<User> => {
  return axios.get('/auth/me');
};


export const useCurrentUser = () => {
  return useQuery<User, AxiosError<AxiosErrorResponse>>({
    queryKey: [AUTH_ME_KEY],
    queryFn: () => getCurrentUser(),
  });
};

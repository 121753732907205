import { Popover } from '@headlessui/react';
import { useState } from 'react';

import { Equipment } from '../../../api/equipment';
import { useEquipments } from '../../../api/equipment/get-equipments';
import { Label } from '../../form/label';
import TablePopoverPanelFormInput from '../../table/popover/table-popover-panel-form-input';
import TablePopoverTransition from '../../table/popover/table-popover-transition';

type EquipmentTypesDropdownProps = {
  className?: string;
  onEquipmentSelect?: (equipmentId: number) => void;
};

export const EquipmentTypesDropdown = ({ onEquipmentSelect, className }: EquipmentTypesDropdownProps ) => {
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const { data: equipments } = useEquipments({ page: currentPage.toString(), perPage: '10', search: search });

  const handleExternalPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleExternalSearchChange = (newSearch: string) => {
    setSearch(newSearch);
  };

  const handleEquipmentSelect = (equipmentId: number) => {
    const selectedEquip = equipments?.items.find((equip) => equip.id === Number(equipmentId));
    if (selectedEquip) {
      setSelectedEquipment(selectedEquip);
      if (onEquipmentSelect) {
        onEquipmentSelect(selectedEquip.id);
      }
    }
  };

  return (
    <div className={`${className}`}>
      <Label className='text-sm font-semibold text-gray-900' title="Equipment Types" />
      <Popover className="relative w-full">
        <Popover.Button className="w-full">
          <div className='mt-1 box-border border cursor-pointer bg-white border-gray-300 rounded-md p-2 text-md min-w-[100px] text-sm leading-6 text-gray-900 w-full text-left focus-within:border-2 focus-within:border-blue-800'>
            {selectedEquipment?.title || 'Select one'}
          </div>
        </Popover.Button>
        <TablePopoverTransition>
          {equipments ? (
            <Popover.Panel className="absolute z-10 mt-2">
              <TablePopoverPanelFormInput
                itemKey="productIds"
                setParentValue={handleEquipmentSelect}
                parentValue={selectedEquipment}
                onExternalPageChange={handleExternalPageChange}
                onExternalSearchChange={handleExternalSearchChange}
              />
          </Popover.Panel>
          ) : null}
        </TablePopoverTransition>
      </Popover>
    </div>
  );};

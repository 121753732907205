import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { CUSTOMER_PRICING_KEY } from '../../lib/constants';
import { MutationConfig } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

type DeleteCustomerPricingProps = { customerId: number; id: number };

export const deleteCustomerPricing = ({ customerId, id }: DeleteCustomerPricingProps): Promise<void> => {
  return axios.delete(`/customer/${customerId}/pricing/${id}`);
};

type UseDeleteCustomerPricing = {
  config?: MutationConfig<typeof deleteCustomerPricing>;
};

export const useDeleteCustomerPricing = ({ config }: UseDeleteCustomerPricing = {}) => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: async (_, { customerId }) => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: `Customer Pricing was deleted successfully`,
      });
      await queryClient.invalidateQueries([CUSTOMER_PRICING_KEY, `${customerId}`]);
    },
    mutationFn: deleteCustomerPricing,
    ...config,
  });
};

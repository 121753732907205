const activeStyle = 'border-b border-black';

export type TabsItemProps = {
  title: string;
  active?: boolean;
  onClick?: () => void;
};

export const TabsItem = ({ title, active = false, onClick }: TabsItemProps) => {
  return (
    <button className={`relative pb-2 ${active ? activeStyle : ''}`} onClick={onClick}>
      {title}
    </button>
  );
};

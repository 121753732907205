export type ButtonOutlineProps = {
  text: string;
  onClick?: () => void;
};

export const ButtonOutline = ({ text, onClick }: ButtonOutlineProps) => {
  return (
    <button className="border border-gray-300 px-3 py-1 rounded-md" onClick={onClick}>
      {text}
    </button>
  );
};

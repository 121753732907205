import { ReactNode } from 'react';

export type TableContainerProps = {
  children: ReactNode;
  className?: string;
};

export const TableContainer = ({ children, className }: TableContainerProps) => {
  return (
    <div className={`w-full overflow-x-auto ${className}`}>
      <table className="w-full table-auto whitespace-nowrap">
        <tbody className="">{children}</tbody>
      </table>
    </div>
  );
};

import { Customer } from '../customer';
import { Equipment } from '../equipment';
import { InvoiceGroup } from '../invoicing';
import { Owner } from '../owner';
import { Status } from '../status';
import { Type } from '../type';
import { User } from '../user';

type ValueOf<T> = T[keyof T];

export const orderType = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP',
  SALE: 'SALE',
} as const;

type OrderType = ValueOf<typeof orderType>;

export const deliveryMode = {
  LOCAL_DELIVERY: 'LOCAL_DELIVERY',
  SHIPMENT_DELIVERY: 'SHIPMENT_DELIVERY',
};

export type Order = {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  orderType: OrderType | string;
  shippingCompany: string;
  deliveryMode: string;
  pickupMode: string;
  shipTo: string;
  isReceiverName: boolean;
  callerName: string;
  callerPhoneNumber: string;
  callerEmail: string;
  receiverName: string;
  receiverPhoneNumber: string;
  receiverEmail: string;
  orderPO: string;
  description: string;
  driverNotes: string;
  userId: number | null;
  customerId: number;
  orderDate: Date | string;
  orderNeededDate: Date | string;
  orderInventories: OrderInventoryItem[];
  user: User;
  customer: Customer;
  productBookings: ProductBooking[];
  trackingNumber: string;
  pickupDate: Date | string;
};

export type OrderHistory = {
  id: number;
  field: string;
  oldValue: string;
  newValue: string;
  user: Pick<User, 'name'>;
  createdAt: Date;
  updatedAt: Date;
};

export type ProductBooking = {
  orderId: number;
  productId: number;
  qty: number;
  product: Equipment;
};

export type OrderInventoryItem = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  orderId: number;
  productId: number;
  inventoryId: number;
  scannedId: string;
  status: string;
  product: Product;
  inventory: Inventory;
  flaggedForAdmin: boolean;
  invoiceGroup: InvoiceGroup | null;
};

export type Product = {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  isDisposable: boolean;
  isPart: boolean;
  typeId: number;
  pm_type: string;
  manufacturer: string;
  deletedAt: Date | string | null;
  type: Type;
};

export type Inventory = {
  id: number;
  createdAt: Date | string | null;
  updatedAt: Date | string | null;
  assetId: string;
  sn: string;
  productId: number;
  statusId: number;
  ownerId: number;
  locationId: number;
  atId: any;
  typeId: number;
  software: string;
  warranty: string;
  poNumber: string;
  vendorPurchased: string;
  importId: any;
  shelf: any;
  bin: any;
  cost: any;
  lastPm: string;
  dueDate: string;
  purchaseDate: string;
  isDisposable: boolean;
  isPart: boolean;
  expiredAt: any;
  deletedAt: any;
  product: Product;
  status: Status;
  owner: Owner;
  location: Location;
  at: any;
};

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Location } from '../../../api/location';
import { useLocation } from '../../../api/location/get-location';
import { Tabs } from '../../common/tabs/tabs';
import { TabsItem } from '../../common/tabs/tabs-item';

const ALL = 'All';
const KEY = 'locIds';

export const InventoryViewTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [active, setActive] = useState<string>('');
  const locations = useLocation({ page: '1', perPage: '50' });

  const setLocation = async (location: string) => {
    if (location === ALL) {
      searchParams.delete(KEY);
      await setSearchParams(searchParams);

      return setActive(ALL);
    }

    if (!location) {
      searchParams.delete(KEY);
      await setSearchParams(searchParams);

      return setActive(ALL);
    }

    searchParams.set(KEY, location);
    await setSearchParams(searchParams);

    return setActive(location);
  };

  useEffect(() => {
    const param = searchParams.get(KEY);
    if (param) return setActive(param);
    return setActive(ALL);
  }, []);

  return (
    <div className="w-full mb-4">
      <Tabs>
        <TabsItem title={'All'} active={active === ALL} onClick={() => setLocation(ALL)} />

        {locations.isSuccess
          ? locations.data.items?.map((location: Location) => (
              <TabsItem
                title={location.title}
                active={active === String(location.id)}
                onClick={() => setLocation(String(location.id))}
                key={location.id}
              />
            ))
          : null}
      </Tabs>
    </div>
  );
};

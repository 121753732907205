import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { externalAxios } from '../../lib/axios-external';
import { INVOICE_ZIP_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

async function downloadFile(url: string, filename: string) {
  try {
    const response = await externalAxios.get(url, { responseType: 'blob' });
    const file = new Blob([response.data], { type: 'application/octet-stream' });
    const downloadUrl = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export const getInvoiceZip = async (month: number | undefined, year: number | undefined): Promise<null> => {
  try {
    if (typeof month === undefined || typeof year === undefined) throw new Error('Undefined month or year');
    // Fetch the S3 URL for the zip
    const s3Response = await axios.get(`/invoicing/zip?month=${month}&year=${year}`);
    const { url, filename } = s3Response as unknown as { filename: string; url: string };
    await downloadFile(url, filename);
  } catch (err) {
    console.error('Error downloading the file', err);
  }
  return null;
};

export const useInvoiceZip = (
  month: number | undefined,
  year: number | undefined,
  config: UseQueryOptions<any, AxiosError<AxiosErrorResponse>> = {}
) => {
  return useQuery<Blob | null, AxiosError<AxiosErrorResponse>>({
    ...config,
    queryKey: [INVOICE_ZIP_KEY, month, year],
    queryFn: () => getInvoiceZip(month, year),
    enabled: false,
    keepPreviousData: false,
  });
};

import { CameraIcon } from '@heroicons/react/24/outline';
import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

import { Customer } from '../../../api/customer';
import { useOneCustomer } from '../../../api/customer/get-one-customer';
import { Equipment } from '../../../api/equipment';
import { useOneEquipment } from '../../../api/equipment/get-one-equipment';
import { Location } from '../../../api/location';
import { useOneLocation } from '../../../api/location/get-one-location';
import { Owner } from '../../../api/owner';
import { useOneOwner } from '../../../api/owner/get-one-owner';
import { ResourceResponse } from '../../../api/types';
import { ScannerModal } from '../../barcode-scanner/scanner-modal';
import PopoverSelect from '../../common/popover-select/popover-select';
import { Input } from '../../form/input';
import { Label } from '../../form/label';
import { LabelInput } from '../../form/labelInput';
import { SubmitButton } from '../../form/submit-button';

type Code = {
  id: string;
  text: string;
};

type InventoryInitialProps = {
  equipmentId: number;
  ownerId: number;
  locationId: number;
  atId: number | null;
};

export type InventoryFormProps = {
  isCreateForm?: boolean;
  onSubmit: any;
  register: any;
  setValue: any;
  equipments: UseQueryResult<ResourceResponse<Equipment>>;
  owners: UseQueryResult<ResourceResponse<Owner>>;
  locations: UseQueryResult<ResourceResponse<Location>>;
  customers: UseQueryResult<ResourceResponse<Customer>>;
  isSubmitting: boolean;
  initialData?: InventoryInitialProps;
};

export const InventoryForm = ({
  isCreateForm = true,
  onSubmit,
  register,
  setValue,
  // equipments,
  isSubmitting,
  initialData,
}: InventoryFormProps) => {
  const [codes, setCode] = useState<Code[]>([]);

  const [cameraSN, setCameraForSN] = useState(false);
  const [cameraArraySn, setCameraForArraySn] = useState(false);
  const [cameraAsset, setCameraForAsset] = useState(false);

  //initial data
  const [equipmentId, setEquipmentId] = useState<any>(initialData?.equipmentId || null);
  const [ownerId, setOwnerId] = useState<any>(initialData?.ownerId || null);
  const [locationId, setLocationId] = useState<any>(initialData?.locationId || null);
  const [atId, setAtId] = useState<any>(initialData?.atId || null);

  const equipment = useOneEquipment(equipmentId);
  const owner = useOneOwner(ownerId);
  const location = useOneLocation(locationId);
  const customer = useOneCustomer(atId);

  const toggleCameraSn = () => {
    setCameraForSN(!cameraSN);
  };

  const toggleCameraArraySn = () => {
    setCameraForArraySn(!cameraArraySn);
  };

  const toggleCameraAsset = () => {
    setCameraForAsset(!cameraAsset);
  };

  const handleDelete = (i: number) => {
    const newTags = codes.filter((tag: any, index: number) => index !== i);
    setCode(newTags);
  };

  const handleAddition = (code: Code) => {
    const state = [...codes, code];
    setCode(state);
  };

  const onResult = async (code: string) => {
    const findCode = codes.find((c) => c.id === code);
    if (findCode) return;

    const state = [...codes, { id: code, text: code }];
    setCode(state);
  };

  const setAssetId = (assetId: string) => {
    setValue('assetId', assetId);
  };

  const setSn = (sn: string) => {
    setValue('sn', sn);
  };

  useEffect(() => {
    setValue('snArray', codes);
  }, [codes]);

  return (
    <form onSubmit={onSubmit} className="w-full">
      {cameraAsset && <ScannerModal onResult={setAssetId} onClose={toggleCameraAsset} />}
      {cameraSN && <ScannerModal onResult={setSn} onClose={toggleCameraSn} />}
      {cameraArraySn && <ScannerModal onResult={onResult} onClose={toggleCameraArraySn} />}

      <div className="flex w-full md:flex-row flex-col gap-4">
        <div className="flex flex-col space-y-2 w-full">
          {!isCreateForm ? (
            <div className="space-y-1">
              <Label title={'AssetID'} />
              <div className="flex">
                <Input
                  attributes={{
                    ...register('assetId'),
                  }}
                  className="border border-gray-300 p-2 text-md rounded-none rounded-l-lg w-full"
                />
                <span className="flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
                  <CameraIcon className="h-6 w-6" onClick={toggleCameraAsset} />
                </span>
              </div>
            </div>
          ) : null}

          <div className="space-y-1">
            <Label title={'Serial Number'} />
            {!isCreateForm ? (
              <div className="flex">
                <Input
                  attributes={{
                    ...register('sn', {
                      required: true,
                    }),
                  }}
                  className="border border-gray-300 p-2 text-md rounded-none rounded-l-lg w-full"
                />
                <span className="flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
                  <CameraIcon className="h-6 w-6" onClick={toggleCameraSn} />
                </span>
              </div>
            ) : null}
          </div>

          {isCreateForm ? (
            <div className="flex flex-col">
              <Input
                attributes={{
                  ...register('snArray', {
                    required: true,
                  }),
                }}
                className="hidden"
              />
              <div className="flex">
                <ReactTags
                  name="snArray"
                  tags={codes}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  allowDragDrop={false}
                  placeholder="Enter serial number"
                  inputFieldPosition="top"
                  autocomplete
                  classNames={{
                    tags: 'w-full',
                    tag: 'flex p-1 border border-gray text-sm rounded-sm my-1 justify-between',
                    selected: 'overflow-x-scroll max-h-[200px] mt-1',
                    remove: 'pr-2 text-red-700',
                    tagInputField: 'rounded-none rounded-l-lg border border-gray-300 p-1 focus:ring-0 w-full',
                  }}
                />
                <span className="inline-flex items-center h-[34px] px-3 text-sm text-gray-900 bg-gray-200 border border-l-0 border-gray-300 rounded-r-md">
                  <CameraIcon className="h-6 w-6" onClick={toggleCameraArraySn} />
                </span>
              </div>
              {!codes.length ? <p className="text-sm text-yellow-600">Add serial number</p> : null}
            </div>
          ) : null}

          <LabelInput
            title={'Shelf'}
            attributes={{
              ...register('shelf', {
                valueAsNumber: true,
                setValueAs: (v: string) => (v === '' ? null : parseInt(v)),
              }),
            }}
          />

          <LabelInput
            title={'Last PM'}
            attributes={{
              ...register('lastPm'),
            }}
          />

          <LabelInput
            title={'Due Date'}
            attributes={{
              ...register('dueDate'),
            }}
          />

          <LabelInput
            title={'Bin'}
            attributes={{
              ...register('bin', {
                valueAsNumber: true,
                setValueAs: (v: string) => (v === '' ? null : parseInt(v)),
              }),
            }}
          />

          <LabelInput
            title={'PurchaseDate'}
            type="date"
            attributes={{ ...register('purchaseDate', { valueAsDate: true }) }}
          />

          <LabelInput title={'Cost'} attributes={{ ...register('cost', { valueAsNumber: true }) }} />
        </div>

        <div className="flex flex-col space-y-2 w-full justify-between">
          <LabelInput
            title={'Software'}
            attributes={{
              ...register('softwareVersion'),
            }}
          />

          <LabelInput
            title={'Warranty'}
            attributes={{
              ...register('warranty'),
            }}
          />

          <LabelInput
            title={'PO Number'}
            attributes={{
              ...register('poNumber'),
            }}
          />

          <LabelInput
            title={'Vendor Purchased From'}
            attributes={{
              ...register('vendorPurchased'),
            }}
          />

          <PopoverSelect
            title="Equipment Type"
            setedValue={equipment?.data?.title}
            parentValue={equipmentId}
            parentValueSetter={setEquipmentId}
            itemKey="productIds"
            registerName="productId"
            setValue={setValue}
            register={register}
            isRequired
          />

          <PopoverSelect
            title="Owner"
            setedValue={owner?.data?.title}
            parentValue={ownerId}
            parentValueSetter={setOwnerId}
            itemKey="ownerIds"
            registerName="ownerId"
            setValue={setValue}
            register={register}
            isRequired
          />

          <PopoverSelect
            title="Location"
            setedValue={location?.data?.title}
            parentValue={locationId}
            parentValueSetter={setLocationId}
            itemKey="locIds"
            registerName="locationId"
            setValue={setValue}
            register={register}
            isRequired
          />

          {!isCreateForm && (
            <PopoverSelect
              title="At"
              setedValue={customer?.data?.title}
              parentValue={atId || null}
              parentValueSetter={setAtId}
              itemKey="atIds"
              registerName="atId"
              register={register}
              setValue={setValue}
              isRequired={false}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center w-full">
        <SubmitButton isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

import { Dialog } from '@headlessui/react';
import { Dispatch, SetStateAction, useState } from 'react';

import { useInvoiceGroupItems } from '../../api/invoicing/get-invoice-group-items';
import { useAssignOrderInventoriesToInvoiceGroup } from '../../api/order/assign-order-inventories-to-invoice-group';
import { ButtonCancel } from '../common/button/button-cancel';
import { ButtonFilled } from '../common/button/button-filled';
import { InvoiceGroupDropdown } from '../invoicing/dropdown/invoicing-groups-dropdown';
import { Table } from '../table/table';

type InvoiceGroupModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerId: number;
  inventoryIds: number[];
};

export const InvoiceGroupModal = ({ isOpen, setIsOpen, customerId, inventoryIds }: InvoiceGroupModalProps) => {
  const [selectedInvoiceGroupId, setSelectedInvoiceGroupId] = useState<number>();
  const { data: invoiceGroupItems, isSuccess } = useInvoiceGroupItems({
    args: {
      customerId,
      groupId: selectedInvoiceGroupId,
      page: 1,
      perPage: 5,
    },
    config: {
      enabled: selectedInvoiceGroupId !== undefined,
    },
  });
  const assignOrderInventoriesToInvoiceGroup = useAssignOrderInventoriesToInvoiceGroup();

  const mapInvoiceGroupItems = (data: any) => {
    return (
      data?.items.map((item: any) => ({
        id: item.inventory.id,
        equipmentType: item.inventory.product.title,
        asset: item.inventory.assetId,
        sn: item.inventory.sn,
      })) || []
    );
  };

  const onAssignButtonClicked = async () => {
    try {
      await assignOrderInventoriesToInvoiceGroup.mutateAsync({
        orderInventoryIds: inventoryIds,
        invoiceGroupId: selectedInvoiceGroupId,
      });
      onModalClose();
    } catch {
      // notification handled by axios interceptor
      return;
    }
  };

  const onModalClose = () => {
    setIsOpen(false);
    setSelectedInvoiceGroupId(undefined);
  };

  return (
    <Dialog open={isOpen} onClose={onModalClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="mx-auto rounded bg-white w-full md:w-[640px] p-4">
          <Dialog.Title className="text-2xl text-center font-bold">Assign an Invoice Group</Dialog.Title>
          <Dialog.Description className="pt-4 pb-2 text-l text-center">
            Select an invoice group for the checked order inventories.
          </Dialog.Description>
          <div className="flex justify-center">
            <div className="pb-4 w-[216px]">
              <InvoiceGroupDropdown
                customerId={customerId}
                onSelect={(id) => {
                  setSelectedInvoiceGroupId(id);
                }}
              />
            </div>
          </div>

          {selectedInvoiceGroupId !== undefined && isSuccess ? (
            <div className="py-4">
              <p className="text-l font-bold w-full text-center">Sample items in selected group</p>
              <Table
                actions={false}
                columns={[{ title: 'Equipment Type' }, { title: 'Asset' }, { title: 'SN' }]}
                data={{
                  items: mapInvoiceGroupItems(invoiceGroupItems),
                  page: 1,
                  perPage: 5,
                  count: 5,
                }}
                view={false}
              />
            </div>
          ) : null}
          <div className="flex justify-center gap-4">
            <ButtonCancel text="Cancel" onClick={onModalClose} />
            <ButtonFilled text="Assign Invoice Group" onClick={onAssignButtonClicked} />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

// const onAssignInvoiceGroupsButtonClicked = async () => {

// };

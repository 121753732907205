import { useExportInventory } from '../../../api/inventory/get-inventory-csv';
import { ActionButton } from '../../common/title-action/action-button';

interface Props {
  searchParams: URLSearchParams;
}

const ExportInventoryButton = ({ searchParams }: Props) => {
  const { refetch } = useExportInventory(searchParams);

  return (
    <ActionButton
      icon="/media/export-table-icon.svg"
      title="Export Table"
      className={'sm:flex'}
      onClick={async () => await refetch()}
    />
  );
};

export default ExportInventoryButton;

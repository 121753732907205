export type TableTitleProps = {
  title: string;
};

export const TableTitle = ({ title }: TableTitleProps) => {
  return (
    <div className="w-full text-base border-b mb-3">
      <p>{title}</p>
    </div>
  );
};

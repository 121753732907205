import { useEffect, useState } from 'react';

import { TableUsers } from '../../api/user';
import { getAllUsersAndRoles } from '../../api/user/get-roles';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { Spinner } from '../../components/spinner';
import { usersTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';

export const UsersPage = () => {
  const [usersData, setUsersData] = useState<TableUsers[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const mapItems = (users: any[]): TableUsers[] => {
    return users.map(user => {
      const attributes = Object.fromEntries(
        user.attributes.map((attr: { Name: string; Value: string }) => [attr.Name, attr.Value])
      );
      return {
        name: attributes.name,
        email: attributes.email,
        roles: user.groups.join(', '),
      };
    });
  };

  // Pagination for this data is not currently implemented due to the pagination options that are supported by the Cognito API
  // TODO: Consider extending the User Roles feature to include a roles column on the User entity in the database to align with the approach for the other resrouces
  const tableData = {
    count: usersData.length,
    items: usersData,
    page: 1,
    perPage: 10
  };

  useEffect(() => {
    setIsLoading(true);
    getAllUsersAndRoles().then(response => {
      const mappedUsers = mapItems(response);
      setUsersData(mappedUsers);
      setIsLoading(false);
    });
  }, []);
  
  return (
    <PageContainer>
          <TitleActions title="Users" />
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Table
                data={tableData}
                columns={usersTableColumns}
                view={false}
                actions={false}
                canUserEdit={false}
                checkbox={false}
              />
            </>
          )}
    </PageContainer>
  );
};

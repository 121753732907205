import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { useNotificationStore } from '../../stores/notifications';

import { Order } from '.';

export const createPickupOrder = (data: Order): Promise<Order> => {
  return axios.post('/order/pickup', { ...data });
};

export const useOrderPickupCreate = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Order created successfully',
      });
    },
    mutationFn: createPickupOrder,
  });
};

import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { InvoicePreview } from '../../../api/invoicing';
import { useInvoiceGroups } from '../../../api/invoicing/get-customer-invoice-groups';
import { useCustomerInvoicePreview } from '../../../api/invoicing/get-customer-invoice-preview';
import Dropdown from '../../../components/common/dropdown/dropdown';
import DropdownItem from '../../../components/common/dropdown/dropdown-item';
import { LinkButton } from '../../../components/common/title-action/link-button';
import { TitleActions } from '../../../components/common/title-action/title-actions';
import { GenerateInvoicePreviewButton } from '../../../components/invoicing/button/invoice-generate-button';
import { InventoryBulkUpdateInvoiceGroupDropdown } from '../../../components/invoicing/dropdown/inventory-bulk-update-invoice-group-dropdown';
import { invoicePreviewTableColumns } from '../../../components/table/data-cols';
import { Table } from '../../../components/table/table';
import { TablePagination } from '../../../components/table/table-pagination';
import { CUSTOMER_INVOICE_PREVIEW_KEY, QUERY_PAGE } from '../../../lib/constants';
import { useRoles } from '../../../provider/roles-provider';

const CustomerInvoicing = () => {
  const { roles } = useRoles();
  const isUserOrAdmin =
    roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));
  const [searchParams, setSearchParams] = useSearchParams();
  const { customerId } = useParams();

  const months = [
    'January',
    'Feburary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  const yearsSince2023: number[] = [];

  for (let year = 2023; year <= currentYear; year++) {
    yearsSince2023.push(year);
  }

  // Get the current month (1 = January, 12 = December)
  const currentMonthIndex = new Date().getMonth();
  const currentYearIndex = new Date().getFullYear() - 2023;
  // Initialize state with the current month
  const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);
  const [selectedYear, setSelectedYear] = useState(yearsSince2023[currentYearIndex]);
  const [invoicePreviewData, setInvoicePreviewData] = useState<InvoicePreview[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedRowIds, setCheckedRowIds] = useState<number[]>([]);

  const clearBulkSelect = () => {
    setCheckedRowIds([]);
    setIsCheckAll(false);
  };

  const customerInvoiceGroups = useInvoiceGroups({
    args: { customerId, searchParams },
    config: {
      select: (data) => {
        return {
          ...data,
        };
      },
    },
  });

  const { refetch, isRefetching, isFetching, isFetched, data } = useCustomerInvoicePreview(
    customerId ? customerId : '',
    searchParams
  );

  useEffect(() => {
    if (data && data.items) {
      setInvoicePreviewData(mapItems(data.items));
    }
  }, [data]);

  // Function to handle month change
  const handleMonthChange = (newIndex: number) => {
    // Ensure the new month is in the months array
    setSelectedMonth(newIndex);
  };
  // Function to handle year change
  const handleYearChange = (newYear: number) => {
    // Ensure the new month is in the months array
    if (yearsSince2023.includes(newYear)) {
      setSelectedYear(newYear);
    }
  };

  useEffect(() => {
    const fetchPreviewData = async () => {
      await refetch({ queryKey: [CUSTOMER_INVOICE_PREVIEW_KEY, customerId, searchParams.toString()] });
    };

    if (searchParams.get('month') && searchParams.get('year')) {
      fetchPreviewData();
    }
  }, [searchParams, refetch, customerId]);

  const handleFetchPreview = () => {
    const month = selectedMonth + 1;
    const year = selectedYear;
    const page = searchParams.get(QUERY_PAGE) || '1';

    const newSearchParams = new URLSearchParams();
    newSearchParams.set('month', String(month));
    newSearchParams.set('year', String(year));
    newSearchParams.set(QUERY_PAGE, page);

    setSearchParams(newSearchParams);
  };

  const mapItems = (items: InvoicePreview[]): InvoicePreview[] => {
    return items.map(
      (i): InvoicePreview => ({
        id: i.id,
        invDate: i.invDate,
        invRef: i.invRef,
        customer: i.customer,
        billTo: i.billTo,
        billTo2: i.billTo2,
        billTo3: i.billTo3,
        billTo4: i.billTo4,
        billTo5: i.billTo5,
        shipTo: i.shipTo,
        shipTo2: i.shipTo2,
        shipTo3: i.shipTo3,
        shipTo4: i.shipTo4,
        shipTo5: i.shipTo5,
        importPO: i.importPO,
        termsRef: i.termsRef,
        dueDate: i.dueDate,
        accountNumber: i.accountNumber,
        equipment: i.equipment,
        description: i.description,
        period: i.period,
        qty: i.qty,
        amount: i.amount,
        isTaxIncluded: i.isTaxIncluded,
        email: i.email,
        totalAmount: i.totalAmount,
      })
    );
  };

  return (
    <>
      {isUserOrAdmin ? (
        <>
          <TitleActions subtitle title="Invoicing">
            <div className="flex flex-row gap-6 items-start justify-center bg-white">
              <div className="flex gap-4 w-full">
                <Dropdown title="Pick month" defaultValue={months[selectedMonth]}>
                  {months.map((month, index) => (
                    <DropdownItem
                      buttonType="button"
                      title={month}
                      onClick={() => handleMonthChange(index)}
                      key={index}
                    />
                  ))}
                </Dropdown>
                <Dropdown title="Year" defaultValue={selectedYear}>
                  {yearsSince2023.map((year) => (
                    <DropdownItem buttonType="button" title={year} onClick={() => handleYearChange(year)} key={year} />
                  ))}
                </Dropdown>
              </div>
              <GenerateInvoicePreviewButton
                onClick={handleFetchPreview}
                className="flex justify-start items-center w-full h-full px-2 sm:px-3 py-1 border border-[#dde2e4ff] border-solid rounded-lg box-border bg-[rgba(255,255,255,1)] sm:flex"
                isGenerating={isRefetching || isFetching}
                title="Generate Invoice Preview"
              />
            </div>
            {customerInvoiceGroups.isSuccess ? (
              <InventoryBulkUpdateInvoiceGroupDropdown
                customerId={Number(customerId)}
                groups={customerInvoiceGroups.data.items}
                inventories={checkedRowIds}
                clearBulkSelect={clearBulkSelect}
              />
            ) : null}
            <LinkButton title="View Invoice Groups" link={`/customer/${customerId}/groups`} className={'sm:flex'} />
          </TitleActions>
          <div className="flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0">
            {isFetched && data ? (
              <>
                <Table
                  data={{
                    ...data,
                    items: invoicePreviewData,
                  }}
                  columns={invoicePreviewTableColumns}
                  checkbox={true}
                  isCheckAll={isCheckAll}
                  setIsCheckAll={setIsCheckAll}
                  checkedRowIds={checkedRowIds}
                  setCheckedRowIds={setCheckedRowIds}
                  textLinks={true}
                  view={false}
                  actions={false}
                  canUserEdit={isUserOrAdmin}
                />
                <TablePagination count={Number(data.count)} page={Number(data.page)} perPage={Number(data.perPage)} />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </>
  );
};
export default CustomerInvoicing;

import { Tabs } from '../../common/tabs/tabs';
import { TabsItem } from '../../common/tabs/tabs-item';

export const ProductOrderTypeTabs = () => {
  return (
    <div className="w-full mb-4">
      <Tabs>
        <TabsItem title={'Rentals'} active={true} />
        <TabsItem title={'Sales'} />
      </Tabs>
    </div>
  );
};

import { useState } from 'react';

import { ReadyEquipment } from '../../api/equipment';
import { useReadyEquipmentsList } from '../../api/equipment/get-ready-equipments-list';
import TablePopoverPagination from '../table/popover/table-popover-pagination';
import TablePopoverSearch from '../table/popover/table-popover-search';
import { Table } from '../table/table';

const hooks: { [key: string]: any } = {
  readyEquipments: useReadyEquipmentsList,
};

type OrderReadyEquipmentsPanelProps = {
  orderId?: string | number | undefined;
  customerId?: number;
  itemKey: string;
  setParentValue: any;
  parentValue: ReadyEquipment[];
};

export default function OrderReadyEquipmentsPanel({
  orderId,
  customerId,
  itemKey,
  parentValue,
  setParentValue,
}: OrderReadyEquipmentsPanelProps) {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const hook = hooks[itemKey];

  // TODO: Consolidate use of URLSearchParams vs GetItemProps
  const response = hook({
    search,
    page: String(page),
    customerId: customerId ? String(customerId) : null,
    orderId: orderId ? String(orderId) : null,
  });

  const onCheck = (checks: number[]) => {
    if (!response.data) return;
    const checkedItems = response.data.items.filter((item: ReadyEquipment) => checks.includes(item.id));
    const uncheckedItems = response.data.items.filter((item: ReadyEquipment) => !checks.includes(item.id));
    setParentValue(checkedItems, uncheckedItems);
  };

  const onSearch = async (search: string) => {
    await setSearch(search);
    await setPage(1);
    await response.refetch();
  };

  const onPageChange = async (page: number) => {
    await setPage(page);
    await response.refetch();
  };

  return (
    <div className="flex flex-col h-full gap-4 overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5 p-5 bg-white text-sm">
      <TablePopoverSearch value={search} onSearch={onSearch} />
      {response.isFetched ? (
        <>
          <Table
            columns={[{ title: '' }, { title: 'Equipment' }, { title: 'PR' }, { title: 'MKT' }, { title: 'PK' }]}
            checkbox={true}
            checkedRowIds={parentValue.map((p) => p.id)}
            setCheckedRowIds={onCheck}
            data={{
              count: response.data.items.length,
              items: response.data.items,
              page: Number(response.data.page),
              perPage: Number(response.data.page),
            }}
            actions={false}
            view={false}
            canUserEdit={false}
          />
          <TablePopoverPagination
            onChange={onPageChange}
            page={Number(response.data.page)}
            perPage={Number(response.data.perPage)}
            count={Number(response.data.count)}
          />
        </>
      ) : null}
    </div>
  );
}

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { TYPE_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { Type } from './index';

export const getOneType = (id: number | null): Promise<Type> | null => {
  if(id === null || isNaN(id)) return null;
  return axios.get(`/type/${id}`);
};

export const useOneType = (id: number) => {
  return useQuery<Type | null, AxiosError<AxiosErrorResponse>>({
    queryKey: [TYPE_KEY, id],
    queryFn: () => getOneType(id),
    keepPreviousData: true,
  });
};

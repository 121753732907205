import { Tabs } from '../../common/tabs/tabs';
import { TabsItem } from '../../common/tabs/tabs-item';

const orderFormTabs = [
  {
    title: 'Order Details',
  },
  {
    title: 'Review Order',
  },
];

export const OrderFormTabs = () => {
  return (
    <div className="w-full mb-4">
      <Tabs>
        {orderFormTabs.map((o, k) => (
          <TabsItem title={o.title} key={k} active={k === 0} />
        ))}
      </Tabs>
    </div>
  );
};
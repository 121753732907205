import { Dispatch, SetStateAction, useState } from 'react';

import { Order } from '../../api/order';
import { TableColumnProp } from '../table/data-cols';
import { Table } from '../table/table';

export type OrderInventoriesTableProps = {
  order: Order;
  checkedRowIds: number[];
  setCheckedRowIds: Dispatch<SetStateAction<number[]>>;
};

export const orderInventoriesTableColumns: TableColumnProp[] = [
  {
    title: 'Title',
  },
  {
    title: 'Serial Number',
  },
  {
    title: 'Asset Number',
  },
  {
    title: 'PM Date',
  },
  {
    title: 'Accessories',
  },
  {
    title: 'Invoice Group',
  },
];

export const OrderInventoriesTable = ({ order, checkedRowIds, setCheckedRowIds }: OrderInventoriesTableProps) => {
  const [isCheckAll, setIsCheckAll] = useState(false);

  const orderInventories = getOrderInventoriesForTable(order);

  return (
    <Table
      data={{
        count: orderInventories.length,
        items: orderInventories,
        page: 1,
        perPage: orderInventories.length,
      }}
      checkbox={true}
      columns={orderInventoriesTableColumns}
      isCheckAll={isCheckAll}
      setIsCheckAll={setIsCheckAll}
      checkedRowIds={checkedRowIds}
      setCheckedRowIds={setCheckedRowIds}
      actions={false}
      view={false}
    />
  );
};

const getOrderInventoriesForTable = (order: Order) => {
  return order.orderInventories.map((orderInventory) => ({
    id: orderInventory.id,
    product: orderInventory.inventory.product.title,
    serialNumber: orderInventory.inventory.sn,
    assetNumber: orderInventory.inventory.assetId,
    pmDate: orderInventory.inventory.lastPm,
    accessories: 'N/A',
    invoiceGroup: orderInventory.invoiceGroup?.name,
  }));
};

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Customer } from '../../api/customer';
import { useCustomerCreate } from '../../api/customer/create-customer';
import { useLocation } from '../../api/location/get-location';
import { useUsers } from '../../api/user/get-users';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { CustomerForm } from '../../components/customer/form/customer-form';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

export const CustomerCreatePage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Customer>();

  const createCustomer = useCustomerCreate();
  const users = useUsers();
  const locations = useLocation({ page: '1', perPage: '100' });

  const onSubmit = async (values: Customer) => {
    try {
      await createCustomer.mutateAsync(values);
      navigate('/customer');
    } catch {
      // error notification sent by axios
      return;
    }
  };

  if (!users.isSuccess || !locations.isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <NavigationBackButton url={-1} />

          <TitleActions title="New Customers" />

          <CustomerForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            users={users.data.items}
            locations={locations.data.items}
            isSubmitting={isSubmitting}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { useSearchParams } from 'react-router-dom';

import { Customer, TableCustomer } from '../../api/customer';
import { useCustomers } from '../../api/customer/get-customers';
import { PageContainer } from '../../components/common/container/page.container';
import { Searchbar } from '../../components/common/search/searchbar';
import { LinkButton } from '../../components/common/title-action/link-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { ImportCustomerButton } from '../../components/customer/button/import-customer-button';
import { ImportPricingButton } from '../../components/customer/button/import-customer-pricing-button';
import { Spinner } from '../../components/spinner';
import { customerTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';
import { TablePagination } from '../../components/table/table-pagination';
import { useRoles } from '../../provider/roles-provider';

export const CustomerPage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const [searchParam] = useSearchParams();
  const { data, isSuccess } = useCustomers(searchParam);

  const mapItems = (items: Customer[]): TableCustomer[] => {
    return items.map(
      (i): TableCustomer => ({
        id: i.id,
        titleLink: { title: i.title, link: String(i.id) },
        billing: i.address?.billing
          ? [i.address.billing.street1, i.address.billing.city, i.address.billing.state, i.address.billing.zip].join(
              ', '
            )
          : '-',
        shipping: i.address?.shipping
          ? [
              i.address.shipping.street1,
              i.address.shipping.city,
              i.address.shipping.state,
              i.address.shipping.zip,
            ].join(', ')
          : '-',
        activeRentalCount: i.activeRentalCount,
        market: i.market?.title,
        salesRep: i.user?.name || 'No Sales Rep',
        links: {
          view: `${i.id}/pricing`,
        },
      })
    );
  };

  if (!isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <Searchbar />

          <TitleActions title="Customers">
            {process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED === 'true' ? (
              <>
                <ImportPricingButton title="Import Pricing" />
                <ImportCustomerButton title="Import Customers" />
              </>
            ) : null}
            <LinkButton
              icon="/media/plus-icon.svg"
              link={'/customer/create'}
              title="New Customer"
              className={'sm:flex'}
            />
          </TitleActions>

          <>
            <Table
              data={{
                ...data,
                items: mapItems(data.items),
              }}
              columns={customerTableColumns}
              canUserEdit={isAdmin ?? undefined}
            />
            <TablePagination count={Number(data.count)} page={Number(data.page)} perPage={Number(data.perPage)} />
          </>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

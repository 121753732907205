import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { INVOICE_GROUP_KEY } from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { InvoiceGroup } from './index';

type CreateInvoiceGroupProps = {
  group: InvoiceGroup;
  customerId: string | unknown;
};

export const createInvoiceGroup = ({ group, customerId }: CreateInvoiceGroupProps): Promise<InvoiceGroup> => {
  return axios.post(`/invoicing/groups/${customerId}`, { ...group });
};

export const useInvoiceGroupCreate = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      await queryClient.refetchQueries([INVOICE_GROUP_KEY]);
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Invoice Group created successfully',
      });
    },
    mutationFn: createInvoiceGroup,
  });
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCustomer } from '../../../api/customer/get-customer';
import { PageContainer } from '../../../components/common/container/page.container';
import { Tabs } from '../../../components/common/tabs/tabs';
import { TabsItem } from '../../../components/common/tabs/tabs-item';
import { TitleActions } from '../../../components/common/title-action/title-actions';
import { useRoles } from '../../../provider/roles-provider';
import CustomerInvoicing from '../invoicing/customer-invoicing';

export const CustomerDetailsPage = () => {
  const { customerId } = useParams();
  const { isSuccess, data } = useCustomer(customerId);
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const [active, setActive] = useState<'INFO' | 'RENTALS' | 'INVOICING' | 'PRICING'>('INFO');

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <TitleActions title={isSuccess && data ? data.title : ''}></TitleActions>
          <Tabs>
            <TabsItem title={'Customer Info'} active={active === 'INFO'} onClick={() => setActive('INFO')} />
            <TabsItem title={'Active Rentals'} active={active === 'RENTALS'} onClick={() => setActive('RENTALS')} />
            <TabsItem title={'Invoicing'} active={active === 'INVOICING'} onClick={() => setActive('INVOICING')} />
            <TabsItem title={'Pricing'} active={active === 'PRICING'} onClick={() => setActive('PRICING')} />
          </Tabs>
          {active === 'INVOICING' && <CustomerInvoicing />}
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { useSetInventoryItemStatus } from '../../../api/inventory/set-inventory-item-status';
import { Status } from '../../../api/status';
import Dropdown from '../../common/dropdown/dropdown';
import DropdownItem from '../../common/dropdown/dropdown-item';

export type InventoryActionDropdownProps = {
  statuses: Status[];
  inventoryId: number;
  className?: string;
};

export const InventoryActionDropdown = ({ statuses, className, inventoryId }: InventoryActionDropdownProps) => {
  const setStatus = useSetInventoryItemStatus();

  const onChangeStatus = async (id: number, statusId: number) => {
    await setStatus.mutateAsync({ id, statusId });
  };

  return (
    <div className={`${className}`}>
      {statuses.length ? (
        <Dropdown title="Actions">
          {statuses.map((s, k) => (
            <DropdownItem
              title={`Set ${s.title}`}
              onClick={async () => await onChangeStatus(inventoryId, s.id)}
              key={k}
            ></DropdownItem>
          ))}
        </Dropdown>
      ) : null}
    </div>
  );
};

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

export default function Dropdown({ title, children, defaultValue, disabled, justifyStart }: any) {
  const displayText = defaultValue || title;

  return (
    <div className="flex justify-start items-center px-3 py-1 border border-[#dde2e4ff] border-solid rounded-lg box-border bg-[rgba(255,255,255,1)] z-40">
      <Menu as="div" className="relative inline-block text-left w-full">
        <div>
          <Menu.Button
            disabled={disabled}
            className={`flex items-center ${
              justifyStart ? 'justify-start' : 'justify-between'
            } h-[100%] w-full box-border gap-2 border-[#252c32ff] text-xs sm:text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px] disabled:opacity-50`}
          >
            <ChevronDownIcon className="mr-0 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
            <span className="ml-0 truncate">{displayText}</span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-[-12px] mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useOneCustomer } from '../../../api/customer/get-one-customer';
import { ReadyEquipment } from '../../../api/equipment';
import { Order } from '../../../api/order';
import { User } from '../../../api/user';
import { formatDateForInput } from '../../../lib/format-date';
import PopoverSelect from '../../common/popover-select/popover-select';
import CustomerInfoBlock from '../../customer/customer-info-block';
import { Checkbox } from '../../form/checkbox';
import { Label } from '../../form/label';
import { LabelInput } from '../../form/labelInput';
import { LabelSelect } from '../../form/labelSelect';
import { Line } from '../../form/line';
import { SubmitButton } from '../../form/submit-button';
import OrderReadyEquipmentsPanel from '../order-ready-equipments-panel';

export type OrderFormProps = {
  isForEdit?: boolean;
  onSubmit: any;
  initialData?: Partial<Order> & { id: number };
  users: User[];
  equipments: ReadyEquipment[];
};

const SHIPMENT_DELIVERY = 'SHIPMENT_DELIVERY';
const LOCAL_DELIVERY = 'LOCAL_DELIVERY';

const SHIPMENT_PICKUP = 'SHIPMENT_PICKUP';
const LOCAL_PICKUP = 'LOCAL_PICKUP';

const DELIVERY = 'DELIVERY';
const PICKUP = 'PICKUP';
// const SALE = 'SALE';

const pickupModeOptions = [
  {
    id: LOCAL_PICKUP,
    title: 'Local Pickup',
  },
  {
    id: SHIPMENT_PICKUP,
    title: 'Shipment Pickup',
  },
];

const shippingCompanyOptions = [
  {
    id: 'ups',
    title: 'UPS',
  },
  {
    id: 'fedex',
    title: 'Fedex',
  },
  {
    id: 'other',
    title: 'Other',
  },
];

const deliveryMode = [
  {
    id: LOCAL_DELIVERY,
    title: 'Local Delivery',
  },
  {
    id: SHIPMENT_DELIVERY,
    title: 'Shipment Delivery',
  },
];

export const orderTypes = [
  {
    id: 'DELIVERY',
    title: 'Delivery',
  },
  {
    id: 'PICKUP',
    title: 'Pickup',
  },
  // {
  //   id: 'SALE',
  //   title: 'Sale',
  // },
];

export const OrderForm = ({ users, onSubmit, equipments, initialData, isForEdit }: OrderFormProps) => {
  const {
    register,
    setValue,
    unregister,
    resetField,
    formState: { isSubmitting },
  } = useFormContext<Order>();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  // const inputRef = useRef<HTMLInputElement | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderType, setOrderType] = useState<string | undefined>(type || initialData?.orderType);

  const [equips, setEquip] = useState<ReadyEquipment[]>(
    initialData?.productBookings?.map((e) => ({
      id: e.productId,
      title: e.product.title,
      count: equipments.find((eq) => eq.id === e.productId)?.count || 0,
      total: equipments.find((eq) => eq.id === e.productId)?.total || 0,
      market: equipments.find((eq) => eq.id === e.productId)?.market || 0,
      qty: e.qty,
    })) || []
  );

  const [delMode, setDeliveryMode] = useState<boolean>(() => initialData?.deliveryMode === SHIPMENT_DELIVERY); // if delivery mode
  const [pickupMode, setPickupMode] = useState<boolean>(() => initialData?.pickupMode === SHIPMENT_PICKUP); // if pickup mode

  const [customerId, setCustomerId] = useState<any>(initialData?.customerId || null);
  const [isReceiver, setIsReceiver] = useState<any>(
    initialData?.isReceiverName === undefined ? true : initialData?.isReceiverName
  );

  const oneCustomer = useOneCustomer(customerId);

  const setEquipment = (checked: ReadyEquipment[], uncheckedItems: ReadyEquipment[]) => {
    // Filter out unchecked items from the current equipments
    const updatedEquipments = equips.filter((equip) => !uncheckedItems.some((unchecked) => unchecked.id === equip.id));

    // Add new checked items that are not already in equips
    const newEquipments = checked
      .filter((checkedItem) => !updatedEquipments.some((equip) => checkedItem.id === equip.id))
      .map((checkedItem) => ({
        ...checkedItem,
        qty: 1,
      }));

    // Combine the updated existing equipments with the new equipments
    setEquip([...updatedEquipments, ...newEquipments]);
  };

  const plusEq = (id: number) => {
    const eq = equips.find((e) => e.id === id);

    if (!eq) return;

    const filteredEq = equips.filter((e) => e.id !== id);

    if (eq.qty + 1 > eq.total) return;

    setEquip([...filteredEq, { ...eq, qty: eq.qty + 1 }].sort((a, b) => a.id - b.id));
  };

  const minusEq = (id: number) => {
    const eq = equips.find((e) => e.id === id);

    if (!eq) return;

    const filteredEq = equips.filter((e) => e.id !== id);

    if (eq.qty - 1 === 0) {
      return setEquip(filteredEq.sort((a, b) => a.id - b.id));
    }

    setEquip([...filteredEq, { ...eq, qty: eq.qty - 1 }].sort((a, b) => a.id - b.id));
  };

  const sub = (values: any, submit: any) => {
    submit(values);
  };

  useEffect(() => {
    setValue(
      'productBookings',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      equips.map((e) => ({ productId: e.id, qty: e.qty }))
    );
  }, [equips]);

  useEffect(() => {
    if (customerId) {
      (async () => {
        await oneCustomer.refetch();
        if (oneCustomer.data?.contactPerson) setValue('callerName', oneCustomer.data?.contactPerson);
        if (oneCustomer.data?.callerPhoneNumber) setValue('callerPhoneNumber', oneCustomer.data?.callerPhoneNumber);
        if (oneCustomer.data?.email) setValue('callerEmail', oneCustomer.data?.email);
      })();
    }
  }, [customerId]);

  useEffect(() => {
    if (orderType !== DELIVERY) {
      unregister('deliveryMode', { keepDirty: false, keepTouched: false });
      unregister('productBookings', { keepDirty: false, keepTouched: false });
    }

    if (orderType !== PICKUP) {
      unregister('pickupMode', { keepDirty: false, keepTouched: false });
    }

    if (!delMode) {
      unregister('shippingCompany', { keepDirty: false, keepTouched: false });
    }

    if (!pickupMode) {
      unregister('shippingCompany', { keepDirty: false, keepTouched: false });
    }

    if (!isReceiver) {
      unregister('receiverName', { keepDirty: false, keepTouched: false, keepDefaultValue: true });
      unregister('receiverPhoneNumber', { keepDirty: false, keepTouched: false, keepDefaultValue: true });
      unregister('receiverEmail', { keepDirty: false, keepTouched: false, keepDefaultValue: true });
    }
  }, [orderType, delMode, isReceiver]);

  return (
    <form onSubmit={onSubmit(sub)} className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 items-start pb-20">
      <div className="flex flex-col space-y-4 w-full">
        <div className="flex flex-col space-y-4">
          <LabelInput
            title={'Date of order'}
            type="date"
            attributes={{
              ...register('orderDate', {
                required: true,
                valueAsDate: true,
                value: formatDateForInput(new Date()),
              }),
            }}
          />
          <PopoverSelect
            title="Customer"
            setedValue={oneCustomer?.data?.title}
            parentValue={customerId}
            parentValueSetter={setCustomerId}
            itemKey="atIds"
            setValue={setValue}
            registerName="customerId"
            register={register}
            isRequired
          />

          {oneCustomer.isSuccess && oneCustomer.data ? (
            <>
              <div className="block lg:hidden">
                <CustomerInfoBlock customer={oneCustomer.data} onRefresh={oneCustomer.refetch} />
              </div>
              <LabelInput
                title={'Caller Name'}
                attributes={{
                  ...register('callerName', {
                    value: oneCustomer.data.contactPerson || '',
                  }),
                }}
              />
              <LabelInput
                title={'Caller Phone Number'}
                attributes={{
                  ...register('callerPhoneNumber', {
                    value: oneCustomer.data.callerPhoneNumber || '',
                  }),
                }}
              />
              <LabelInput
                title={'Caller Email'}
                attributes={{
                  ...register('callerEmail', {
                    value: oneCustomer.data.email || '',
                  }),
                }}
              />
            </>
          ) : null}
          {/*

          Do we need to remove this field from the form altogether since the orderType is set before the User gets to this page?

          <LabelSelect
            title={'Order Type'}
            data={orderTypes}
            option={'Select order type:'}
            attributes={{
              ...register('orderType', {
                required: 'Select one option',
                onChange: (e: any) => setOrderType(e.target.value),
              }),
            }}
          /> */}
          {orderType === DELIVERY ? (
            <>
              <LabelInput
                title={'Date Needed'}
                type="date"
                attributes={{
                  ...register('orderNeededDate', {
                    required: true,
                    valueAsDate: true,
                    value: formatDateForInput(new Date()),
                  }),
                }}
              />
              {oneCustomer.isSuccess && oneCustomer.data ? (
                <LabelInput
                  title={'Shipping Address'}
                  attributes={{
                    ...register('shipTo', {
                      required: true,
                      value: [
                        oneCustomer.data.address.shipping.street1,
                        oneCustomer.data.address.shipping.city,
                        oneCustomer.data.address.shipping.state,
                        oneCustomer.data.address.shipping.zip,
                      ].join(', '),
                    }),
                  }}
                />
              ) : null}

              <LabelSelect
                title={'Delivery Mode'}
                data={deliveryMode}
                option={'Select delivery mode:'}
                onChange={(i) => {
                  const value = i.target.value;
                  if (value === LOCAL_DELIVERY) {
                    resetField('shippingCompany');
                  }
                  setDeliveryMode(i.target.value === SHIPMENT_DELIVERY);
                }}
                attributes={{
                  ...register('deliveryMode', {
                    required: 'Select one option',
                    onChange: (i: any) => setDeliveryMode(i.target.value === SHIPMENT_DELIVERY),
                  }),
                }}
              />
              {delMode ? (
                <LabelSelect
                  title={'Shipping Company'}
                  option={'Select shipping company:'}
                  data={shippingCompanyOptions}
                  attributes={{
                    ...register('shippingCompany', {
                      required: 'Select one option',
                      onChange: (i: any) => console.log(i.target.value),
                    }),
                  }}
                />
              ) : null}
            </>
          ) : null}
          {orderType === PICKUP ? (
            <>
              {oneCustomer.isSuccess && oneCustomer.data ? (
                <LabelInput
                  title={'Pickup Address'}
                  attributes={{
                    ...register('shipTo', {
                      required: true,
                      value: [
                        oneCustomer.data.address.shipping.street1,
                        oneCustomer.data.address.shipping.city,
                        oneCustomer.data.address.shipping.state,
                        oneCustomer.data.address.shipping.zip,
                      ].join(', '),
                    }),
                  }}
                />
              ) : null}

              <LabelSelect
                title={'Pickup Mode'}
                data={pickupModeOptions}
                option={'Select pickup mode:'}
                attributes={{
                  ...register('pickupMode', {
                    required: 'Select one option',
                  }),
                }}
                onChange={(i) => {
                  const value = i.target.value;
                  if (value === LOCAL_PICKUP) {
                    resetField('shippingCompany');
                  }
                  setPickupMode(i.target.value === SHIPMENT_PICKUP);
                }}
              />
              {pickupMode ? (
                <LabelSelect
                  title={'Shipping Company'}
                  option={'Select shipping company:'}
                  data={shippingCompanyOptions}
                  attributes={{
                    ...register('shippingCompany', {
                      required: 'Select one option',
                    }),
                  }}
                />
              ) : null}
            </>
          ) : null}
          {orderType === DELIVERY ? (
            <>
              <Label title={'Receiver Name'} />
              <div className="flex flex-col space-y-4 w-full pl-3">
                <Checkbox
                  attributes={{
                    ...register('isReceiverName', {
                      onChange: () => setIsReceiver(!!isReceiver),
                    }),
                  }}
                  title={'Same as caller above'}
                />
                {!isReceiver ? (
                  <>
                    <LabelInput
                      title={'Receiver Name'}
                      attributes={{ ...register('receiverName', { required: true }) }}
                    />

                    <LabelInput
                      title={'Receiver Phone Number'}
                      attributes={{ ...register('receiverPhoneNumber', { required: true }) }}
                    />
                    <LabelInput
                      title={'Receiver Email'}
                      attributes={{ ...register('receiverEmail', { required: true }) }}
                    />
                  </>
                ) : null}
              </div>
              <LabelInput title={'Order PO'} attributes={{ ...register('orderPO') }} />
            </>
          ) : null}
          {/* Do we want to remove this field altogether since it can be set from the orders page? */}
          {!isForEdit && initialData?.orderType === 'DELIVERY' ? (
            <LabelSelect
              title={'Assigned to Fill'}
              data={users?.map((c) => ({ id: c.id, title: c.name }))}
              option={'Select user:'}
              attributes={{ ...register('userId', { required: 'Select one option', valueAsNumber: true }) }}
            />
          ) : null}
          <>
            {customerId && orderType === 'DELIVERY' ? (
              <div className="flex flex-col space-y-1 h-[54rem]">
                <Label title={'Choose Equipment'} />
                <OrderReadyEquipmentsPanel
                  itemKey="readyEquipments"
                  customerId={customerId}
                  orderId={initialData?.id ? `${initialData.id}` : undefined}
                  parentValue={equips}
                  setParentValue={setEquipment}
                />
              </div>
            ) : null}

            <LabelInput
              title={'Order Notes'}
              className="h-20 w-[100%]"
              attributes={{ ...register('description', { required: true }) }}
              isTextarea={true}
            />
          </>
        </div>
      </div>
      <div className="sticky top-4 lg:space-y-4">
        {oneCustomer.isSuccess && oneCustomer.data && (
          <div className="hidden lg:block">
            <CustomerInfoBlock customer={oneCustomer.data} onRefresh={oneCustomer.refetch} />
          </div>
        )}
        {orderType == DELIVERY ? (
          <>
            <h1>Equipments</h1>
            {equips.length === 0 && <h3>Select Equipments in the list on the left</h3>}
            <div className="max-h-[41rem] overflow-y-auto">
              {' '}
              {/* Adjust max-h-64 to the desired height */}
              {equips.map((e: ReadyEquipment, key: number) => (
                <div className="flex bg-[rgba(224,224,224,0.37)] p-4 mb-2 justify-between" key={key}>
                  <p>{e.title}</p>
                  <div className="flex gap-4">
                    <button onClick={() => minusEq(e.id)} type="button">
                      -
                    </button>
                    <span>
                      {e.qty} / {e.total}
                    </span>
                    <button onClick={() => plusEq(e.id)} type="button">
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <div className="col-span-full flex flex-col items-center">
        <Line />
        <SubmitButton submitText={orderType === 'PICKUP' ? 'Submit to Admin' : 'Save'} isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

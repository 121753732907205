import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { MASTER_INVENTORY_ITEM_KEY } from './get-inventory-item';

import { Inventory } from './index';

type EditInventoryProps = {
  inventory: Inventory;
  id: string | unknown;
};

export const editInventory = ({ inventory, id }: EditInventoryProps): Promise<Inventory> => {
  const updateInventoryDto = {
    ...inventory,
    createdAt: undefined,
    updatedAt: undefined,
    statusId: undefined,
    importId: undefined,
    isDisposable: undefined,
    isPart: undefined,
    deletedAt: undefined,
    histories: undefined,
    product: undefined,
    status: undefined,
    owner: undefined,
    location: undefined,
    at: undefined,
    snArray: undefined,
  };
  return axios.put(`/inventory/${id}`, updateInventoryDto);
};

export const useInventoryEdit = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: async (data: Inventory) => {
      await queryClient.refetchQueries([MASTER_INVENTORY_ITEM_KEY, String(data.id)]);
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Inventory saved successfully',
      });
    },
    mutationFn: editInventory,
  });
};

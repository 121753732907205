import { Disclosure } from '@headlessui/react';

import { MenuItem } from './menu-item';

export const MenuList = ({ navigation }: any) => {
  return navigation.map((item: any, key: number) => (
    <Disclosure key={key} defaultOpen={true}>
      <MenuItem item={item} />
    </Disclosure>
  ));
};

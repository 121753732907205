import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Customer } from '../../api/customer';
import { useCustomer } from '../../api/customer/get-customer';
import { useCustomerEdit } from '../../api/customer/update-customer';
import { useLocation } from '../../api/location/get-location';
import { useUsers } from '../../api/user/get-users';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { CustomerForm } from '../../components/customer/form/customer-form';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

export const CustomerEditPage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const navigate = useNavigate();
  const { id } = useParams();

  const { isSuccess, data: customer } = useCustomer(id);

  const editCustomer = useCustomerEdit();
  const users = useUsers();
  const locations = useLocation({ page: '1', perPage: '100' });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<Customer>();

  const onSubmit = async (customer: Customer) => {
    try {
      const updateCustomerDto = {
        ...customer,
        createdAt: undefined,
        updatedAt: undefined,
        market: undefined,
        user: undefined,
      };
      await editCustomer.mutateAsync({ data: updateCustomerDto, id });
      navigate('/customer');
    } catch {
      // Error notification presented by axios interceptor
      return;
    }
  };

  useEffect(() => {
    reset(customer);
  }, [customer]);

  if (!isSuccess || !users.isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <NavigationBackButton url={-1} />

          <TitleActions title={`Update ${customer.title} customer`} />

          <CustomerForm
            onSubmit={handleSubmit(onSubmit)}
            users={users.data?.items || []}
            locations={locations.data?.items || []}
            register={register}
            isSubmitting={isSubmitting}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PAGE } from '../../lib/constants';

import { ItemsPerPage } from './items-per-page';

type TablePaginationOptions = {
  count: number;
  page: number;
  perPage: number;
};

export const TablePagination = ({ count, page, perPage }: TablePaginationOptions) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const from = page === 1 ? 1 : page > 1 ? (page - 1) * perPage : 0;
  const to = (page === 1 ? 0 : from) + perPage;

  const next = async () => {
    const pageParam = searchParams.get(QUERY_PAGE);

    if (to >= count) return;

    if (!pageParam) {
      searchParams.set(QUERY_PAGE, String(2));

      return setSearchParams(searchParams);
    }

    searchParams.set(QUERY_PAGE, String(Number(pageParam) + 1));

    await setSearchParams(searchParams);
  };

  const previous = async () => {
    const pageParam = searchParams.get(QUERY_PAGE);

    if (pageParam && Number(pageParam) > 1) {
      if (Number(pageParam) - 1 === 1) {
        searchParams.delete(QUERY_PAGE);

        return setSearchParams(searchParams);
      }

      searchParams.set(QUERY_PAGE, String(Number(pageParam) - 1));

      await setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const pageParam = searchParams.get(QUERY_PAGE);
    if (pageParam && Number(pageParam) === 1) {
      searchParams.delete(QUERY_PAGE);
      return setSearchParams(searchParams);
    }
  }, []);

  return (
    <div className="flex justify-between sm:justify-end items-center gap-3 sm:gap-8 text-[12px] w-full">
      <div className="flex items-center gap-2">
        Rows per page:
        <ItemsPerPage />
      </div>
      <span className="flex">
        {from + (page === 1 ? 0 : 1)} - {to >= count ? count : to} of {count}
      </span>
      <ChevronLeftIcon className={`h-5 ${page === 1 ? 'text-gray-300' : ''}`} onClick={previous} />
      <ChevronRightIcon className={`h-5 ${to >= count ? 'text-gray-300' : ''}`} onClick={next} />
    </div>
  );
};

import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";

import { Accessory, Component } from "../../api/equipment";
import { useOneEquipment } from '../../api/equipment/get-one-equipment';
import { TableContainer } from "../../components/common/table/table-container";
import { TableDataCell } from "../../components/common/table/table-data-cell";
import { TableDataRow } from "../../components/common/table/table-data-row";
import { TableDataValue } from "../../components/common/table/table-data-value";
import { LinkButton } from "../../components/common/title-action/link-button";
import { TitleActions } from "../../components/common/title-action/title-actions";
import { NavigationButton } from "../../components/navigation/navigation-button";
import { Spinner } from "../../components/spinner";

const productInfoCols = [
    "Manufacturer",
    "Type",
    "Category",
    "isDisposable",
    "isPart",
    "PM Type",
    "Created At",
    "Updated At",
];

export const EquipmentViewPage = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, isRefetching, isSuccess } = useOneEquipment(Number(id));

    if (isLoading || isRefetching) return <Spinner />;

    return isSuccess ? (
        <>
            <div className="flex flex-col justify-start items-start w-full h-full p-4 box-border overflow-x-auto mb-[80px] md:mb-0">

                {/**  NAVIGATION BACK * */}
                <button onClick={() => navigate(-1)}>
                    <NavigationButton title="Equipment" url="#">
                        <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
                    </NavigationButton>
                </button>

                <TitleActions title={data?.title}>
                    <LinkButton title="Edit" link={`/equipment/edit/${id}`} />
                </TitleActions>

                <TableContainer className="mb-8 h-full">
                    <TableDataRow className="h-14">
                        {productInfoCols.map((col: string, key: number) => (
                            <TableDataCell key={key} className="px-[10px] border-r border-1">
                                <TableDataValue text={col} />
                            </TableDataCell>
                        ))}
                    </TableDataRow>

                    <TableDataRow className="h-14">
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={data?.manufacturer ? data?.manufacturer : 'N/A'} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={data?.type ? data?.type.title : 'None'} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={data?.category ? data?.category.title : 'None'} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={String(data?.isDisposable)} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={String(data?.isPart)} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={data?.pm_type ? data?.pm_type : 'N/A'} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={data?.createdAt} />
                        </TableDataCell>
                        <TableDataCell className="px-[10px] border-r border-1">
                            <TableDataValue text={data?.updatedAt} />
                        </TableDataCell>
                    </TableDataRow>

                </TableContainer>


                <div className="flex flex-row space-x-5">
                    <div className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md border border-1 border-gray-[rgba(224,224,224,0.40)] mr-2 mb-2">
                        <span className="font-bold">Accessories</span>
                        {data?.accessories.map((acc: Accessory, key: number) => (
                            <div key={key}>
                                <span>{acc.title}</span>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md border border-1 border-gray-[rgba(224,224,224,0.40)] mr-2 mb-2">
                        <span className="font-bold">Components</span>
                        {data?.components.map((acc: Component, key: number) => (
                            <div key={key}>
                                <span>{acc.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    ) : null;
}
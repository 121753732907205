import { useSearchParams } from 'react-router-dom';

import { Order } from '../../api/order';
import { useOrders } from '../../api/order/get-orders';
import { useCurrentUser, useUsers } from '../../api/user/get-users';
import { PageContainer } from '../../components/common/container/page.container';
import { Searchbar } from '../../components/common/search/searchbar';
import { LinkButton } from '../../components/common/title-action/link-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { PersonAssignedDropdown } from '../../components/order/dropdown/person-assigned-dropdown';
import { OrderTabs } from '../../components/order/tabs/order-tabs';
import { Spinner } from '../../components/spinner';
import { ordersTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';
import { TablePagination } from '../../components/table/table-pagination';
import { formatDate } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';

export const OrderPage = () => {
  const [searchParam] = useSearchParams();
  const { data, isSuccess } = useOrders(searchParam);

  const users = useUsers();
  const { data: currentUser } = useCurrentUser();
  const currentUserId = currentUser ? currentUser.id : null;

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  if (!isSuccess || !users.isSuccess || !currentUser) return <Spinner />;

  const personAssignedRenderer = (order: Order): React.ReactNode => {
    return <PersonAssignedDropdown users={users.data.items} order={order} className="w-40"/>;
  };

  const mapItems = (items: Order[]): any[] => {
    return items.map((i: Order) => ({
      id: i.id,
      orderNo: i.id,
      customerName: i.customer.title,
      personAssigned: personAssignedRenderer(i),
      type: i.status.replaceAll('_', ' '),
      orderType: i.orderType,
      deliveryMode: i.deliveryMode ? i.deliveryMode.replaceAll('_', ' ') : i.pickupMode.replaceAll('_', ' '),
      orderDate: formatDate(i.orderDate),
      orderNeededDate: formatDate(i.orderNeededDate),
      links: i.orderType === 'DELIVERY' ? { view: `/order/${i.id}` } : { view: `/pickup-order/${i.id}` },
    }));
  };

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <Searchbar />

          <TitleActions title="Orders">
            <LinkButton icon="/media/plus-icon.svg" link={'choose'} title="New Order" className={'sm:flex'} />
          </TitleActions>

          <OrderTabs currentUser={currentUserId}/>
          <>
            <Table
              data={{
                ...data,
                items: mapItems(data.items),
              }}
              checkbox={false}
              columns={ordersTableColumns}
              editOnly={true}
              canUserEdit={isUserOrAdmin}
            />
            <TablePagination count={Number(data.count)} page={Number(data.page)} perPage={Number(data.perPage)} />
          </>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { AxiosErrorResponse } from '../../lib/types';

import { OrderHistory } from './index';

export const ORDER_HISTORY = 'ORDER_HISTORY';

export const getOrderHistory = (id: number): Promise<OrderHistory[]> => {
  return axios.get(`/order/${id}/history`);
};

export const useOrderHistory = <TData = OrderHistory[]>(
    id: number,
    config: UseQueryOptions<OrderHistory[], AxiosError<AxiosErrorResponse>, TData> = {}) => {
  return useQuery<OrderHistory[], AxiosError<AxiosErrorResponse>, TData>({
    ...config,
    queryKey: [ORDER_HISTORY, id],
    queryFn: () => getOrderHistory(id),
    keepPreviousData: false,
  });
};

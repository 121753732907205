import { Tabs } from '../../common/tabs/tabs';
import { TabsItem } from '../../common/tabs/tabs-item';

const tabs = ['Information', 'BioMed', 'Repairs', 'Files', 'Recalls', 'Accessories', 'Notes'];

export const InventoryViewTabs = () => {
  return (
    <div className="my-8 w-full">
      <Tabs>
        {tabs.map((t, key) => (
          <TabsItem title={t} active={key === 0} key={key} />
        ))}
      </Tabs>
    </div>
  );
};

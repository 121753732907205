import { useParams, useSearchParams } from 'react-router-dom';

import { useInvoiceGroups } from '../../../api/invoicing/get-customer-invoice-groups';
import { useInvoiceGroupItems } from '../../../api/invoicing/get-invoice-group-items';
import { PageContainer } from '../../../components/common/container/page.container';
import { LinkButton } from '../../../components/common/title-action/link-button';
import { TitleActions } from '../../../components/common/title-action/title-actions';
import { Spinner } from '../../../components/spinner';
import { invoiceGroupItemsColumns } from '../../../components/table/data-cols';
import { Table } from '../../../components/table/table';
import { TablePagination } from '../../../components/table/table-pagination';
import { useRoles } from '../../../provider/roles-provider';

export const CustomerInvoiceGroupView = () => {
  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));
  const [searchParams] = useSearchParams();
  const { customerId, groupId } = useParams();

  const page = searchParams.get('page') || '1';
  const perPage = searchParams.get('perPage') || '10';

  const customerInvoiceGroups = useInvoiceGroups({
    args: { customerId },
    config: {
      select: (data) => {
        return {
          ...data,
        };
      }
    },
  });

  const invoiceGroupItems = useInvoiceGroupItems({
    args: { customerId, groupId, page, perPage },
    config: {
      select: (data) => {
        return {
          ...data,
        };
      }
    },
  });

  // Map items for the table component
  const mapItems = (data: any) => {
    return data.items.map((item: any) => ({
      id: item.inventory.id,
      equipmentType: item.inventory.product.title,
      asset: item.inventory.assetId,
      sn: item.inventory.sn,
    }));
  };

  const currentGroup = groupId ? customerInvoiceGroups.data?.items.find(group => group.id === parseInt(groupId, 10)) : null;
  const pageTitle = currentGroup ? currentGroup.name : 'Invoice Group';

  if (!invoiceGroupItems.isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <TitleActions title={pageTitle} >
          <LinkButton
              icon="/media/plus-icon.svg"
              title="Edit Invoice Group"
              link={'/invoicing/groups/create'}
              
              className={'sm:flex'}
            />
          </TitleActions>
          <div className='flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0'>
            {invoiceGroupItems ? (
              <>
                <Table
                  data={{ 
                    ...invoiceGroupItems.data,
                    items: mapItems(invoiceGroupItems.data)
                  }}
                  columns={invoiceGroupItemsColumns}
                  textLinks={true}
                  view={false}
                  canUserEdit={isUserOrAdmin}
                />
                <TablePagination
                  count={Number(invoiceGroupItems.data.count)}
                  page={Number(invoiceGroupItems.data.page)}
                  perPage={Number(invoiceGroupItems.data.perPage)}
                />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

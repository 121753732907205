import { useMutation } from "@tanstack/react-query";

import { axios } from "../../lib/axios";
import { ORDERS_KEY } from "../../lib/constants";
import { queryClient } from "../../lib/react-query";
import { useNotificationStore } from "../../stores/notifications";

export const pendingReviewOrder = (orderId: number): Promise<any> => {
  return axios.post(`/order/pending-review/${orderId}`);
};

export const useOrderPendingReview = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: "success",
        title: "Ok",
        message: "Order pending review successfully"
      });
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });

    },
    mutationFn: pendingReviewOrder 
  });
};
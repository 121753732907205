import { Loader } from '@aws-amplify/ui-react';

export type ButtonFilledProps = {
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
};

export const ButtonFilled = ({ text, onClick, isLoading }: ButtonFilledProps) => {
  return (
    <button
      className={`my-4 min-w-[250px] max-w-[450px] font-bold bg-blue-800 text-white py-1 px-2 rounded-full disabled:opacity-40`}
      onClick={onClick}
      disabled={isLoading}
    >
      {text}
      {isLoading && <Loader filledColor="white" />}
    </button>
  );
};

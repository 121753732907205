import { useQuery, UseQueryResult, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { USER_ROLES_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

export const getUserRoles = (): Promise<string[]> => {
  return axios.get('/auth/roles');
};

export const getAllUsersAndRoles = (): Promise<any> => {
  return axios.get('user/all-users-and-groups');
};

// Currently not used, consider using this in the future if we extend the User Roles feature to include a roles column on the User entity
export const useUserRoles = (
  options?: UseQueryOptions<string[], AxiosError<AxiosErrorResponse>, string[]>
): UseQueryResult<string[], AxiosError<AxiosErrorResponse>> => {
  return useQuery({
    queryKey: [USER_ROLES_KEY],
    queryFn: getUserRoles,
    ...options,
  });
};


import { useQuery } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';

import { Inventory } from './index';

export const MASTER_INVENTORY_ITEM_KEY = 'MASTER_INVENTORY_ITEM_KEY';

export const getMasterInventoryItem = (id: string | unknown): Promise<Inventory> => {
  return axios.get(`/inventory/${id}`);
};

type QueryFnType = typeof getMasterInventoryItem;

type UseMasterInventoryItemOptions = {
  id: string | unknown;
  config?: QueryConfig<QueryFnType>;
};

export const useMasterInventoryItem = ({ id, config }: UseMasterInventoryItemOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [MASTER_INVENTORY_ITEM_KEY, String(id)],
    queryFn: () => getMasterInventoryItem(id),
  });
};

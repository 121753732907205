import { useQuery } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';

export const GET_INVENTORY_HISTORY = 'GET_INVENTORY_HISTORY';

type QueryFnType = typeof getInventoryHistory;

type UseInventoryHistoryOptions = {
  id: string;
  config?: QueryConfig<QueryFnType>;
};

export const getInventoryHistory = (inventoryId: string): Promise<any> => {
  return axios.get(`/inventory/history/${inventoryId}`);
};

export const useInventoryHistory = ({ id, config }: UseInventoryHistoryOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: [GET_INVENTORY_HISTORY, id],
    queryFn: () => getInventoryHistory(id),
  });
};

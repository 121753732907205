import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react'

import { Order, deliveryMode, orderType } from '../../api/order';
import { useAssignInvToPickupOrder } from '../../api/order/assign-inv-to-pickup-order';
import { useOrderItemDelete } from '../../api/order/delete-order-item';
import { formatDate } from '../../lib/format-date';
import { useNotificationStore } from '../../stores/notifications';
import { ScannerModal } from '../barcode-scanner/scanner-modal';
import { Line } from '../form/line';

import { OrderStatusButtons } from './order-status-buttons';

type Props = {
  order: Order;
}

const pickupMode: Record<string, string> = {
  LOCAL_PICKUP: 'Local Pickup',
  SHIPMENT_PICKUP: 'Shipment Pickup',
};

const OrderPickDetails: React.FC<Props> = ({ order }) => {
  const { addNotification } = useNotificationStore();

  const [cameraAsset, setCameraForAsset] = React.useState(false);

  const assignInvToPickupOrder = useAssignInvToPickupOrder();
  const deleteItemMutate = useOrderItemDelete();

  const assignInventoryItemToPickupOrder = async (code: string) => {
    if (!code) return;
    try {
      await assignInvToPickupOrder.mutateAsync({ code, orderId: order.id, customerId: order.customerId });
      addNotification({
        type: 'success',
        title: `Success`,
        message: `Assigned to Order PM-${order.id}`,
      });
      return code;
    } catch (error) {
      addNotification({
        type: 'error',
        title: 'Error',
        message: `Unable to assign inventory item ${code} to Order PM-${order.id}.`,
      });
    }
  };

  const handleDeleteOrderItem = async (itemId: number, orderId: number) => {
    await deleteItemMutate.mutateAsync({
      itemId: itemId,
      orderId: orderId
    });
  }

  return (
    <>
      {cameraAsset && (
        <ScannerModal onResult={assignInventoryItemToPickupOrder} onClose={() => setCameraForAsset(!cameraAsset)} />
      )}

      <div className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md space-y-2 border border-1 border-gray-[rgba(224,224,224,0.40)] mt-4 mb-2 w-full">
        <div className="flex">
          <span className="font-bold">Order Number:</span>
          <span className="ml-2">PM-{order.id}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Type:</span>
          <span className="ml-2">
            {order.orderType === orderType.DELIVERY ? order.deliveryMode.replaceAll('_', ' ') : order.orderType}
          </span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Status:</span>
          <span className="ml-2">{order.status.replace("_", " ")}</span>
        </div>

        {pickupMode?.[order.pickupMode] && (
          <div className="flex">
            <span className="font-bold">Shipping Mode:</span>
            <span className="ml-2">{pickupMode[order.pickupMode]}</span>
          </div>
        )}

        {order.trackingNumber && (
          <div className="flex">
            <span className="font-bold">Tracking Number:</span>
            <span className="ml-2">{order.trackingNumber}</span>
          </div>
        )}

        {order.shippingCompany &&
          <div className="flex">
            <span className="font-bold">Shipping Company:</span>
            <span className="ml-2">{order.shippingCompany}</span>
          </div>
        }

        {order.pickupDate &&
          <div className="flex">
            <span className="font-bold">Pickup Date:</span>
            <span className="ml-2">{formatDate(order.pickupDate)}</span>
          </div>
        }

        <div className="flex">
          <span className="font-bold">Order Date:</span>
          <span className="ml-2">
            {formatDate(order.orderDate)}
          </span>
        </div>

        <div className="flex">
          <span className="font-bold">Customer:</span>
          <span className="ml-2">{order.customer.title}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Pickup Address:</span>
          <span className="ml-2">{order.shipTo}</span>
        </div>

        {order.orderType === orderType.DELIVERY ? (
          <div className="flex">
            <span className="font-bold">
              {order.deliveryMode === deliveryMode.LOCAL_DELIVERY ? 'Deliver To' : 'Ship To'}:
            </span>
            <span className="ml-2">{order.shipTo}</span>
          </div>
        ) : null}

        <Line />

        <div className="flex">
          <span className="font-bold">Caller Name:</span>
          <span className="ml-2">{order.callerName}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Caller Phone Number:</span>
          <span className="ml-2">{order.callerPhoneNumber}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Caller E-mail:</span>
          <span className="ml-2">{order.callerEmail}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Person Assigned:</span>
          <span className="ml-2">{order.user ? order.user.name : 'None'}</span>
        </div>

        <Line />

        {order.isReceiverName ? (
          <div className="flex">
            <span className="font-bold">Receiver:</span>
            <span className="ml-2">Same as caller</span>
          </div>
        ) : (
          <>
            <div className="flex">
              <span className="font-bold">Receiver Name:</span>
              <span className="ml-2">{order.receiverName}</span>
            </div>
            <div className="flex">
              <span className="font-bold">Receiver Phone Number:</span>
              <span className="ml-2">{order.receiverPhoneNumber}</span>
            </div>
            <div className="flex">
              <span className="font-bold">Receiver Email:</span>
              <span className="ml-2">{order.receiverEmail}</span>
            </div>
          </>
        )}

        <Line />

        <div className="flex">
          <span className="font-bold">Customer Notes:</span>
          <span className="ml-2">{order.customer.note ? order.customer.note : 'None'}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Order Notes:</span>
          <span className="ml-2">{order.description ? order.description : 'None'}</span>
        </div>

        <div className="flex">
          <span className="font-bold">Driver Notes:</span>
          <span className="ml-2">{order.driverNotes ? order.driverNotes : 'None'}</span>
        </div>

      </div>

      <div className="flex flex-wrap">
        <>
          {order.orderInventories
            .sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            })
            .map((oi, key) => (
              <React.Fragment key={key}>
                <div className={`flex flex-row space-x-8 bg-[rgba(224,224,224,0.37)] p-4 rounded-md border border-1 ${oi.flaggedForAdmin ? 'border-red-500' : 'border-gray-[rgba(224,224,224,0.40)]'} mr-2 mb-2`}>
                  <div className='flex flex-col'>
                    {oi.inventory ? (
                      <>
                        <div>
                          <span className="font-bold">Title:</span>
                          <span className="ml-2">{oi.inventory.product.title}</span>
                        </div>
                        <div>
                          <span className="font-bold">Serial Number:</span>
                          <span className="ml-2">{oi.inventory.sn || 'N/A'}</span>
                        </div>
                        <div>
                          <span className="font-bold">Asset Number:</span>
                          <span className="ml-2">{oi.inventory.assetId || 'N/A'}</span>
                        </div>
                        <div>
                          <span className="font-bold">PM Date:</span>
                          <span className="ml-2">{oi.inventory.lastPm || 'N/A'}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className="font-bold">Scanned Equipment Not Found In Inventory</span>
                        </div>
                        <div>
                          <span className="font-bold">Scanned ID:</span>
                          <span className="ml-2">{oi.scannedId || 'N/A'}</span>
                        </div>
                      </>
                    )}
                    <div>
                      <span className="font-bold">Accessories:</span>
                      <span className="ml-2">{'N/A'}</span>
                    </div>
                  </div>
                  {order.status == 'PENDING' ? (
                    <div>
                      <button type="button" onClick={() => handleDeleteOrderItem(oi.id, oi.orderId)}>
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            ))}
        </>
      </div>
      <div className="w-full text-center">
        <OrderStatusButtons
          order={order}
          setCamera={setCameraForAsset}
          assignInventoryItemToOrder={assignInventoryItemToPickupOrder}
        />
      </div>
    </>
  )
}

export default OrderPickDetails

import { useParams, useSearchParams } from 'react-router-dom';

import { useInvoiceGroups } from '../../../api/invoicing/get-customer-invoice-groups';
import { PageContainer } from '../../../components/common/container/page.container';
import { LinkButton } from '../../../components/common/title-action/link-button';
import { TitleActions } from '../../../components/common/title-action/title-actions';
import { Spinner } from '../../../components/spinner';
import { invoiceGroupsColumns } from '../../../components/table/data-cols';
import { Table } from '../../../components/table/table';
import { TablePagination } from '../../../components/table/table-pagination';
import { useRoles } from '../../../provider/roles-provider';

export const CustomerInvoiceGroups = () => {
  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));
  const [searchParams] = useSearchParams();
  const { customerId } = useParams();

  const page = searchParams.get('page') || '1';
  const perPage = searchParams.get('perPage') || '10';

  const invoiceGroups = useInvoiceGroups({
    args: { customerId, page, perPage },
    config: {
      select: (data) => {
        return {
          ...data,
          items: data.items.map((i) => ({
            id: i.id,
            titleLink: {
              title: i.name,
              link: `/customer/${customerId}/group/${i.id}`,
            },
          })),
        }
      }
    }
  });

  if (!invoiceGroups.isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <TitleActions subtitle title="Invoice Groups">
          <LinkButton
              icon="/media/plus-icon.svg"
              title="Add Invoice Group"
              link={`/customer/${customerId}/group/create`}
              className={'sm:flex'}
            />
          </TitleActions>
          <div className='flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0'>
            {invoiceGroups ? (
              <>
                <Table
                  data={invoiceGroups.data}
                  columns={invoiceGroupsColumns}
                  checkbox={true}
                  textLinks={true}
                  view={false}
                  canUserEdit={isUserOrAdmin}
                />
                <TablePagination
                  count={Number(invoiceGroups.data.count)}
                  page={Number(invoiceGroups.data.page)}
                  perPage={Number(invoiceGroups.data.perPage)}
                />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

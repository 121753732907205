import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

import { Order } from '../../api/order';
import { useOrderItemDelete } from '../../api/order/delete-order-item';

type OrderInventoriesCardsProps = {
  order: Order;
};

export const OrderInventoriesCards = ({ order }: OrderInventoriesCardsProps) => {
  const deleteItemMutate = useOrderItemDelete();

  const handleDeleteOrderItem = async (itemId: number, orderId: number) => {
    await deleteItemMutate.mutateAsync({
      itemId: itemId,
      orderId: orderId,
    });
  };
  return (
    <div className="flex flex-wrap">
      {order.orderInventories
        .sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
        .map((oi, key) => (
          <Fragment key={key}>
            <div className="flex flex-row space-x-8 bg-[rgba(224,224,224,0.37)] p-4 rounded-md border border-1 border-gray-[rgba(224,224,224,0.40)] mr-2 mb-2">
              <div>
                <span>{key + 1}</span>
              </div>
              <div className="flex flex-col">
                <div>
                  <span className="font-bold">Title:</span>
                  <span className="ml-2">{oi.inventory.product.title}</span>
                </div>
                <div>
                  <span className="font-bold">Serial Number:</span>
                  <span className="ml-2">{oi.inventory.sn}</span>
                </div>
                <div>
                  <span className="font-bold">Asset Number:</span>
                  <span className="ml-2">{oi.inventory.assetId}</span>
                </div>
                <div>
                  <span className="font-bold">PM Date:</span>
                  <span className="ml-2">{oi.inventory.lastPm || 'N/A'}</span>
                </div>
                <div>
                  <span className="font-bold">Accessories:</span>
                  <span className="ml-2">{'N/A'}</span>
                </div>
              </div>
              {order.status == 'PENDING' ? (
                <div>
                  <button type="button" onClick={() => handleDeleteOrderItem(oi.id, oi.orderId)}>
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              ) : null}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Import, TableImport } from '../../api/import';
import { useImports } from '../../api/import/get-imports';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { ImportTable } from '../../components/import/import-table';
import { Spinner } from '../../components/spinner';
import { importHistoryTableColumns } from '../../components/table/data-cols';
import { TablePagination } from '../../components/table/table-pagination';
import { useRoles } from '../../provider/roles-provider';

export const ImportHistoryPage: FC = () => {
  const [searchParams] = useSearchParams();
  const importHistory = useImports(searchParams);

  const mapItems = (items: Import[]): TableImport[] => {
    return items.map(
      (i): TableImport => ({
        fileName: i.fileName,
        type: i.type,
        count: i.count,
        'data-object': i.failedRows,
      })
    );
  };

  const { roles } = useRoles();
  const isUserOrAdmin =
    roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  if (process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED !== 'true') {
    return (
      <div className="text-center text-red-500">
        <h1>This feature is not currently enabled</h1>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0">
        {isUserOrAdmin ? (
          <>
            {process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED === 'true' ? (
              <TitleActions title="Import History"></TitleActions>
            ) : null}

            {importHistory.isFetched && importHistory.data ? (
              <>
                <ImportTable
                  data={{
                    ...importHistory.data,
                    items: mapItems(importHistory.data.items),
                  }}
                  columns={importHistoryTableColumns}
                  checkbox={false}
                />
                <TablePagination
                  count={Number(importHistory.data.count)}
                  page={Number(importHistory.data.page)}
                  perPage={Number(importHistory.data.perPage)}
                />
              </>
            ) : (
              <Spinner />
            )}
          </>
        ) : (
          <div className="text-center text-red-500">
            <h1>You do not have the appropriate permissions to view this page</h1>
          </div>
        )}
      </div>
    </>
  );
};

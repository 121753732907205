import { useQueryClient } from '@tanstack/react-query';
import { Auth, Hub } from 'aws-amplify';
import React, { useEffect, useState, createContext, useContext } from 'react';

import { getUserRoles } from '../api/user/get-roles';
import '@aws-amplify/ui-react/styles.css';
import { Spinner } from '../components/spinner';
import { USER_ROLES_KEY } from '../lib/constants'

export type RolesType = {
  roles: string[];
};

type RolesContextType = {
  roles: RolesType | null;
  setRoles: React.Dispatch<React.SetStateAction<RolesType | null>>;
};

const RolesContext = createContext<RolesContextType | null>(null);

export const useRoles = () => {
  const context = useContext(RolesContext);
  if (!context) {
    throw new Error("useRoles must be used within a RolesProvider");
  }
  return context;
};

export const RolesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [roles, setRoles] = useState<RolesType | null>(null);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const checkUser = async () => {
    try {
      setLoadingRoles(true);
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        await queryClient.prefetchQuery([USER_ROLES_KEY], getUserRoles);
        const rolesData = queryClient.getQueryData<RolesType | null>([USER_ROLES_KEY]);
        if (rolesData) {
          setRoles(rolesData);
        }
      }
    } catch (error) {
      console.error('No authenticated user:', error);
    } finally {
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          checkUser();
          break;
        case 'signOut':
          setRoles(null);
          break;
        default:
          break;
      }
    };

    const removeListener = Hub.listen('auth', listener);

    return () => {
      removeListener(); // Invoke the function to remove the listener
    };
  }, [queryClient]);

  return (
    <RolesContext.Provider value={{ roles, setRoles }}>
      {loadingRoles ? <Spinner /> : children}
    </RolesContext.Provider>
  );
};

import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PAGE, QUERY_PER_PAGE } from '../../lib/constants';

const pages = ['10', '20', '30', '40', '50'];
const min = pages[0];

export const ItemsPerPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState<string>(min);

  const onPerPage = async (count: string) => {
    setSelected(count);

    searchParams.delete(QUERY_PAGE);

    if (count === min) {
      searchParams.delete(QUERY_PER_PAGE);
      return setSearchParams(searchParams);
    }

    searchParams.set(QUERY_PER_PAGE, count);

    return setSearchParams(searchParams);
  };

  useEffect(() => {
    const perPage = searchParams.get(QUERY_PER_PAGE);
    setSelected(perPage && pages.includes(perPage) ? perPage : min);
  }, []);

  return (
    <Listbox value={selected} onChange={(e: string) => onPerPage(e)}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default  bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">{selected}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Listbox.Options className="absolute mb-1 bottom-full max-h-60 w-[80px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {pages.map((page, key) => (
            <Listbox.Option
              key={key}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                }`
              }
              value={page}
            >
              {({ selected }) => (
                <>
                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{page}</span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { useNavigate } from 'react-router-dom';

import { NavigationButton } from './navigation-button';

export type NavigationBackButtonProps = {
  title?: string;
  url?: any;
};

export const NavigationBackButton = ({ title = 'Back', url = -1 }: NavigationBackButtonProps) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <button onClick={() => navigate(url)}>
      <NavigationButton title={title} url="#">
        <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
      </NavigationButton>
    </button>
  );
};

type TablePopoverCheckboxProps = {
  id?: number;
  onChange: (e: any) => void;
  checked: boolean;
};

export const TablePopoverCheckbox = ({ id, onChange, checked }: TablePopoverCheckboxProps) => {
  return (
    <input
      type="checkbox"
      id={`${id}`}
      onChange={onChange}
      className="cursor-pointer w-[18px] h-[18px] border-2 border-[#666666] rounded-sm focus:outline-none"
      checked={checked}
    />
  );
};

import React from 'react'
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';

import { useOrderUpdate } from '../../api/order/update-order';
import { ButtonFilled } from '../common/button/button-filled';
import { LabelInput } from '../form/labelInput';


type FormValues = {
    trackingNumber: string;
    isLabelSent: boolean;
}

type Props = {
    orderId: number;
}

const OrderShippingDeliveryForm: React.FC<Props> = ({ orderId }) => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<FormValues>();
  const updateOrder = useOrderUpdate();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await updateOrder.mutateAsync({
        id: orderId,
        trackingNumber: data.trackingNumber,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-left flex flex-col space-y-3 w-full lg:w-1/2">
            <div>
                <LabelInput title={"Tracking Number"} attributes={{
                    ...register('trackingNumber', {
                        disabled: isSubmitting,
                        required: "Tracking Number is required",
                        minLength: {
                            value: 5,
                            message: "Tracking Number must be at least 5 characters long"
                        },
                    })
                }}/>
                {errors.trackingNumber && <span className="text-red-500 text-sm">{errors.trackingNumber.message}</span>}
            </div>
        </div>
        <ButtonFilled text="Submit" isLoading={isSubmitting} />
    </form>
  )
}

export default OrderShippingDeliveryForm

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import {
  INVENTORY_STATUS_KEY,
  MASTER_INVENTORY_ITEM_STATUS_CHANGE_KEY,
  MASTER_INVENTORY_BULK_ITEM_STATUS_CHANGE_KEY,
  MASTER_INVENTORY_KEY,
} from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { MASTER_INVENTORY_ITEM_KEY } from './get-inventory-item';

import { Inventory } from './index';

export type SetInventoryItemStatusDto = {
  id: number;
  statusId: number;
};

export type SetBulkInventoryItemStatusDto = {
  ids: number[];
  statusId: number;
};

export const setInventoryItemStatus = ({ id, statusId }: SetInventoryItemStatusDto): Promise<Inventory> => {
  return axios.post(`/inventory/${id}/setStatus`, { statusId });
};

export const useSetInventoryItemStatus = () => {
  return useMutation({
    onError: (_: any, __: any, context: any) => {
      if (context?.previousDiscussions) {
        queryClient.setQueryData([MASTER_INVENTORY_ITEM_KEY], context.previousDiscussions);
      }
    },
    onSuccess: async (data: Inventory) => {
      console.log('go invalidate');
      await queryClient.invalidateQueries([MASTER_INVENTORY_ITEM_KEY, String(data.id)]);
      await queryClient.invalidateQueries([INVENTORY_STATUS_KEY]);
    },
    mutationFn: setInventoryItemStatus,
    mutationKey: [MASTER_INVENTORY_ITEM_STATUS_CHANGE_KEY],
  });
};

export const setBulkInventoryItemStatus = ({ ids, statusId }: SetBulkInventoryItemStatusDto): Promise<Inventory[]> => {
  return axios.post('/inventory/bulk/setStatus', { ids, statusId });
};

export const useSetBulkInventoryItemStatus = (clearBulkSelect: () => void) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onError: () => {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Failed to bulk update status of inventory items.',
      });
    },
    onSuccess: async (data: any) => {
      await queryClient.invalidateQueries([MASTER_INVENTORY_KEY]);
      clearBulkSelect();
      addNotification({
        type: 'success',
        title: 'Success',
        message: `Successfully bulk updated statuses of ${data.length} inventory items.`,
      });
    },
    mutationFn: setBulkInventoryItemStatus,
    mutationKey: [MASTER_INVENTORY_BULK_ITEM_STATUS_CHANGE_KEY],
  });
};

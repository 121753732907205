import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { ORDERS_KEY } from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { AxiosErrorResponse } from '../../lib/types';

type AssignProps = {
  orderId: number;
  customerId: number;
  code: string;
};

export const assign = ({ customerId, orderId, code }: AssignProps): Promise<void> => {
  return axios.post(`/order/assign/${orderId}`, { code, customerId });
};

export const useAssignInvToOrder = () => {
  return useMutation<void, AxiosError<AxiosErrorResponse>, any, any>({
    mutationFn: assign,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });
    },
  });
};

import React from 'react';

import { Inventory } from '../../../api/inventory';

export type ProductInfoProps = {
  inventory: Inventory;
};

export const InventoryInfo = ({ inventory }: ProductInfoProps) => {
  const items = [
    {
      title: 'Location',
      value: inventory.location.title,
    },
    {
      title: 'Asset',
      value: inventory.assetId || '',
    },
    {
      title: 'MFR SN',
      value: inventory.sn,
    },
    {
      title: 'Shelf',
      value: inventory.shelf,
    },
    {
      title: 'Bin',
      value: inventory.bin,
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-[100%] py-2">
      <div className="flex p-2 md:p-0">
        <img src="/media/product.jpg" alt="product" className="h-[100px]" />
      </div>

      {/**  PRODUCT INFO **/}
      <div className="flex p-3 bg-[#EBEBEB] gap-3 justify-between md:justify-center rounded-lg w-full md:w-auto md:py-4 md:px-5 md:gap-6">
        {items.map(({ title, value }: { title: string; value: string | number }, k: number) => (
          <div className="flex flex-col text-xs md:text-sm gap-1" key={k}>
            <p className="font-medium">{title}</p>
            <p>{value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

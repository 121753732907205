import { CameraIcon, MagnifyingGlassIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';

export const MobileBar = () => {
  return (
    <>
      {/*<div className="h-[80px] md:hidden"></div>*/}
      <div className="fixed bottom-0 w-full h-[80px] bg-white border-t border-[#EBEBEB] md:hidden">
        <div className="flex pt-[18px] px-[30px] justify-between items-center">
          <ShoppingBagIcon className="h-[22px]" />
          <MagnifyingGlassIcon className="h-[22px] opacity-30" />
          <NavLink to={'/scanner'}>
            <CameraIcon className="h-[22px] opacity-30" />
          </NavLink>
        </div>
      </div>
    </>
  );
};

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { InvoiceGroup } from '../../../api/invoicing';
import { useInvoiceGroupCreate } from '../../../api/invoicing/create-invoice-group';
import { PageContainer } from '../../../components/common/container/page.container';
import { TitleActions } from '../../../components/common/title-action/title-actions';
import { InvoiceGroupCreateForm } from '../../../components/invoicing/form/invoice-group-create-form';
import { useRoles } from '../../../provider/roles-provider';

export const InvoiceGroupCreate = () => {
  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));
  const { customerId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = useForm<InvoiceGroup>();

  const navigate = useNavigate();
  const createInvoiceGroup = useInvoiceGroupCreate();

  const onSubmit = async (values: InvoiceGroup) => {
    try {
      await createInvoiceGroup.mutateAsync({ group: values, customerId: customerId });
      navigate(`/customer/${customerId}`);
    } catch {
      // allow axios interceptor to handle notification with API error response
      return;
    }

  };


  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <TitleActions title='Create Invoice Group' />
          <div className='w-[90%] bg-white my-4 overflow-x-auto'>
            <InvoiceGroupCreateForm
              setValue={setValue}
              onSubmit={handleSubmit(onSubmit)}
              register={register}
              isSubmitting={isSubmitting}
            />
          </div>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

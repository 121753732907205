import { Notification, useNotificationStore } from '../../stores/notifications';

import { NotificationItem } from './notification';

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore();

  return (
    <div
      aria-live="assertive"
      className="z-[1000] flex flex-col fixed inset-0 space-y-4 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      {notifications.map((notification: Notification) => (
        <NotificationItem key={notification.id} notification={notification} onDismiss={dismissNotification} />
      ))}
    </div>
  );
};

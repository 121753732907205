import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { dashboardTableColumns } from '../../components/table/data-cols';
import { axios } from '../../lib/axios';
import {
  QUERY_AT_IDS,
  QUERY_LOCATION_IDS,
  QUERY_OWNER_IDS,
  QUERY_PRODUCT_IDS,
  QUERY_SEARCH,
  QUERY_STATUS_IDS,
  QUERY_TYPE_IDS,
} from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

export const EXPORT_INVENTORY_KEY = 'EXPORT_INVENTORY_KEY';

export const getInventoryCSV = async (queryParams: URLSearchParams): Promise<Blob | null> => {
  const params = {
    search: queryParams.get(QUERY_SEARCH) || null,
    productIds: queryParams.getAll(QUERY_PRODUCT_IDS) || null,
    statusIds: queryParams.getAll(QUERY_STATUS_IDS) || null,
    typeIds: queryParams.getAll(QUERY_TYPE_IDS) || null,
    ownerIds: queryParams.getAll(QUERY_OWNER_IDS) || null,
    locationIds: queryParams.getAll(QUERY_LOCATION_IDS) || null,
    atIds: queryParams.getAll(QUERY_AT_IDS) || null,
    tableHeaders: dashboardTableColumns.map((col) => col.title),
  };

  try {
    // Fetch the CSV as a blob
    const response = await axios.get<Blob>('/inventory/master/csv', {
      params,
      responseType: 'blob',
    });
    // Type check for createObjectURL()
    if (response instanceof Blob) {
      const blobUrl = window.URL.createObjectURL(response);
      // Build an <a> tag, add to DOM
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'Master-Inventory-export.csv';
      document.body.appendChild(link);
      // Click the tag to download file
      link.click();
      document.body.removeChild(link);

      // Free up the used object URL
      window.URL.revokeObjectURL(blobUrl);
      return response;
    }
  } catch (err) {
    console.error('Error downloading the file', err);
  }
  return null;
};

export const useExportInventory = (args: URLSearchParams, enabled = false) => {
  args.sort();
  return useQuery<Blob | null, AxiosError<AxiosErrorResponse>>({
    queryKey: [EXPORT_INVENTORY_KEY, args.toString()],
    queryFn: async () => {
      const response = await getInventoryCSV(args);
      if (!response) {
        // Ensure we return something other than undefined.
        return null;
      }
      return response;
    },
    keepPreviousData: false,
    enabled,
  });
};

import { ReactNode } from 'react';

type TableTitleProps = {
  title: string | undefined;
  subtitle?: boolean;
  children?: ReactNode;
};

export const TitleActions = ({ title, children, subtitle = false }: TableTitleProps) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-full box-border py-4">
      <p className={`border-[#252c32ff] ${subtitle ? 'text-2xl md:text-3xl' : 'text-3xl md:text-4xl'} leading-[133%] font-inter font-[700] tracking-[-0.79px]`}>
        {title}
      </p>
      {children ? <div className="flex gap-2 flex-row pt-4 md:pt-0">{children}</div> : null}
    </div>
  );
};

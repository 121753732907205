import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { CustomerPricing } from '../../api/customer';
import { useCustomer } from '../../api/customer/get-customer';
import { useCustomerPricing } from '../../api/customer/get-customer-pricing';
import { useCustomerPricingEdit } from '../../api/customer/update-customer-pricing';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { CustomerPricingForm } from '../../components/customer/form/customer-pricing-form';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

export const CustomerPricingEditPage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const { customerId, pricingId } = useParams();
  const customer = useCustomer(customerId);

  const { isSuccess, data: customerPricing, isLoading, isFetching } = useCustomerPricing({ customerId, pricingId });

  const editCustomerPricing = useCustomerPricingEdit();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<CustomerPricing>();

  const onSubmit = async (values: Partial<CustomerPricing>) => {
    try {
      if (!customerId || !pricingId) return;

      const customerPricingDto = {
        ...values,
        customerId: parseInt(customerId),
        id: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        product: undefined,
      };

      await editCustomerPricing.mutateAsync({
        customerId: customerId,
        pricingId,
        data: customerPricingDto,
      });
      navigate(pathname.split('/').slice(0, 4).join('/'));
    } catch {
      // notifications handled by axios interceptor
      return;
    }
  };

  useEffect(() => {
    reset({
      ...customerPricing,
      startDate: customerPricing ? customerPricing?.startDate : undefined,
      endDate: customerPricing ? customerPricing?.endDate : undefined,
    });
  }, [customerPricing]);

  if (!isSuccess || !customer.isSuccess || isLoading || isFetching) return <Spinner />;

  return (
    <div className="flex flex-col justify-start items-start w-[100%] h-screen p-4 box-border overflow-x-auto pb-[80px] md:pb-0">
      {isAdmin ? (
        <>
          <NavigationBackButton url={-1} />

          <TitleActions title={customer.data.title} />

          <CustomerPricingForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            setValue={setValue}
            isSubmitting={isSubmitting}
            initialData={{ equipmentId: customerPricing.product.id, ...customerPricing }}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </div>
  );
};

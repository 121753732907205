import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMERS_KEY, QUERY_PAGE, QUERY_PER_PAGE, QUERY_SEARCH } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { Customer } from './index';

export const getCustomers = (queryParams: URLSearchParams): Promise<ResourceResponse<Customer>> => {
  const params = {
    page: queryParams.get(QUERY_PAGE) || 1,
    perPage: queryParams.get(QUERY_PER_PAGE) || 10,
    search: queryParams.get(QUERY_SEARCH) || null,
  };

  return axios.get('/customer', { params });
};

export const useCustomers = (args: URLSearchParams) => {
  args.sort();
  return useQuery<ResourceResponse<Customer>, AxiosError<AxiosErrorResponse>>({
    queryKey: [CUSTOMERS_KEY, args.toString()],
    queryFn: () => getCustomers(args),
    keepPreviousData: true,
  });
};

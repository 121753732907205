import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useCustomers } from '../../api/customer/get-customers';
import { useEquipments } from '../../api/equipment/get-equipments';
import { InventoryCreate } from '../../api/inventory';
import { useCreateInventory } from '../../api/inventory/create-inventory';
import { useLocation } from '../../api/location/get-location';
import { useOwner } from '../../api/owner/get-owner';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { InventoryForm } from '../../components/inventory/form/inventory-form';
import { NavigationButton } from '../../components/navigation/navigation-button';
import { useRoles } from '../../provider/roles-provider';

export const InventoryCreatePage = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<InventoryCreate>({});

  const navigate = useNavigate();

  const equipments = useEquipments({ page: '1', perPage: '100' });
  const owners = useOwner({ page: '1', perPage: '100' });
  const locations = useLocation({ page: '1', perPage: '100' });
  const customers = useCustomers(new URLSearchParams({ page: '1', perPage: '100' }));

  const inventoryCreate = useCreateInventory();

  const onSubmit = async (values: InventoryCreate) => {
    try {
      values.snIds = values.snArray.map((s) => s.id);

      await inventoryCreate.mutateAsync(values);
  
      await navigate(`/inventory`);
    } catch {
      // notification handled by axios interceptor
      return;
    }

  };

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <button onClick={() => navigate('/inventory')}>
            <NavigationButton title="Inventory" url="#">
              <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
            </NavigationButton>
          </button>

          <TitleActions title="Create inventory product" />

          <InventoryForm
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            setValue={setValue}
            equipments={equipments}
            owners={owners}
            locations={locations}
            isSubmitting={isSubmitting}
            customers={customers}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

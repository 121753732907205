import { Dispatch } from 'react';

import { TableContainer } from '../common/table/table-container';
import { TableDataCell } from '../common/table/table-data-cell';
import { TableDataRow } from '../common/table/table-data-row';
import { Actions } from '../form/actions';

import { TableColumnProp } from './data-cols';
import TablePopover from './popover/table-popover';
import { TableCheckbox } from './table-checkbox';
import { TableItem } from './table-item';
import { TableRows } from './table-rows';

type TableProps<T> = {
  data: {
    count: number;
    items: T[];
    page: number;
    perPage: number;
  };
  columns: TableColumnProp[];
  checkbox?: boolean;
  textLinks?: boolean;
  view?: boolean;
  editOnly?: boolean;
  canUserEdit?: boolean;
  onDelete?: (item: T) => void;
  actions?: boolean;
  isCheckAll?: boolean;
  setIsCheckAll?: Dispatch<boolean>;
  checkedRowIds?: number[];
  setCheckedRowIds?: Dispatch<number[]>;
};

export const Table = <T extends Record<string, unknown>>({
  data,
  columns,
  onDelete,
  checkbox = true,
  view = true,
  textLinks = true,
  editOnly = false,
  canUserEdit = false,
  actions = true,
  isCheckAll,
  setIsCheckAll,
  checkedRowIds,
  setCheckedRowIds,
}: TableProps<T>) => {
  const { items } = data;

  const handleSelectAll = () => {
    if (checkbox && isCheckAll !== undefined && setIsCheckAll && checkedRowIds && setCheckedRowIds) {
      if (isCheckAll) {
        setCheckedRowIds([]);
      } else {
        setCheckedRowIds(items.map((i) => i.id as number));
      }
      setIsCheckAll(!isCheckAll);
    }
  };

  const handleSelect = (e: any) => {
    if (checkbox && checkedRowIds && setCheckedRowIds) {
      const { id, checked } = e.target;
      if (checked) {
        setCheckedRowIds([...checkedRowIds, Number(id)]);
      } else {
        setCheckedRowIds(checkedRowIds.filter((i) => i !== Number(id)));
      }
    }
  };

  return (
    <div className="w-[100%] h-full">
      <TableContainer className={'h-full'}>
        <>
          <TableDataRow className={'h-16'}>
            {checkbox && isCheckAll !== undefined ? (
              <TableDataCell className="p-3 sticky left-0 z-10 bg-white">
                <TableCheckbox setChecked={handleSelectAll} checked={isCheckAll} />
              </TableDataCell>
            ) : null}

            {columns.map((c: TableColumnProp, i: number) => (
              <TableDataCell className="pl-[22px]" key={i}>
                {c.isDateFilter ? (
                  <TablePopover
                    title={c.title}
                    sortable={c.sortable}
                    isDateFilter={c.isDateFilter}
                    startDateKey={`${c.key}Start`}
                    endDateKey={`${c.key}End`}
                  />
                ) : (
                  <TablePopover title={c.title} sortable={c.sortable} itemKey={c.key} />
                )}
              </TableDataCell>
            ))}
          </TableDataRow>

          {items.map((item: any, key: number) => (
            <TableDataRow className={'h-16'} key={key}>
              {checkbox && checkedRowIds ? (
                <TableDataCell className="p-3 sticky left-0 z-10 bg-white">
                  <TableCheckbox id={item.id} setChecked={handleSelect} checked={checkedRowIds.includes(item.id)} />
                </TableDataCell>
              ) : null}

              {textLinks && item.titleLink ? (
                <TableDataCell className="pl-[22px] hover:underline">
                  <TableItem text={item.titleLink?.title} linkString={item.titleLink?.link ?? '#'} />
                </TableDataCell>
              ) : null}

              <TableRows item={item} />

              {view ? (
                <TableDataCell className="pl-[22px]">
                  <TableItem text="View" outlineKey="view" productId={item.links?.view ? item.links.view : item.id} />
                </TableDataCell>
              ) : null}

              {actions ? (
                <TableDataCell className="pl-[22px]">
                  {editOnly ? (
                    <TableItem text="Edit" outlineKey="edit" productId={item.id} />
                  ) : (
                    <Actions id={item.id} disabled={!canUserEdit} onDelete={() => onDelete?.(item)} />
                  )}
                </TableDataCell>
              ) : null}
            </TableDataRow>
          ))}
        </>
      </TableContainer>
    </div>
  );
};

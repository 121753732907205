import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CATEGORY_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { Category } from '.';

export const getCategories = (): Promise<ResourceResponse<Category>> => {
  return axios.get('/category', { params: {page: '1', perPage: '10' } });
};

export const useCategories = () => {
  return useQuery<ResourceResponse<Category>, AxiosError<AxiosErrorResponse>>({
    queryKey: [CATEGORY_KEY],
    queryFn: () => getCategories(),
    keepPreviousData: true,
  });
};

export type TextAreaProps = {
  attributes: any;
  placeholder?: string;
  className?: string;
};

export const TextArea = ({ attributes, className, placeholder }: TextAreaProps) => {
  return (
    <textarea
      {...attributes}
      className={className ? className : 'border border-gray-300 rounded-md p-2 text-md'}
      placeholder={placeholder}
    />
  );
};

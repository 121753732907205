import { Disclosure } from '@headlessui/react';
import { NavLink } from 'react-router-dom';

import { MenuNestedItem } from './menu-nested-item';
import { MessageCount } from './message-count';

export const ButtonDisc = ({ isActive, item }: any) => {
  return (
    <Disclosure.Button
      className={`flex items-center justify-between w-full px-2 py-1 text-[14px] font-medium rounded-md focus:outline-none ${
        isActive ? 'bg-[#D7EDFF] text-[#0E73F6]' : ''
      }`}
    >
      {item.name ? <span>{item.name}</span> : <span>Item</span>}

      {item.messages ? <MessageCount count={item.messages} /> : null}
    </Disclosure.Button>
  );
};

export const MenuItem = ({ item }: any) => {
  const handleOnClick = (e: React.MouseEvent) => {
    if (item.disabled) {
      e.preventDefault();
    }
  };

  const handleOnHover = (e: React.MouseEvent) => {
    if (item.disabled) {
      (e.currentTarget as HTMLElement).title = 'You must be an Admin to view this page';
    }
  };

  return (
    <>
      {item.href ? (
        <NavLink
          to={item.href ? item.href : null}
          onClick={handleOnClick}
          onMouseOver={handleOnHover}
          className={({ isActive }) => {
            const activeClasses = isActive ? 'bg-[#D7EDFF] text-[#0E73F6]' : '';
            const textColor = item.disabled ? 'text-gray-400' : 'text-gray-default';
            return `${activeClasses} ${textColor}`;
          }}
        >
          {({ isActive }) => <ButtonDisc isActive={isActive} item={item} />}
        </NavLink>
      ) : (
        <ButtonDisc isActive={false} item={item} />
      )}

      <Disclosure.Panel>
        {item.children?.map((child: any, key: number) => (
          <MenuNestedItem child={child} key={key} />
        ))}
      </Disclosure.Panel>
    </>
  );
};

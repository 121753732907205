import { useState } from 'react';

import { useInvoiceZip } from '../../api/invoicing/get-invoices-zip';
import { PageContainer } from '../../components/common/container/page.container';
import Dropdown from '../../components/common/dropdown/dropdown';
import DropdownItem from '../../components/common/dropdown/dropdown-item';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { SubmitButton } from '../../components/form/submit-button';
import { useRoles } from '../../provider/roles-provider';

const InvoicingPage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const months = [
    'January',
    'Feburary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  const yearsSince2023: number[] = [];

  for (let year = 2023; year <= currentYear; year++) {
    yearsSince2023.push(year);
  }

  // Get the current month (1 = January, 12 = December)
  const currentMonthIndex = new Date().getMonth();
  const currentYearIndex = new Date().getFullYear() - 2023;
  // Initialize state with the current month
  const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);
  const [selectedYear, setSelectedYear] = useState(yearsSince2023[currentYearIndex]);

  const { refetch, isRefetching, isFetching } = useInvoiceZip(selectedMonth + 1, selectedYear);

  // Function to handle month change
  const handleMonthChange = (newIndex: number) => {
    // Ensure the new month is in the months array
    setSelectedMonth(newIndex);
  };
  // Function to handle year change
  const handleYearChange = (newYear: number) => {
    // Ensure the new month is in the months array
    if (yearsSince2023.includes(newYear)) {
      setSelectedYear(newYear);
    }
  };

  const handleGenerateClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await refetch();
  };

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <TitleActions title="Invoicing Reports" />
          <form
            className="flex flex-row gap-6 items-start justify-center p-4 bg-white shadow-md rounded-lg my-4"
            onSubmit={handleGenerateClick}
          >
            <div className="flex gap-4 w-full">
              <Dropdown title="Pick month" defaultValue={months[selectedMonth]}>
                {months.map((month, index) => (
                  <DropdownItem
                    buttonType="button"
                    title={month}
                    onClick={() => handleMonthChange(index)}
                    key={index}
                  />
                ))}
              </Dropdown>
              <Dropdown title="Year" defaultValue={selectedYear}>
                {yearsSince2023.map((year) => (
                  <DropdownItem buttonType="button" title={year} onClick={() => handleYearChange(year)} key={year} />
                ))}
              </Dropdown>
            </div>
            <SubmitButton
              submittingSpinner={true}
              isSubmitting={isRefetching || isFetching}
              submitText="Download Invoices"
            />
          </form>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

export default InvoicingPage;

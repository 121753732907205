import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { useNotificationStore } from '../../stores/notifications';

import { Customer } from './index';

type EditCustomerProps = {
  data: Omit<Customer, 'createdAt' | 'updatedAt' | 'market' | 'user'>;
  id: string | unknown;
};

export const editCustomer = ({ data, id }: EditCustomerProps): Promise<Customer> => {
  return axios.put(`/customer/${id}`, { ...data });
};

export const useCustomerEdit = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: async (data: Customer) => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: `Customer ${data.title} saved successfully`,
      });
    },
    mutationFn: editCustomer,
  });
};

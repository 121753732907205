import { useMutation } from "@tanstack/react-query";

import { axios } from "../../lib/axios";
import { ORDERS_KEY } from "../../lib/constants";
import { queryClient } from "../../lib/react-query";
import { useNotificationStore } from "../../stores/notifications";

import { Order } from ".";

export const closeOrder = (data: Partial<Order>): Promise<any> => {
  return axios.post(`/order/close/${data.id}`, {...data});
};

export const useOrderClose = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: "success",
        title: "Ok",
        message: "Order closed successfully"
      });
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });
    },
    mutationFn: closeOrder 
  });
};
import { NavLink } from 'react-router-dom';

type TableActionButtonProps = {
  icon?: string;
  className?: string;
  link?: string;
  title: string;
};

export const LinkButton = ({ icon, title, className, link }: TableActionButtonProps) => {
  return (
    <div
      className={`flex justify-start items-center h-full px-2 sm:px-3 py-1 border border-[#dde2e4ff] border-solid rounded-lg box-border  bg-[rgba(255,255,255,1)] ${className}`}
    >
      <NavLink className="flex items-center h-full w-full box-border gap-2" to={link ? link : '#'}>
        {icon ? <img src={icon} alt="Not Found" className="w-3 h-3 sm:w-4 sm:h-4" /> : null}
        <p className="border-[#252c32ff] text-xs sm:text-sm leading-[171%] font-inter font-[400] tracking-[-0.08px]">
          {title}
        </p>
      </NavLink>
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { useNotificationStore } from '../../stores/notifications';

import { Customer } from './index';

export const createCustomer = (data: Customer): Promise<Customer> => {
  return axios.post('/customer', { ...data });
};

export const useCustomerCreate = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Customer created successfully',
      });
    },
    mutationFn: createCustomer,
  });
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import {
  ORDERS_KEY,
  QUERY_CUSTOMER_IDS,
  QUERY_PAGE,
  QUERY_PER_PAGE,
  QUERY_SEARCH,
  QUERY_USER_IDS,
  QUERY_ORDER_TYPE,
  QUERY_ORDER_STATUS,
  QUERY_DELIVERY_MODE,
} from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { Order } from './index';

export const getOrders = (queryParams: URLSearchParams): Promise<ResourceResponse<Order>> => {
  const params = {
    page: queryParams.get(QUERY_PAGE) || 1,
    perPage: queryParams.get(QUERY_PER_PAGE) || 10,
    search: queryParams.get(QUERY_SEARCH) || null,
    customerIds: queryParams.getAll(QUERY_CUSTOMER_IDS) || null,
    userIds: queryParams.getAll(QUERY_USER_IDS) || null,
    orderType: queryParams.getAll(QUERY_ORDER_TYPE) || null,
    status: queryParams.getAll(QUERY_ORDER_STATUS) || null,
    deliveryMode: queryParams.getAll(QUERY_DELIVERY_MODE) || null,
    orderDateStart: queryParams.get('orderDateStart') || null,
    orderDateEnd: queryParams.get('orderDateEnd') || null,
    orderNeededDateStart: queryParams.get('orderNeededDateStart') || null,
    orderNeededDateEnd: queryParams.get('orderNeededDateEnd') || null,
  };

  return axios.get('/order', { params });
};

export const useOrders = (args: URLSearchParams) => {
  args.sort();
  return useQuery<ResourceResponse<Order>, AxiosError<AxiosErrorResponse>>({
    queryKey: [ORDERS_KEY, args.toString()],
    queryFn: () => getOrders(args),
    keepPreviousData: true,
  });
};

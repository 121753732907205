import { TableActiveRental } from '../../api/active-rental';
import { Inventory } from '../../api/inventory';
import { useMasterInventories } from '../../api/inventory/get-master-inventory';
import { Status } from '../../api/status';
import { useStatus } from '../../api/status/get-status';
import { ImportActiveRentalButton } from '../../components/active-rental/button/import-active-rental-button';
import { Searchbar } from '../../components/common/search/searchbar';
import { ActionButton } from '../../components/common/title-action/action-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { Spinner } from '../../components/spinner';
import { activeRentalViewTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';
import { TablePagination } from '../../components/table/table-pagination';
import { useRoles } from '../../provider/roles-provider';

export const ActiveRentalPage = () => {
  const statuses = useStatus({ page: '1', perPage: '100' });
  const activeRentalStatusId = statuses?.data?.items
    .find((e: Status) => e.title.toLowerCase() === 'active rental')
    ?.id.toString();
  const inventories = useMasterInventories(
    new URLSearchParams({ statusIds: activeRentalStatusId! }),
    statuses.isSuccess
  );

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  const mapItems = (items: Inventory[]): TableActiveRental[] => {
    return items.map(
      (i): TableActiveRental => ({
        id: i.id,
        productTitle: i.product.title,
        status: i.status.title,
        type: i.product.type.title,
        assetId: i.assetId || '',
        sn: i.sn,
        software: i.softwareVersion,
      })
    );
  };

  return (
    <div className="flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0">
      {isUserOrAdmin ? (
        <>
          <Searchbar />

          <TitleActions title="Active Rentals">
            <ActionButton icon="/media/export-table-icon.svg" title="Export Table" className={'sm:flex'} />
            {process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED === 'true' ? (
              <ImportActiveRentalButton title="Import CSV" className={'sm:flex'} />
            ) : null}
          </TitleActions>

          {inventories.isFetched && inventories.data ? (
            <>
              <Table
                data={{
                  ...inventories.data,
                  items: mapItems(inventories.data.items),
                }}
                columns={activeRentalViewTableColumns}
                checkbox={false}
                canUserEdit={isUserOrAdmin}
              />
              <TablePagination
                count={Number(inventories.data.count)}
                page={Number(inventories.data.page)}
                perPage={Number(inventories.data.perPage)}
              />
            </>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { EQUIPMENT_KEY } from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { Equipment } from './index';

export const createEquipment = (product: Equipment): Promise<Equipment> => {
  return axios.post('/product', { ...product });
};

export const useEquipmentCreate = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async (data: Equipment) => {
      await queryClient.refetchQueries([EQUIPMENT_KEY, String(data.id)]);
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Equipment created successfully',
      });
    },
    mutationFn: createEquipment,
  });
};

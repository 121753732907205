import { useEffect } from 'react';
import { Result, useZxing } from 'react-zxing';

export type BarcodeScannerProps = {
  onResult?: (result: Result) => void;
  onError?: (error: Error) => void;
};

export const BarcodeScanner = ({ onResult, onError = () => {} }: BarcodeScannerProps) => {
  const { ref, torch: {
    isAvailable,
    isOn,
    on,
    off,
  } } = useZxing({
    onResult,
    onError,
  });

  useEffect(() => {
    if (isAvailable && !isOn) {
      on();
    }

    return () => {
      if (isOn) {
        off();
      }
    };
  }, [isAvailable, on, off, isOn]);

  return (
    <div className="flex h-[90vh] items-center">
      {/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
      <video ref={ref} className="h-[100%]" />
    </div>
  );
};

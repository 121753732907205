import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { AxiosErrorResponse } from '../../lib/types';

export const FIND_BY_CODE_ITEM = 'FIND_BY_CODE_ITEM';

export const getInventoryItemIdByCode = (code: string): Promise<{ id: number }> => {
  return axios.get(`/inventory/findByCodeId/${code}`);
};

export const useInventoryItemIdByCode = () => {
  return useMutation<{ id: number }, AxiosError<AxiosErrorResponse>, any, any>({
    mutationFn: getInventoryItemIdByCode,
    mutationKey: [FIND_BY_CODE_ITEM],
  });
};

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Inventory, TableInventory } from '../../api/inventory';
import { useMasterInventories } from '../../api/inventory/get-master-inventory';
import { useStatus } from '../../api/status/get-status';
import { PageContainer } from '../../components/common/container/page.container';
import { Searchbar } from '../../components/common/search/searchbar';
import { LinkButton } from '../../components/common/title-action/link-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import ExportInventoryButton from '../../components/inventory/button/export-inventory-button';
import { ImportInventoryButton } from '../../components/inventory/button/import-inventory-button';
import { InventoryBulkChangeStatusDropdown } from '../../components/inventory/dropdown/inventory-bulk-change-status-dropdown';
import { InventoryViewTabs } from '../../components/inventory/tabs/inventory-tabs';
import { Spinner } from '../../components/spinner';
import { dashboardTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';
import { TablePagination } from '../../components/table/table-pagination';
import { useRoles } from '../../provider/roles-provider';

export const InventoryPage = () => {
  const [searchParams] = useSearchParams();
  const inventories = useMasterInventories(searchParams);
  const statuses = useStatus({ page: '1', perPage: '100' });
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [checkedRowIds, setCheckedRowIds] = useState<number[]>([]);

  const clearBulkSelect = () => {
    setCheckedRowIds([]);
    setIsCheckAll(false);
  };

  const mapItems = (items: Inventory[]): TableInventory[] => {
    return items.map(
      (i): TableInventory => ({
        id: i.id,
        productTitle: i.product.title,
        status: i.status.title,
        type: i.product.type.title,
        owner: i.owner.title,
        assetId: i.assetId || '',
        sn: i.sn,
        location: i.location.title,
        at: i.at?.title ?? '',
        software: i.softwareVersion,
      })
    );
  };

  const { roles } = useRoles();
  const isUserOrAdmin =
    roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <Searchbar />
          <TitleActions title="Master Inventory">
            {isAdmin && statuses.isSuccess ? (
              <InventoryBulkChangeStatusDropdown
                statuses={statuses.data.items}
                inventoryIds={checkedRowIds}
                clearBulkSelect={clearBulkSelect}
              />
            ) : null}
            <ExportInventoryButton searchParams={searchParams} />
            {process.env.REACT_APP_FEATURE_IMPORT_CSV_ENABLED === 'true' ? (
              <ImportInventoryButton title="Import CSV" className={'sm:flex'} />
            ) : null}
            <LinkButton
              icon="/media/plus-icon.svg"
              title="Add inventory"
              link={'/inventory/create'}
              className={'sm:flex'}
            />
          </TitleActions>

          <InventoryViewTabs />

          {inventories.isFetched && inventories.data ? (
            <>
              <Table
                data={{
                  ...inventories.data,
                  items: mapItems(inventories.data.items),
                }}
                columns={dashboardTableColumns}
                checkbox={true}
                canUserEdit={isUserOrAdmin}
                editOnly={true}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
                checkedRowIds={checkedRowIds}
                setCheckedRowIds={setCheckedRowIds}
              />
              <TablePagination
                count={Number(inventories.data.count)}
                page={Number(inventories.data.page)}
                perPage={Number(inventories.data.perPage)}
              />
            </>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

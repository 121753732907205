import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useInventoryItemIdByCode } from '../../api/inventory/get-inventory-by-code';
import { BarcodeScanner } from '../../components/barcode-scanner/barcode-scanner';
import { NavigationButton } from '../../components/navigation/navigation-button';
import { useRoles } from '../../provider/roles-provider';

export const ScannerPage = () => {
  const [inventoryId, setInventoryId] = useState<number>();
  const [error, setError] = useState<string>('');
  const inventoryItemByCode = useInventoryItemIdByCode();

  const onResult = async (res: any) => {
    try {
      const item = await inventoryItemByCode.mutateAsync(res.text);
      setInventoryId(item.id);
    } catch (e: any) {
      setError('Not found');
    }
  };

  const refresh = () => {
    setInventoryId(undefined);
    setError('');
  };

  const onError = () => {};

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  return (
    <div className="flex flex-col justify-start items-start w-[100%] h-screen p-4 box-border">
      {isUserOrAdmin ? (
        <>
          <NavigationButton title={'Inventory'} url={'/inventory'}>
            <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
          </NavigationButton>

          {!inventoryId && !error ? <BarcodeScanner onResult={onResult} onError={onError} /> : null}

          {inventoryId ? <Navigate to={`/inventory/${inventoryId}`} /> : null}

          {error ? (
            <div className="fixed flex justify-between items-center top-[40%] left-[20%] w-[60%] p-4 border rounded">
              <div className="flex items-center justify-center gap-1">
                <ExclamationTriangleIcon className="h-4" /> {error}
              </div>
              <ArrowPathIcon className="h-4" onClick={() => refresh()} />
            </div>
          ) : null}
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </div>
  );
};

import { LabelInput } from '../../form/labelInput';
import { SubmitButton } from '../../form/submit-button';

export type InvoiceGroupEditFormProps = {
  setValue: any;
  onSubmit: () => void;
  register: any;
  isSubmitting: boolean;
};

export const InvoiceGroupEditForm = ({
  onSubmit,
  register,
  isSubmitting,
}: InvoiceGroupEditFormProps) => {

  return (
    <form onSubmit={onSubmit} className="w-[100%] sm:w-[50%]">
      <div className="mx-2 flex flex-col space-y-4 w-full">
        <LabelInput title={'New Group Name'} attributes={{ ...register('name', { required: true }) }} />
        <SubmitButton isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

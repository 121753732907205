import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useReadyEquipmentsList } from '../../api/equipment/get-ready-equipments-list';
import { Order } from '../../api/order';
import { useOrder } from '../../api/order/get-order';
import { useOrderUpdate } from '../../api/order/update-order';
import { useUsers } from '../../api/user/get-users';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { OrderForm } from '../../components/order/form/order-form';
import { OrderFormTabs } from '../../components/order/tabs/order-form-tabs';
import { Spinner } from '../../components/spinner';
import { formatDateForInput } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';
import { useNotificationStore } from '../../stores/notifications';

export const OrderEditPage = () => {

  const { roles } = useRoles();
  const isUserOrAdmin = roles && Array.isArray(roles.roles) && (roles.roles.includes('Admin') || roles.roles.includes('User'));

  const navigate = useNavigate();
  const { id } = useParams();
  const { addNotification } = useNotificationStore();

  const updateOrder = useOrderUpdate();

  const { isSuccess, data: order } = useOrder(id);

  const methods = useForm<Order>({
    defaultValues: {
      isReceiverName: true,
      orderDate: '',
      orderNeededDate: '',
    },
  });

  const { reset, handleSubmit } = methods;

  const { isSuccess: isSuccessUsers, data: users } = useUsers();
  const { isSuccess: isSuccessEquip, data: equipments } = useReadyEquipmentsList({
    orderId: id,
    page: '1',
    perPage: '100',
  });

  const onSubmit = async (values: any) => {
    if (order?.orderType === 'DELIVERY') {
      await updateOrder.mutateAsync({ ...values });
      navigate(`/order/${values.id}`);
    } else {
      await updateOrder.mutateAsync({ ...values, orderNeededDate: null });
      navigate(`/pickup-order/${values.id}`);
    }
  };

  useEffect(() => {
    if (order?.status === 'ACTIVE_RENTAL') {
      addNotification({
        type: 'error',
        title: 'Error',
        message: 'Order is in Active Rental status!',
      });
      return navigate('/order');
    }

    if (isSuccess && order) {
      reset({
        ...order,
        orderDate: formatDateForInput(order.orderDate),
        orderNeededDate: formatDateForInput(order.orderNeededDate),
      });
    }
  }, [order, isSuccess, reset]);
  

  if (!isSuccessUsers || !isSuccessEquip || !isSuccess || !id || !order) return <Spinner />;

  if (order.status === 'ACTIVE_RENTAL') {
    return <Navigate to={'/order'} />;
  }

  return (
    <PageContainer>
      {isUserOrAdmin ? (
        <>
          <NavigationBackButton url={'/order'} />
          <TitleActions title={order.orderType === 'DELIVERY' ? ' Edit Delivery Order' : 'Edit Pickup Order'} />
          <OrderFormTabs />

          <FormProvider {...methods}>
            <OrderForm
              initialData={order}
              onSubmit={(fn: any) => handleSubmit((values) => fn(values, onSubmit))}
              users={users.items}
              equipments={equipments.items}
              isForEdit={true}
            />
          </FormProvider>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You do not have the appropriate permissions to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

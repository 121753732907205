import { useParams, useSearchParams } from 'react-router-dom';

import type { TableCustomerPricing } from '../../api/customer';
import { useDeleteCustomerPricing } from '../../api/customer/delete-customer-pricing';
import { useCustomer } from '../../api/customer/get-customer';
import { useCustomerPricingList } from '../../api/customer/get-customer-pricing-list';
import { LinkButton } from '../../components/common/title-action/link-button';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { ProductOrderTypeTabs } from '../../components/customer/tabs/product-order-type-tabs';
import { NavigationBackButton } from '../../components/navigation/navigation-back-button';
import { Spinner } from '../../components/spinner';
import { customerViewTableColumns } from '../../components/table/data-cols';
import { Table } from '../../components/table/table';
import { TablePagination } from '../../components/table/table-pagination';
import { formatDate } from '../../lib/format-date';
import { useRoles } from '../../provider/roles-provider';

export const CustomerPricingPage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const { customerId } = useParams();
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';
  const perPage = searchParams.get('perPage') || '10';
  const search = searchParams.get('search') || '';

  const deleteCustomerPricing = useDeleteCustomerPricing();

  const customer = useCustomer(customerId);
  const customerPricing = useCustomerPricingList({
    args: { customerId, page, perPage, search },
    config: {
      select: (data) => {
        return {
          ...data,
          items: data.items.map((i) => ({
            id: i.id,
            equipment: i.product.title,
            pricing:
              i.pricingRate === 'MONTHLY'
                ? `$${i.monthlyRate}/${i.pricingRate.toLowerCase()}`
                : `$${i.dailyRate}/${i.pricingRate.toLowerCase()}`,
            monthlyCap: i.monthlyCap ? `$${i.monthlyCap}` : undefined,
            startDate: formatDate(i.startDate),
            endDate: formatDate(i.endDate),
            notes: i.notes,
          })),
        };
      },
    },
  });

  if ((!customer.isSuccess || !customerPricing.isSuccess) && !isAdmin) return <Spinner />;

  return (
    <div className="flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0">
      {isAdmin && customer.data && customerPricing.data ? (
        <>
          <NavigationBackButton url={-1} />

          <TitleActions title={customer.data.title}>
            <LinkButton title={'Add Equipment'} icon="/media/plus-icon.svg" link={'create'} />
          </TitleActions>

          <ProductOrderTypeTabs />

          <>
            <Table
              columns={customerViewTableColumns}
              data={customerPricing.data}
              checkbox={false}
              view={false}
              canUserEdit={isAdmin}
              {...(isAdmin && {
                onDelete: (item: TableCustomerPricing) => {
                  if (!customerId || !+customerId) return;
                  deleteCustomerPricing.mutate({ customerId: +customerId, id: item.id });
                },
              })}
            />
            <TablePagination
              count={Number(customerPricing.data.count)}
              page={Number(customerPricing.data.page)}
              perPage={Number(customerPricing.data.perPage)}
            />
          </>
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </div>
  );
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { QUERY_PAGE, QUERY_PER_PAGE, QUERY_SEARCH } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { Import } from './index';

export const getImports = (queryParams: URLSearchParams): Promise<ResourceResponse<Import>> => {
  const params = {
    page: queryParams.get(QUERY_PAGE) || 1,
    perPage: queryParams.get(QUERY_PER_PAGE) || 10,
    search: queryParams.get(QUERY_SEARCH) || '',
  };
  return axios.get('/import', { params });
};

export const useImports = (params: any) => {
  return useQuery<ResourceResponse<Import>, AxiosError<AxiosErrorResponse>>({
    queryKey: ['imports', params],
    queryFn: () => getImports(params),
    keepPreviousData: true,
  });
};

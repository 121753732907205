import { useMutation } from "@tanstack/react-query";

import { axios } from "../../lib/axios";
import { ORDERS_KEY } from "../../lib/constants";
import { queryClient } from "../../lib/react-query";
import { useNotificationStore } from "../../stores/notifications";


export const approveOrder = (id: number): Promise<any> => {
  return axios.post(`/order/approve/${id}`);
};

export const useOrderApprove = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: "success",
        title: "Ok",
        message: "Order approved successfully"
      });
      await queryClient.invalidateQueries({ queryKey: [ORDERS_KEY] });
    },
    mutationFn: approveOrder
  });
};
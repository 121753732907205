import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useCategories } from '../../api/category/get-categories';
import { Equipment } from '../../api/equipment';
import { useEquipment } from '../../api/equipment/get-equipment';
import { useEquipmentEdit } from '../../api/equipment/update-equipment';
import { PageContainer } from '../../components/common/container/page.container';
import { TitleActions } from '../../components/common/title-action/title-actions';
import { EquipmentForm } from '../../components/equipment/form/equipment-form';
import { NavigationButton } from '../../components/navigation/navigation-button';
import { Spinner } from '../../components/spinner';
import { useRoles } from '../../provider/roles-provider';

export const EquipmentEditPage = () => {
  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  const navigate = useNavigate();

  const { id } = useParams();
  const { isSuccess, data: equipment } = useEquipment(id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    setValue,
  } = useForm<Equipment>();

  const editProduct = useEquipmentEdit();
  const categories = useCategories();

  const onSubmit = async (equipment: Equipment) => {
    try {
      const updateEquipmentDto = {
        ...equipment,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
        accessories: undefined,
        components: undefined,
        type: undefined,
        category: undefined,
      };
      await editProduct.mutateAsync({ product: updateEquipmentDto, id });
      navigate('/equipment');
    } catch {
      // error notifcations handled by axios interceptor
      return;
    }
  };

  useEffect(() => {
    reset(equipment);
  }, [equipment]);

  if (!isSuccess) return <Spinner />;

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <button onClick={() => navigate('/equipment')}>
            <NavigationButton title="Equipment" url="#">
              <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
            </NavigationButton>
          </button>

          <TitleActions title="Edit equipment" />

          <EquipmentForm
            initialType={equipment.type.id}
            setValue={setValue}
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            isSubmitting={isSubmitting}
            categories={categories?.data?.items || []}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { STATUS_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps, ResourceResponse } from '../types';

import { Status } from './index';

export const getStatus = (params: GetItemProps): Promise<ResourceResponse<Status>> => {
  return axios.get('/status', { params: { ...params, perPage: params.perPage ? params.perPage : '10' } });
};

export const useStatus = (params: GetItemProps) => {
  return useQuery<ResourceResponse<Status>, AxiosError<AxiosErrorResponse>>({
    queryKey: [STATUS_KEY, params.page || 1],
    queryFn: () => getStatus(params),
    keepPreviousData: true,
  });
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { USER_LIST_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps, ResourceResponse } from '../types';

import { User } from './index';

export const getUserList = (params: GetItemProps): Promise<ResourceResponse<User>> => {
  return axios.get('/user', { params: { perPage: '10', ...params } });
};

export const useUserList = (args: GetItemProps) => {
  return useQuery<ResourceResponse<User>, AxiosError<AxiosErrorResponse>>({
    queryKey: [USER_LIST_KEY, args.toString()],
    queryFn: () => getUserList(args),
    keepPreviousData: true,
  });
};

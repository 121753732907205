import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { CUSTOMER_PRICING_KEY } from '../../lib/constants';
import { useNotificationStore } from '../../stores/notifications';

import { CustomerPricing } from './index';

type EditCustomerPricingProps = {
  data: Partial<CustomerPricing>;
  customerId: string;
  pricingId: string;
};

export const editCustomerPricing = ({
  data,
  customerId,
  pricingId,
}: EditCustomerPricingProps): Promise<CustomerPricing> => {
  return axios.put(`/customer/${customerId}/pricing/${pricingId}`, { ...data });
};

export const useCustomerPricingEdit = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: `Customer Pricing saved successfully`,
      });
      await queryClient.invalidateQueries({ queryKey: [CUSTOMER_PRICING_KEY] });
    },
    mutationFn: editCustomerPricing,
  });
};

import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useCategories } from "../../api/category/get-categories";
import { Equipment } from "../../api/equipment";
import { useEquipmentCreate } from "../../api/equipment/create-equipment";
import { PageContainer } from "../../components/common/container/page.container";
import { TitleActions } from "../../components/common/title-action/title-actions";
import { EquipmentForm } from "../../components/equipment/form/equipment-form";
import { NavigationButton } from "../../components/navigation/navigation-button";
import { useRoles } from "../../provider/roles-provider";

export const EquipmentCreatePage = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = useForm<Equipment>();

  const navigate = useNavigate();
  const createProduct = useEquipmentCreate();
  const categories = useCategories();

  const onSubmit = async (values: Equipment) => {
    try {
      await createProduct.mutateAsync(values);
      navigate("/equipment");
    } catch {
      // let axios interceptor handle notification of error
      return;
    }

  };

  const { roles } = useRoles();
  const isAdmin = roles && Array.isArray(roles.roles) && roles.roles.includes('Admin');

  return (
    <PageContainer>
      {isAdmin ? (
        <>
          <button onClick={() => navigate("/equipment")}>
            <NavigationButton title="Equipment" url="#">
              <ArrowLeftIcon className="h-5 w-5 ml-2 text-[#5B6871]" />
            </NavigationButton>
          </button>

          <TitleActions title="Create equipment" />

          <EquipmentForm
            setValue={setValue}
            onSubmit={handleSubmit(onSubmit)}
            register={register}
            isSubmitting={isSubmitting}
            categories={categories?.data?.items || []}
          />
        </>
      ) : (
        <div className="text-center text-red-500">
          <h1>You must be an Admin to view this page</h1>
        </div>
      )}
    </PageContainer>
  );
};

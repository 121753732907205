import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { ORDERS_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { Order } from './index';

export const getOrder = (id: string | undefined): Promise<Order> => {
  return axios.get(`/order/${id}`);
};

export const useOrder = (id: string | undefined, config: UseQueryOptions<Order, AxiosError<AxiosErrorResponse>> = {}) => {
  return useQuery<Order, AxiosError<AxiosErrorResponse>>({
    ...config,
    queryKey: [ORDERS_KEY, id],
    queryFn: () => getOrder(id),
    keepPreviousData: false,
  });
};

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tabs } from '../../common/tabs/tabs';
import { TabsItem } from '../../common/tabs/tabs-item';

export const ORDER_VIEW_KEY = 'view';

const orderViewTabs = [
  {
    title: 'View Order',
    key: 'details',
  },
  {
    title: 'Order History',
    key: 'history',
  },
] as const;

export const OrderViewTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setSearchParams({ [ORDER_VIEW_KEY]: orderViewTabs[0].key });
  }, [])

  return (
    <div className="w-full mb-4">
      <Tabs>
        {orderViewTabs.map((view) => (
          <TabsItem
            title={view.title}
            key={view.key}
            active={view.key === searchParams.get(ORDER_VIEW_KEY)}
            onClick={async () => {
              console.log()
              await setSearchParams({ [ORDER_VIEW_KEY]: view.key })
            }}
          />
        ))}
      </Tabs>
    </div>
  );
};

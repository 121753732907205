export const usaStates = [
  { title: 'Alabama', id: 'Alabama' },
  { title: 'Alaska', id: 'Alaska' },
  { title: 'Arizona', id: 'Arizona' },
  { title: 'Arkansas', id: 'Arkansas' },
  { title: 'California', id: 'California' },
  { title: 'Colorado', id: 'Colorado' },
  { title: 'Connecticut', id: 'Connecticut' },
  { title: 'Delaware', id: 'Delaware' },
  { title: 'Florida', id: 'Florida' },
  { title: 'Georgia', id: 'Georgia' },
  { title: 'Hawaii', id: 'Hawaii' },
  { title: 'Idaho', id: 'Idaho' },
  { title: 'Illinois', id: 'Illinois' },
  { title: 'Indiana', id: 'Indiana' },
  { title: 'Iowa', id: 'Iowa' },
  { title: 'Kansas', id: 'Kansas' },
  { title: 'Kentucky', id: 'Kentucky' },
  { title: 'Louisiana', id: 'Louisiana' },
  { title: 'Maine', id: 'Maine' },
  { title: 'Maryland', id: 'Maryland' },
  { title: 'Massachusetts', id: 'Massachusetts' },
  { title: 'Michigan', id: 'Michigan' },
  { title: 'Minnesota', id: 'Minnesota' },
  { title: 'Mississippi', id: 'Mississippi' },
  { title: 'Missouri', id: 'Missouri' },
  { title: 'Montana', id: 'Montana' },
  { title: 'Nebraska', id: 'Nebraska' },
  { title: 'Nevada', id: 'Nevada' },
  { title: 'New Hampshire', id: 'New Hampshire' },
  { title: 'New Jersey', id: 'New Jersey' },
  { title: 'New Mexico', id: 'New Mexico' },
  { title: 'New York', id: 'New York' },
  { title: 'North Carolina', id: 'North Carolina' },
  { title: 'North Dakota', id: 'North Dakota' },
  { title: 'Ohio', id: 'Ohio' },
  { title: 'Oklahoma', id: 'Oklahoma' },
  { title: 'Oregon', id: 'Oregon' },
  { title: 'Pennsylvania', id: 'Pennsylvania' },
  { title: 'Rhode Island', id: 'Rhode Island' },
  { title: 'South Carolina', id: 'South Carolina' },
  { title: 'South Dakota', id: 'South Dakota' },
  { title: 'Tennessee', id: 'Tennessee' },
  { title: 'Texas', id: 'Texas' },
  { title: 'Utah', id: 'Utah' },
  { title: 'Vermont', id: 'Vermont' },
  { title: 'Virginia', id: 'Virginia' },
  { title: 'Washington', id: 'Washington' },
  { title: 'West Virginia', id: 'West Virginia' },
  { title: 'Wisconsin', id: 'Wisconsin' },
  { title: 'Wyoming', id: 'Wyoming' },
];

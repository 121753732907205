import { ReactNode } from 'react';

export type PageContainerProps = {
  children: ReactNode;
  className?: string;
};

export const PageContainer = ({ children, className }: PageContainerProps) => {
  return (
    <div className={`flex flex-col justify-start items-start w-full p-4 box-border overflow-x-auto pb-[80px] md:pb-0 ${className}`}>
      {children}
    </div>
  );
};

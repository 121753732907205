export const formatDate = (date: string | Date) => {
  if (!date) return '';

  const dateObject = new Date(date);

  if (dateObject.toString() === 'Invalid Date') {
    return dateObject.toString();
  }

  return [padTo2Digits(dateObject.getUTCMonth() + 1), padTo2Digits(dateObject.getUTCDate()), dateObject.getUTCFullYear()].join(
    '/'
  );
};

export const formatDateForInput = (date: string | Date) => {
  const dateObject = new Date(date);

  if (dateObject.toString() === 'Invalid Date') {
    return dateObject.toString();
  }

  return [dateObject.getUTCFullYear(), padTo2Digits(dateObject.getUTCMonth() + 1), padTo2Digits(dateObject.getUTCDate())].join('-');
};

export const padTo2Digits = (num: number | string) => {
  return num.toString().padStart(2, '0');
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { useNotificationStore } from '../../stores/notifications';

import { CustomerPricing } from './index';

type CreateCustomerPricingProps = {
  data: CustomerPricing;
  customerId: string | undefined;
};

export const createCustomerPricing = ({ data, customerId }: CreateCustomerPricingProps): Promise<CustomerPricing> => {
  return axios.post(`/customer/${customerId}/pricing`, { ...data });
};

export const useCustomerPricingCreate = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: async () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: `Customer Pricing saved successfully`,
      });
    },
    mutationFn: createCustomerPricing,
  });
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ResourceResponse } from '../types';

interface DeleteEquipmentArgs {
  equipmentIdForDelete: number;
  equipmentIdForReassign: number | undefined;
}

export const deleteEquipment = (equipmentIdForDelete: number, equipmentIdForReassign: number | undefined): Promise<ResourceResponse<void>> => {

  return axios.delete(`/product/${equipmentIdForDelete}`, { params: { equipmentIdForReassign }});
};

export const useDeleteEquipment = () => {
  return useMutation((args: DeleteEquipmentArgs) => 
    deleteEquipment(args.equipmentIdForDelete, args.equipmentIdForReassign)
  );
};

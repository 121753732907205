import { useEffect, useState } from 'react';

import { useOneEquipment } from '../../../api/equipment/get-one-equipment';
import PopoverSelect from '../../common/popover-select/popover-select';
import { Input } from '../../form/input';
import { Label } from '../../form/label';
import { LabelInput } from '../../form/labelInput';
import { LabelSelect } from '../../form/labelSelect';
import { LabelTextArea } from '../../form/labelTextArea';
import { SubmitButton } from '../../form/submit-button';

export type CustomerPricingFormProps = {
  onSubmit: () => void;
  register: any;
  setValue: any;
  isSubmitting: boolean;
  initialData?: any;
};

enum PricingRate {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

const pricingRates = [
  { id: PricingRate.DAILY, title: 'Daily' },
  { id: PricingRate.MONTHLY, title: 'Monthly' },
];

export const CustomerPricingForm = ({
  onSubmit,
  register,
  isSubmitting,
  initialData,
  setValue,
}: CustomerPricingFormProps) => {
  const [equipmentId, setEquipmentId] = useState<any>(
    initialData?.equipmentId ? Number(initialData.equipmentId) : null
  );
  const [pricingRate, setPricingRate] = useState<PricingRate>(initialData?.pricingRate || PricingRate.DAILY);

  const equipment = useOneEquipment(equipmentId, equipmentId !== null);

  useEffect(() => {
    if (initialData) {
      // Format and set the start date if it exists
      if (initialData.startDate) {
        const formattedStartDate = new Date(initialData.startDate).toISOString().slice(0, 10);
        setValue('startDate', formattedStartDate);
      }

      // Format and set the end date if it exists
      if (initialData.endDate) {
        const formattedEndDate = new Date(initialData.endDate).toISOString().slice(0, 10);
        setValue('endDate', formattedEndDate);
      }
    }
  }, [initialData, setValue]);

  return (
    <form onSubmit={onSubmit} className="w-full">
      <div className="flex flex-col space-y-8 w-full">
        <div className="w-full sm:w-[50%] space-y-3">
          <PopoverSelect
            title="Equipment"
            setedValue={equipment?.data?.title}
            parentValue={equipmentId}
            parentValueSetter={(value: any) => setEquipmentId(Number(value))}
            itemKey="productIds"
            registerName="productId"
            setValue={setValue}
            register={register}
            isRequired
          />

          <LabelSelect
            title={'Pricing Rate'}
            attributes={{ ...register('pricingRate', { required: true }) }}
            data={pricingRates}
            skipOption={true}
            onChange={(e) => {
              setPricingRate(e.target.value as PricingRate);
            }}
          />

          {pricingRate === PricingRate.DAILY ? (
            <div>
              <div className="flex flex-col">
                <Label title={'Daily Rate'} />
                <div className="flex w-full">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                    $
                  </span>
                  <Input
                    type="number"
                    attributes={{ ...register('dailyRate', { valueAsNumber: true, required: true }) }}
                    placeholder={'0.00'}
                    className="border border-gray-300 p-2 text-md rounded-r-lg w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <Label title={'Monthly Cap'} />
                <div className="flex w-full">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                    $
                  </span>
                  <Input
                    type="number"
                    attributes={{ ...register('monthlyCap', { valueAsNumber: true }) }}
                    placeholder={'0.00'}
                    className="border border-gray-300 p-2 text-md rounded-r-lg w-full"
                  />
                </div>
              </div>
            </div>
          ) : pricingRate == PricingRate.MONTHLY ? (
            <div className="flex flex-col">
              <Label title={'Monthly Rate'} />
              <div className="flex w-full">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                  $
                </span>
                <Input
                  type="number"
                  attributes={{ ...register('monthlyRate', { valueAsNumber: true, required: true }) }}
                  placeholder={'0.00'}
                  className="border border-gray-300 p-2 text-md rounded-r-lg w-full"
                />
              </div>
            </div>
          ) : undefined}

          <LabelTextArea title={'Price Notes'} attributes={{ ...register('notes') }} placeholder={'Long term price'} />

          <LabelInput
            title={'Start Date'}
            type="date"
            attributes={{ ...register('startDate', { required: true, valueAsDate: true }) }}
          />
          <LabelInput title={'End Date'} type="date" attributes={{ ...register('endDate', { valueAsDate: true }) }} />
        </div>

        <div className="flex justify-center">
          <SubmitButton isSubmitting={isSubmitting} submitText="Save Customer Pricing" />
        </div>
      </div>
    </form>
  );
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { EQUIPMENT_KEY } from '../../lib/constants';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { Equipment } from './index';

type EditEquipmentProps = {
  product: Omit<
    Equipment,
    'createdAt' | 'updatedAt' | 'deletedAt' | 'accessories' | 'components' | 'type' | 'category'
  >;
  id: string | unknown;
};

export const editEquipment = ({ product, id }: EditEquipmentProps): Promise<Equipment> => {
  return axios.put(`/product/${id}`, { ...product });
};

export const useEquipmentEdit = () => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: async (data: Equipment) => {
      await queryClient.refetchQueries([EQUIPMENT_KEY, String(data.id)]);
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Equipment saved successfully',
      });
    },
    mutationFn: editEquipment,
  });
};

import { useMutation } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { queryClient } from '../../lib/react-query';
import { useNotificationStore } from '../../stores/notifications';

import { MASTER_INVENTORY_ITEM_KEY } from './get-inventory-item';

import { Inventory, InventoryCreate } from './index';

export const createInventory = (inventory: InventoryCreate): Promise<Inventory> => {
  const inventoryCreateDto = {
    ...inventory,
    snArray: undefined,
  };
  return axios.post('/inventory', inventoryCreateDto);
};

export const useCreateInventory = () => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: async (data: Inventory) => {
      await queryClient.refetchQueries([MASTER_INVENTORY_ITEM_KEY, String(data.id)]);
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Equipment created successfully',
      });
    },
    mutationFn: createInventory,
  });
};

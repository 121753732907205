import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMER_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { Customer } from './index';

export const getOneCustomer = (id: number | null): Promise<Customer> | null => {
  if (id === null || isNaN(id)) return null;
  return axios.get(`/customer/${id}`);
};

export const useOneCustomer = (id: number) => {
  return useQuery<Customer | null, AxiosError<AxiosErrorResponse>>({
    queryKey: [CUSTOMER_KEY, id],
    queryFn: () => getOneCustomer(id),
    keepPreviousData: true,
  });
};

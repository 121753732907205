import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCustomersList } from '../../../api/customer/get-customers-list';
import { useEquipments } from '../../../api/equipment/get-equipments';
import { useLocation } from '../../../api/location/get-location';
import { useOwner } from '../../../api/owner/get-owner';
import { useStatus } from '../../../api/status/get-status';
import { useType } from '../../../api/type/get-type';
import { useUserList } from '../../../api/user/get-user-list';

import TablePopoverList from './table-popover-list';
import TablePopoverPagination from './table-popover-pagination';
import TablePopoverSearch from './table-popover-search';
const hooks: { [key: string]: any } = {
  productIds: useEquipments,
  statusIds: useStatus,
  ownerIds: useOwner,
  typeIds: useType,
  locIds: useLocation,
  atIds: useCustomersList,
  customerIds: useCustomersList,
  userIds: useUserList,
};

// TODO: Consider consolidating this with TablePopoverPanelFilter for shared use
export default function TablePopoverPanelFormInput({ itemKey, setParentValue, parentValue, onExternalPageChange, onExternalSearchChange }: any) {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const hook = hooks[itemKey];
  // TODO: Consolidate use of URLSearchParams vs GetItemProps
  const response = hook({
    search,
    page,
  });

  useEffect(() => {
    const params = searchParams.getAll(itemKey);
    setIsChecked([...params.map((p: string) => Number(p))]);
  }, []);

  const handleSelect = async (e: any) => {
    const { id, checked } = e.target;

    if (!checked) {
      const checkedItems = isChecked.filter((i) => i !== Number(id));
      await setIsChecked(checkedItems);

      if (setParentValue) {
        setParentValue(null);
      } else {
        if (checkedItems.length === 0) {
          searchParams.delete(itemKey);
          await setSearchParams(searchParams);
        } else {
          searchParams.delete(itemKey);

          checkedItems.forEach((c: number) => searchParams.append(itemKey, String(c)));

          await setSearchParams(searchParams);
        }
      }
    } else {
      if (setParentValue) {
        await setIsChecked([Number(id)]);
        setParentValue(id);
      } else {
        await setIsChecked([...isChecked, Number(id)]);
        searchParams.append(itemKey, String(id));
        await setSearchParams(searchParams);
      }
    }
  };

  const onSearch = async (search: string) => {
    await setSearch(search);
    await response.refetch();

    if (onExternalSearchChange) {
      onExternalSearchChange(search);
    }
  };

  const onPageChange = async (page: number) => {
    await setPage(page);
    await response.refetch();

    if (onExternalPageChange) {
      onExternalPageChange(page);
    }
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-5 bg-white text-sm">
      <TablePopoverSearch value={search} onSearch={onSearch} />
      {response.isFetched ? (
        <>
          <TablePopoverList
            items={response.data.items}
            isChecked={isChecked.length ? isChecked : [Number(parentValue)]}
            onSelect={handleSelect}
          />
          <TablePopoverPagination
            onChange={onPageChange}
            page={Number(response.data.page)}
            perPage={Number(response.data.perPage)}
            count={Number(response.data.count)}
          />
        </>
      ) : null}
    </div>
  );
}
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { NavLink } from 'react-router-dom';

import { Customer } from '../../api/customer'

type CustomerProps = {
  customer: Customer;
  onRefresh?: () => void;
}

const CustomerInfoBlock: React.FC<CustomerProps> = ({ customer, onRefresh }) => {
  return (
    <div className="flex flex-col bg-[rgba(224,224,224,0.37)] p-4 rounded-md space-y-2 border border-1 border-gray-[rgba(224,224,224,0.40)]">
      <div className="flex justify-between">
        <span className="text-xs text-gray-600">Customer Notes</span>
        <span className="text-xs text-gray-600"></span>
        <div className="flex space-x-2">
          <ArrowPathIcon
            className="text-xs text-gray-600 h-4 w-4 cursor-pointer"
            onClick={() => onRefresh?.()}
          />
          <NavLink className="text-xs text-gray-600" to={`/customer/edit/${customer.id}`} target="_blank">
            Edit
          </NavLink>
        </div>
      </div>
      <div className="flex flex-col">
        <span className="text-xs font-medium">Default Ship To:</span>
        <span className="text-xs"> {customer.title}</span>
        <span className="text-xs"> {customer.address.delivery.street1}</span>
        <span className="text-xs"> {customer.address.delivery.street2}</span>
        <span className="text-xs">
          {customer.address.delivery.city}, {customer.address.delivery.state}{' '}
          {customer.address.delivery.zip}
        </span>
      </div>
      <div className="flex flex-col">
        <span className="text-xs font-medium">PO Number:</span>
        <span className="text-xs">{customer.poNumber}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-xs font-medium">Caller Name:</span>
        <span className="text-xs">{customer.contactPerson}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-xs font-medium">Caller Phone Number:</span>
        <span className="text-xs">{customer.callerPhoneNumber}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-xs font-medium">Caller E-mail:</span>
        <span className="text-xs">{customer.email}</span>
      </div>
      <div className="flex flex-col">
        <span className="text-xs font-medium">Notes:</span>
        <span className="text-xs">{customer.note}</span>
      </div>
    </div>
  )
}

export default CustomerInfoBlock

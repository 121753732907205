import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps, ResourceResponse } from '../types';

import { ReadyEquipment } from './index';

export const EQUIPMENT_READY_LIST_KEY = 'EQUIPMENT_READY_LIST_KEY';

type GetReadyEquipmentsListProps = {
  orderId?: string | undefined;
  customerId?: string | undefined;
} & GetItemProps;

export const getReadyEquipments = (options: GetReadyEquipmentsListProps): Promise<ResourceResponse<ReadyEquipment>> => {
  const { orderId, customerId, page, search, perPage } = options;
  return axios.get(`/product/ready`, {
    params: {
      customerId,
      orderId: orderId ? orderId : null,
      page,
      perPage: perPage ? perPage : '10',
      search,
    },
  });
};

export const useReadyEquipmentsList = (options: GetReadyEquipmentsListProps) => {
  return useQuery<ResourceResponse<ReadyEquipment>, AxiosError<AxiosErrorResponse>>({
    queryKey: [EQUIPMENT_READY_LIST_KEY],
    queryFn: () => getReadyEquipments(options),
    keepPreviousData: true,
  });
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { LOCATION_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';
import { GetItemProps } from '../types';

export const getLocation = (params: GetItemProps): Promise<any> => {
  return axios.get('/location', { params: { ...params, perPage: '10' } });
};

export const useLocation = (params: GetItemProps) => {
  return useQuery<any, AxiosError<AxiosErrorResponse>>({
    queryKey: [LOCATION_KEY, params.page || 1],
    queryFn: () => getLocation(params),
    keepPreviousData: true,
  });
};

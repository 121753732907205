import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMER_PRICING_KEY } from '../../lib/constants';
import { QueryConfig } from '../../lib/react-query';
import { AxiosErrorResponse } from '../../lib/types';
import { ResourceResponse } from '../types';

import { CustomerPricing } from './index';

export const getCustomerPricingList = ({
  customerId,
  page = 1,
  perPage = 10,
  search = null,
}: any): Promise<ResourceResponse<CustomerPricing>> => {
  const params = { page, perPage, search };

  return axios.get(`/customer/${customerId}/pricing`, { params });
};

type QueryFnType = typeof getCustomerPricingList;

type UseCustomerPricingList<TData> = {
  args: any;
  config?: QueryConfig<QueryFnType, TData>;
};

export const useCustomerPricingList = <TData>({ args, config }: UseCustomerPricingList<TData>) => {
  return useQuery<ResourceResponse<CustomerPricing>, AxiosError<AxiosErrorResponse>, TData>({
    ...config,
    queryKey: [CUSTOMER_PRICING_KEY, args.customerId, args.page, args.perPage, args.search],
    queryFn: () => getCustomerPricingList(args),
    keepPreviousData: true,
  });
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { CUSTOMER_PRICING_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { CustomerPricing } from './index';

export type GetCustomerPricingProps = {
  customerId: string | undefined;
  pricingId: string | undefined;
};

export const getCustomerPricing = ({ customerId, pricingId }: GetCustomerPricingProps): Promise<CustomerPricing> => {
  return axios.get(`/customer/${customerId}/pricing/${pricingId}`);
};

export const useCustomerPricing = (args: GetCustomerPricingProps) => {
  return useQuery<CustomerPricing, AxiosError<AxiosErrorResponse>>({
    queryKey: [CUSTOMER_PRICING_KEY, args.customerId, args.pricingId],
    queryFn: () => getCustomerPricing(args),
    keepPreviousData: true,
  });
};

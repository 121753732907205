import { ReactNode } from 'react';

export type TableDataCellProps = {
  className: string;
  children: ReactNode;
} & React.ComponentPropsWithoutRef<'td'>;

export const TableDataCell = ({ className, children, ...props }: TableDataCellProps) => {
  return <td className={className} {...props}>{children}</td>;
};

export const Banner = () => {
    const env = process.env.REACT_APP_ENV;

    return (
        <>
            {env === 'local' ? (
                <div className="flex w-screen h-full text-center text-white bg-sky-600 items-center justify-center font-bold p-1">
                    Develop Environment
                </div>
            ) : null}
            {env === 'staging' ? (
                <div className="flex w-screen h-full text-center text-white bg-indigo-600 items-center justify-center font-bold p-1">
                    Staging Environment
                </div>
            ) : null}
        </>
    )
}
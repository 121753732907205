import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ORDERS_KEY } from '../../lib/constants';
import { useNotificationStore } from '../../stores/notifications';

import { ORDER_HISTORY } from './get-order-history';

import { Order } from './index';

export const updateOrder = (data: Partial<Order> & { id: number }): Promise<Order | null> => {
  const updateOrderDto = {
    ...data,
    id: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    customer: undefined,
    user: undefined,
    orderInventories: undefined,
  };
  return axios.put(`/order/${data.id}`, updateOrderDto);
};

export const useOrderUpdate = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (data) => {
      await queryClient.cancelQueries([ORDERS_KEY, `${data.id}`]);
    },
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Ok',
        message: 'Order updated successfully',
      });
    },
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries([ORDERS_KEY]);
      await queryClient.invalidateQueries([ORDER_HISTORY, id]);
    },
    mutationFn: updateOrder,
  });
};

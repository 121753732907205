import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axios } from '../../lib/axios';
import { OWNER_KEY } from '../../lib/constants';
import { AxiosErrorResponse } from '../../lib/types';

import { Owner } from './index';

export const getOneOwner = (id: number | null): Promise<Owner> | null => {
  if (id === null || isNaN(id)) return null;
  return axios.get(`/owner/${id}`);
};

export const useOneOwner = (id: number) => {
  return useQuery<Owner | null, AxiosError<AxiosErrorResponse>>({
    queryKey: [OWNER_KEY, id],
    queryFn: () => getOneOwner(id),
    keepPreviousData: true,
  });
};

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tabs } from '../../common/tabs/tabs';
import { TabsItem } from '../../common/tabs/tabs-item';

export const OrderTabs = ({ currentUser }: any) => {
  const ALL = '';
  const DELIVERY = 'DELIVERY';
  const PICKUP = 'PICKUP';
  const ASSIGNED_TO_ME = 'ASSIGNED_TO_ME'; 
  const UNASSIGNED = 'UNASSIGNED';
  const TYPE_KEY = 'orderType';
  const USER_KEY = 'userIds';

  const [searchParams, setSearchParams] = useSearchParams();
  const [active, setActive] = useState<string>('');

  const setActiveTab = async (tab: string) => {
    searchParams.delete(TYPE_KEY);
    searchParams.delete(USER_KEY);

    setActive(tab);
    
    switch(tab) {
      case DELIVERY:
      case PICKUP:
        searchParams.set(TYPE_KEY, tab);
        break;
      case ASSIGNED_TO_ME:
        searchParams.set(USER_KEY, String(currentUser));
        break;
      case UNASSIGNED:
        searchParams.set(USER_KEY, tab);
        break;
      default:
        break;
    }
    await setSearchParams(searchParams);
  };

  useEffect(() => {
    let param = searchParams.get(TYPE_KEY) || searchParams.get(USER_KEY);
    
    // Check if the USER_KEY param is equal to the currentUser, and if so, set the active state to ASSIGNED_TO_ME
    if (param === String(currentUser)) {
        param = ASSIGNED_TO_ME;
    }

    setActive(param || ALL);
}, [searchParams, currentUser]);

  return (
    <div className="w-full mb-4">
      <Tabs>
        <TabsItem title={'All'} active={active === ALL} onClick={() => setActiveTab(ALL)} />
        <TabsItem title={'Delivery'} active={active === DELIVERY} onClick={() => setActiveTab(DELIVERY)} />
        <TabsItem title={'Pickup'} active={active === PICKUP} onClick={() => setActiveTab(PICKUP)} />
        <TabsItem title={'Assigned To Me'} active={active === ASSIGNED_TO_ME} onClick={() => setActiveTab(ASSIGNED_TO_ME)} />
        <TabsItem title={'Unassigned'} active={active === UNASSIGNED} onClick={() => setActiveTab(UNASSIGNED)} />
      </Tabs>
    </div>
  );
};
